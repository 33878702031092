import Web3 from "web3";
import CreateLotteryABI from "./abi/CreateLotteryABI";
import { MultiCallAddress , nftMiniABI, jsonRpcURL , CreateLotteryAddress , fee_zcore, tokensList } from "./contracts";
import Multicall from '@dopex-io/web3-multicall';
import { getProvider , getAccount } from '@wagmi/core'
import getTokenPrice  from "./getprice";
import BigNumber from "bignumber.js";
import reactStringReplace from 'react-string-replace';
import funcDebug from "./debug";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

if(localStorage.getItem('isNftDB') === null){
    localStorage.setItem('isNftDB', 'true')
}   

const web3 = new Web3(jsonRpcURL)
const isNftDB = localStorage.getItem('isNftDB');


export const dataRaffle = async () => {

    let jsonRaffles = [];

    try {

    const agora = Math.floor(Date.now() / 1000);
    let lastUpdate = localStorage.getItem('lastUpdateDR');
    let atualiza = true;
    funcDebug('lastUpdate: agora: '+ agora);
    funcDebug('lastUpdate: lastUpdate: '+ lastUpdate);

    
    if(lastUpdate < agora){
        // ATUALIZA
        localStorage.setItem('lastUpdateDR', (agora + 6));
        funcDebug('lastUpdate: ATUALIZA');
        atualiza = true;
    }else{
        // NÃO ATUALIZA
        funcDebug('lastUpdate: NÃO ATUALIZA');
        atualiza = false;
    }        


    if(atualiza){    
        
    const currentAccount = getAccount();
    const currentAddress = currentAccount.address;
    

    
    const CreateLotteryContract = new web3.eth.Contract(CreateLotteryABI, CreateLotteryAddress)
    const allRaffles = await CreateLotteryContract.methods.totRaffles().call()


    let allCalls = [];
    let allPlayers = [];
    let allCallsData = [];
    for (let i = 0; i < allRaffles; i++) {
        // const ZLotteryAddress = allRaffles[i].add;
        // funcDebug(allRaffles[i].add);
        allCalls.push(CreateLotteryContract.methods.raffles(i));
        allCallsData.push(CreateLotteryContract.methods.rafflesData(i));
        allPlayers.push(CreateLotteryContract.methods.getUsersByLot(i));
    }

    
    const multicall = new Multicall({
        multicallAddress: MultiCallAddress,
        provider: jsonRpcURL,
        // defaultBlock: 1000 /* Optional */
      });

    const multicalldata = await multicall.aggregate(allCalls);
    const multicalldataPlus = await multicall.aggregate(allCallsData);
    const multicallplayers = await multicall.aggregate(allPlayers);
    //funcDebug('teste: '+ multicallplayers[0].length )
    funcDebug('teste: '+ JSON.stringify(multicalldata) )



    function checkUser(allp, data){
        let user;
        allp.forEach((alldata) => {
            if(alldata.data == data){
                user = alldata.user;
            }
        });
        return user;
    }


    for (let i = 0; i < multicalldata.length; i++) {


        let allp = [];
        let allpcount = [];

        for (let im = 0; im < multicallplayers[i].length; im++) {
            allpcount.push(Number(multicallplayers[i][im][2]));
        }

        for (let im = 0; im < multicallplayers[i].length; im++) {
            allp.push({
                user: multicallplayers[i][im][1],
                data: Number(multicallplayers[i][im][2]),
                total: allpcount.filter(x => x == Number(multicallplayers[i][im][2]) ).length,
            });        
        }

        const mediaTypes = allp
            .map(dataItem => dataItem.data) // get all media types
            .filter((mediaType, index, array) => array.indexOf(mediaType) === index); // filter out duplicates
      
        const counts = mediaTypes
            .map(mediaType => ({
                data: mediaType,
                user: checkUser(allp, mediaType),
                count: allp.filter(item => item.data === mediaType).length
        }));

        allp = counts;
        allp.sort((a, b) => (b.data > a.data) ? 1 : -1)
        //funcDebug('allp: '+ JSON.stringify(allp));
        
        
        const mediaTypesTop = allp
            .map(dataItem => dataItem.user) // get all media types
            .filter((mediaType, index, array) => array.indexOf(mediaType) === index); // filter out duplicates
  
        const countsTop = mediaTypesTop
            .map(mediaType => ({
                user: mediaType,
                count: allp.filter(item => item.user === mediaType).reduce((a,v) =>  a = a + v.count , 0 )
        }));

        //funcDebug('allp: '+ JSON.stringify(countsTop));

        let topp = countsTop;
        topp.sort((a, b) => (b.count > a.count) ? 1 : -1)
        //funcDebug('allp: '+ JSON.stringify(uniqueCategories));

/*
        const manager = await ZLotteryContract.methods.manager().call()
        const players = await ZLotteryContract.methods.getPlayers().call()
        const price = await ZLotteryContract.methods.price().call()
        const quorum = await ZLotteryContract.methods.quorum().call()
        const time = await ZLotteryContract.methods.time().call()
        const token = await ZLotteryContract.methods.token().call()
        const jackpot = await ZLotteryContract.methods.jackpot().call()
*/

        const idlot = multicalldata[i][0];
        const manager = multicalldata[i][1];
        const price = multicalldata[i][2];
        const quorum = multicalldata[i][3];
        const token = multicalldata[i][4];
        const operator = multicalldata[i][5];
        const time = multicalldata[i][6];
        const winner = multicalldata[i][7];
        const picked = multicalldata[i][8];
        const depositcontract = multicalldata[i][9];
        const isnft = multicalldata[i][10];

        //const idlot = multicalldataPlus[i][0];
        const balance = multicalldataPlus[i][1];
        const jackpot = multicalldataPlus[i][2];
        const created = multicalldataPlus[i][3];
        const started = multicalldataPlus[i][4];
        const finished = multicalldataPlus[i][5];        
        const requestId = multicalldataPlus[i][6];
        const maxperuser = multicalldataPlus[i][7];            
        const nftid = multicalldataPlus[i][8];
        const fee = multicalldataPlus[i][9];
        const zcoreclub = multicalldataPlus[i][10];




        let myentries = 0;
        for (let ip = 0; ip < multicallplayers[i].length; ip++) {        
            if(multicallplayers[i][ip][1] == currentAddress){
                myentries++;
            }
        }
        const players = multicallplayers[i].length;


        let tokenContract, tokenSymbol, tokenDecimal, tokenPriceContract, tokenName;
        for (let ia = 0; ia < tokensList.length; ia++) {
            funcDebug('stoken: ' + tokensList[ia].contract +' : '+ token);
            if(tokensList[ia].contract == token){
                tokenContract = tokensList[ia].contract;
                tokenSymbol = tokensList[ia].symbol;
                tokenDecimal = tokensList[ia].decimais;
                tokenPriceContract = tokensList[ia].contract_price;
                tokenName = tokensList[ia].name;
                funcDebug('stoken: '+tokenPriceContract)
            }
        }


        let artworkImage = 'https://zcore.network/raffles/'+ tokenSymbol +'.png'
        if(isnft){
            artworkImage = 'https://zcore.network/raffles/balck2.png';
        }

        if(isnft){
            //const metaAll = await getNfts(nftid, token, currentAddress);
            //artworkImage = metaAll.image;
        }
        /*
        if(isnft){
            const contractNft = new web3.eth.Contract(nftMiniABI, token)    
            let nftURI = await contractNft.methods.tokenURI(nftid).call()
            //nftURI = nftURI.replace("ipfs://", "https://ipfs.io/ipfs/");
            //funcDebug('metadataNFT: ' + nftURI)
            const newnftURI = nftURI.toString().replace("ipfs://", "https://ipfs.zcore.network/ipfs/");
            //nftURI = reactStringReplace('ipfs://', 'https://ipfs.io/ipfs/', (match, i) => (
            //    {match}
            //  ));
            funcDebug('metadataNFT: ' + newnftURI)
            //const requestNFT = new Request(nftURI);
            const metadataNFT = await fetch(newnftURI, {      
                //mode: "no-cors", // no-cors, *cors, same-origin
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                  "Content-Type": "application/json",
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
              });

            const metaAll = await metadataNFT.json();
            artworkImage = (metaAll.image).toString().replace("ipfs://", "https://ipfs.zcore.network/ipfs/");
            funcDebug('metadataNFT: ' + artworkImage)
            //const NFTMetadata = metadataNFT.json();
            //funcDebug(NFTMetadata.image)
        }
        */


        //funcDebug('stoken tokenPriceContract: ' + tokenPriceContract);
        
        //const getP = !isnft ? await getTokenPrice(tokenPriceContract) : {token: 0, bnb: 0};
        //const dataTokenPrice = getP.token;
        //const dataBnbPrice = getP.bnb;

        funcDebug('dataRaffle: tokenPriceContract: ' + tokenPriceContract);
        //funcDebug('dataRaffle: dataTokenPrice: ' + dataTokenPrice);
        //funcDebug('dataRaffle: dataBnbPrice: ' + dataBnbPrice);

        const priceFormated = (price / (10 ** 18));
        let artworkTitle = isnft ? tokenName+" #"+nftid : "[NOT STARTED]";
        if(jackpot > 0){
            const jackpotFormated = (jackpot / (10 ** tokenDecimal));
            artworkTitle = '' + Number(jackpotFormated.toFixed(6)) + " "+tokenSymbol;
        }
        

        let auctionStatus;
        const agora = Math.floor(Date.now() / 1000);
        //const finished = multicalldata[i].finished;


    
        if(finished == 0){
            auctionStatus = 'soon';
        }else if(agora > finished){
            auctionStatus = 'past';
        }else{
            auctionStatus = 'live';
        }

        //const token = multicalldata[i].token;


        funcDebug('dataRaffle: ==================================')
        funcDebug(idlot.toString());
        //funcDebug(ZLotteryAddress);
        funcDebug('dataRaffle: auctionStatus: ' + auctionStatus);
        funcDebug('dataRaffle: finished: ' + finished);
        funcDebug('dataRaffle: picked: ' + picked);
        funcDebug('dataRaffle: price: ' + price);
        funcDebug('dataRaffle: '+players +'/' + quorum);
        funcDebug('dataRaffle: priceFormated: ' + priceFormated);
        funcDebug('dataRaffle: tokenSymbol: ' + tokenSymbol);
        funcDebug('dataRaffle: tokenPriceContract: ' + tokenPriceContract);
        //funcDebug('dataRaffle: stoken dataTokenPrice: ' + dataTokenPrice);
        //funcDebug('dataRaffle: stoken dataBnbPrice: ' + dataBnbPrice);
        funcDebug('dataRaffle: isnft : ' + isnft.toString() + '/'+isNftDB.toString());
        //funcDebug(agora);
        funcDebug('dataRaffle: ==================================')        


        //const priceMoney = currencyFormat(Number(dataTokenPrice * (jackpot / (10 ** tokenDecimal))));
        //const floorPrice = dataTokenPrice > 0 ? ' (~'+ priceMoney +')' : '';
        

            jsonRaffles.push({
                id: idlot.toString(), 
                auctionStatus: auctionStatus,
                manager: manager,
                isnft: isnft,
                players: players,
                price: price,
                quorum: quorum,
                time: time,
                token: token,
                jackpot: jackpot,
                finished: finished,
                winner: winner,
                created: created,
                started: started,
                myentries: myentries,
                picked: picked,
                maxperuser: maxperuser,
                zcoreclub: zcoreclub,
                //tokenprice: dataTokenPrice,
                //bnbprice: dataBnbPrice,
                nftid: nftid,
                requestId: requestId,
                //zcontract: ZLotteryAddress,
                artworkImage: artworkImage,
                artworkTitle: artworkTitle,
                artworkDetails: "none",
                artworkPrice: "120 MINTO (120 USD)",
                highestBid: "0",
                currentBid: players +'/'+quorum,
                basePrice: priceFormated,
                boughtAt: "120 MINTO (141 USD)",
                creator: manager,
                creatorImage: "/images/userprofile.png",
                owner: "@mads",
                ownerImage: "/images/userprofile.png",
                uploaded: "12",
                allplayers: allp,
                topplayers: topp,
                likes: 40,
            });
      }
      
      jsonRaffles.sort((a, b) => (b.id > a.id) ? 1 : -1)
      //funcDebug( JSON.stringify(jsonRaffles) )
      localStorage.setItem('jsonRaffles', JSON.stringify(jsonRaffles));

    }else{
        jsonRaffles = JSON.parse(localStorage.getItem('jsonRaffles'));
    }


    } catch (error) {
    }

    return jsonRaffles;    
};