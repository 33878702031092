import { Typography } from "@mui/material";
import { Badge } from "@nextui-org/react";
import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

const ButtonZcoreClub = ({
    isZcoreClub, 
    buyNumber, 
    disableBuy, 
    coinNetwork, 
    ticketPriceClub,
    priceBnb
}) => {
    if(isZcoreClub){
      return (
    <>
                    <GradientButtonSecondary
                            style={{ 
                                width: '100%',
                                marginTop: '30px',
                                marginBottom: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '16px',           
                            }}
                            onClick={buyNumber}
                            disabled={disableBuy || !isZcoreClub}
                            variant="contained"
                            >
    
                        <Typography 
                              style={{ textTransform: 'none' }}
                              variant="body1" component="span">                        
                                  <b>Spin {ticketPriceClub} {coinNetwork} {
                                  priceBnb > 0 && 
                                  `(~${currencyFormat(ticketPriceClub*priceBnb)})`
                                  }</b>
                        </Typography>
                    </GradientButtonSecondary>
                    </>                
    );
    
    }else{
      return (
      <>
                  <GradientButtonSecondary
                            style={{ 
                                width: '100%',
                                marginTop: '30px',
                                marginBottom: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '16px',           
                            }}
                            onClick={buyNumber}
                            disabled={disableBuy || !isZcoreClub}
                            variant="contained"
                            >
    
                        
                        <Badge 
                          placement="bottom-left"
                          enableShadow size="xs" 
                          style={{ left: '0' }}
                          disableOutline
                          content="ZCoreClub Members" 
                          color="primary">
                        <Typography 
                              style={{ textTransform: 'none' }}
                              variant="body1" component="span">                        
                                  <b>Spin {ticketPriceClub} {coinNetwork} {
                                  priceBnb > 0 && 
                                  `(~${currencyFormat(ticketPriceClub*priceBnb)})`
                                  }</b>
                        </Typography>
                  </Badge>
                    </GradientButtonSecondary>
      </>
    );
    }
    }

    export default ButtonZcoreClub
