import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import Web3 from "web3";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

// Icons
import { TiCancel, TiTimes } from "react-icons/ti";
import { MdAddToPhotos, MdOutlineCancel } from "react-icons/md";
import { RiAddBoxFill } from "react-icons/ri";
import { ImImage } from "react-icons/im";
import { BiMenuAltLeft, BiRefresh } from "react-icons/bi";

import { GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";
import AssetProperModal from "../../components/AssetPropertiesModal/AssetProperModal";

import { useTranslation } from "react-i18next";
import { Card, Grid as GridNext, Text, Link, Button as ButtonNext } from "@nextui-org/react";

import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AssetProperModalMobile from "../../components/AssetPropertiesModal/AssetProperModalMobile";
import { FiChevronLeft } from "react-icons/fi";
import { getProvider , getAccount } from '@wagmi/core'
import { usePrepareContractWrite, useContractWrite , useBalance, useSigner} from 'wagmi'

import CreateLotteryABI from "../../contract/abi/CreateLotteryABI";
import FarmHorsesOwnersABI from "../../contract/abi/FarmHorsesOwnersABI";
import { FarmHorsesOwnersAdress, coinNetwork, jsonRpcURL, CreateLotteryAddress , nftMiniABI , miniABI , fee_zcore, fee_zcore_nft, tokensList as tokensListaAll} from "../../contract/contracts";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getTokenPrice  from "../../contract/getprice";
import { ethers } from "ethers";
import { IoAddCircle, IoAddCircleOutline, IoAlertCircle, IoCheckbox, IoCloudUpload, IoConstruct, IoSearchCircle, IoShieldCheckmarkOutline } from "react-icons/io5";
import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";
import getNfts  from "../../contract/getnfts";
import { Margin } from "@mui/icons-material";
import { BsArrowRightSquareFill } from "react-icons/bs";

const Input = styled("input")({
  display: "none",
});


const CreateAssets = ({ darkMode }) => {


  const metaAll = {
    name: "-",
    image: "https://zcore.network/raffles/balck2.png",
  };

  const [gpriceToken, setgPriceToken] = useState(0);
  const [gpriceBnb, setgPriceBnb] = useState(0);

  const [feYouPay, setFeYouPay] = useState(fee_zcore);
  
  
  
  useEffect(() => {
    async function viewPrice(){
      console.log('priceToken: ' + tokensList[0].contract_price)
      const npriceToken = await getTokenPrice(tokensList[0].contract_price);
      setgPriceToken(npriceToken.token)
      setgPriceBnb(npriceToken.bnb)
      console.log('priceToken: ' + npriceToken.token)
      console.log('priceToken: ' + npriceToken.bnb)
    }
    if (isNftDB == "false"){
      viewPrice();
    }
  }, []);




  useEffect(() => {
    async function viewBalNft(){
      const newbal = await nftContract.methods.balanceOf(currentAccount.address).call();
      setBalToken(newbal);
    }
    if (isNftDB == "true"){
      viewBalNft();
    }
  }, []);



  const imgdeafult = 'https://zcore.network/raffles/balck2.png';
  const isNftDB = localStorage.getItem("isNftDB");
  //console.log('isNftDB: ' + isNftDB)
  const tokensListBox = tokensListaAll.filter((all) => (all.isnft).toString() === isNftDB);
  const tokensList = tokensListBox;
  //console.log('isNftDB: ' + tokensListBox)
  const [image, setImage] = useState(null);

  const { t } = useTranslation();

  const [fixedButtonToggler, setFixedButtonToggler] = useState(true);
  const [openButtonToggler, setOpenButtonToggler] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [dateValueFrom, setDateValueFrom] = useState(new Date());
  const [dateValueTo, setDateValueTo] = useState(new Date());

  const [tokensend, setTokenSend] = useState(tokensList[0].contract);
  const [tokensendName, setTokenSendName] = useState(tokensList[0].symbol);
  const [maxusers, setMaxUsers] = useState(0);
  const [qtdtokens, setQtdtokens] = useState(0);
  const [pricebnb, setPriceBNB] = useState(0);
  const [maxperuser, setMaxperUser] = useState(0);
  const [duration, setDuration] = useState(60);
  const [runContract, setRunContract] = React.useState(false);
  const [treceived, setReceivedR] = useState(0);  
  const [balToken, setBalToken] = useState(0);  
  const [onclickCreate, setOnclickCreate] = useState(false);
  const [btnApprove, setDisableBtnApprove] = React.useState(false);
  const [tallowance, setTallowance] = React.useState(0);
  const [ownerOf, setOwnerOf] = React.useState(false);
  const [nftMetadata, setNftMetadata] = React.useState(metaAll);
  const [nftThumb, setNftThumb] = React.useState(imgdeafult);
  const [isTyping, setIsTyping] = React.useState(false);
  const [nameNFT, setNameNFT] = React.useState('-');
  const [myFarmHorses, setMyFarmHorses] = useState(0);  
  const [zcoreClub, setZCoreClub] = React.useState(false);

  const zcontract = CreateLotteryAddress;
  const { data: signer } = useSigner()



  async function checkMyNFTs() {

    console.log('mynfts start');
    if(signer !== undefined && signer !== null){

        //setDisableBtnApprove(true);
        const CreateLotteryContract = new ethers.Contract(
          CreateLotteryAddress,
          CreateLotteryABI,
          signer
        );

        try {
          const totalNFTS = await CreateLotteryContract.getNftsFarmTotal(currentAccount.address);
          console.log('mynfts total: ' + totalNFTS);
          setMyFarmHorses(totalNFTS.toNumber());
          if(totalNFTS.toNumber() > 0){
            setFeYouPay(fee_zcore_nft);
          }
        } catch (error) {
          console.log('mynfts: ' + error);
          
        }
      
    }
  }



  async function checkallowance() {

    console.log('checkallowance start');
    if(signer !== undefined && signer !== null){


      if(isNftDB == 'true'){

        //setDisableBtnApprove(true);
        const apcontract = new ethers.Contract(
          tokensend,
          nftMiniABI,
          signer
        );

        try {
          const checkAllowance = await apcontract.getApproved(qtdtokens)          
          const formattedAllowance = checkAllowance == CreateLotteryAddress ? 1000000000000000 : 0;
          console.log('checkallowance formattedAllowance: ' + formattedAllowance);
          setTallowance(formattedAllowance);
        } catch (error) {
          console.log('checkallowance: ' + error);
          //setDisableBtnApprove(true);
        }
      

      }else{

        const apcontract = new ethers.Contract(
          tokensend,
          miniABI,
          signer
        );
       
          const checkAllowance = await apcontract.allowance(manager , zcontract)
          const formattedAllowance = checkAllowance.toString();
          console.log('checkallowance formattedAllowance: ' + formattedAllowance);
          setTallowance(formattedAllowance);        

      }


    }
  }


  function ntOwner(){
    toast.info(`You are not the owner of this NFT!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    
  }

  async function approveallowance() {
    
    if(isNftDB == 'true'){

    
      const apcontract = new ethers.Contract(
        tokensend,
        nftMiniABI,
        signer
      );
  
      const approveTx = await apcontract.approve(zcontract, qtdtokens, {});
      setDisableBtnApprove(true);
      try {
          //notify('Please wait...');
  
          toast.info(`Wait, approving contract`, {
            position: "top-right",
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });                    
  
          await approveTx.wait();
          console.log(`Transaction mined succesfully: ${approveTx.hash}`)
          toast.dismiss();


          toast.success(`Now you can create the raffle!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });  

          //setParentCounter(approveTx.hash);
          checkallowance();
          setDisableBtnApprove(false);
      }
      catch (error) {
          console.log(`Transaction failed with error: ${error}`)
          setDisableBtnApprove(false);
      }          


    }else{

    
    const apcontract = new ethers.Contract(
      tokensend,
      miniABI,
      signer
    );

    const approveTx = await apcontract.approve(zcontract, ethers.constants.MaxUint256, {});
    setDisableBtnApprove(true);
    try {
        //notify('Please wait...');

        toast.info(`Wait, approving contract`, {
          position: "top-right",
          autoClose: 30000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });                    

        await approveTx.wait();
        console.log(`Transaction mined succesfully: ${approveTx.hash}`)
        toast.dismiss();

        toast.success(`Now you can create the raffle!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });  

        //setParentCounter(approveTx.hash);
        checkallowance();
        setDisableBtnApprove(false);
    }
    catch (error) {
        console.log(`Transaction failed with error: ${error}`)
        setDisableBtnApprove(false);
    }    


  }

  }


  useEffect(() => {
    checkMyNFTs();
    //if(manager == currentAccount.address ){
      if(isNftDB === "true"){
        //if(qtdtokens > 0){
          //checkallowance();
        //}
        setDisableBtnApprove(true);
      }else{
        checkallowance();
      }      
    //}
    //setParentCounter(2);
  }, [signer, tokensendName, qtdtokens]);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
  };



  const claimOwner = () => {
    writeOwner?.();
  }

  const clickCreate = () => {
    //setOnclickCreate(true);
    write?.();
  }



  const handleMaxusers = (event) => {
    //if(event.target.value < 1){
    //  setMaxUsers(1);      
    //}else{
      setMaxUsers(event.target.value);
    //}    
  };

  const handlePriceBnb = (event) => {
    //if(event.target.value < 0){
    //  setPriceBNB(0.01);
    //}else{
      //const temp_price = event.target.value > 0 ? parseFloat(event.target.value).toFixed(4) : 0;
      setPriceBNB(event.target.value);
    //}
  };


  const handleMaxPerUser = (event) => {
      setMaxperUser(event.target.value);
  };

  const handleTokenSend = (event) => {    
    setTokenSendName(tokensList[event.target.value].symbol);
    setTokenSend(tokensList[event.target.value].contract);
  };  


  const handleQtdTokens = (event) => {
    const nqtd = event.target.value > 0 ? event.target.value : 0;
    if(isNftDB === 'true'){

      console.log('NFT: ==========================='); 
      console.log('NFT: Digitando:' + event.target.value ) 
      console.log('NFT: ==========================='); 

      //setNftThumb(imgdeafult)
      //setNftMetadata(metaAll)
      setQtdtokens(nqtd);
    }else{      
      setQtdtokens(nqtd);
    }
  };  

  const handleDuration = (event) => {
    setDuration(event.target.value);
  };    


  const handleUsers = (event) => {
    setZCoreClub(event.target.value);
  };      

  useEffect(() => {
    setReceivedR(maxusers * pricebnb);

      if(maxusers > 0 && pricebnb >= 0 && maxperuser > 0 && tallowance > 0 && qtdtokens > 0){
        setRunContract(true);
      } else{
        setRunContract(false);
      }

  }, [maxusers, pricebnb, maxperuser, qtdtokens, duration, tallowance]);  

  /*
  console.log('maxusers: ' + maxusers);
  console.log('pricebnb: ' + pricebnb);
  console.log('nrec: ' + (maxusers * pricebnb));
  console.log('treceived: ' + treceived);
  */




  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}




  const navigate = useNavigate();
  const currentAccount = getAccount();
  // const currentProvider = getProvider(); 
  // const signer = getSigner();
  // console.log(signer);

  // const chainId = await web3.eth.getChainId();
  // console.log(web3);
  // console.log(currentAccount);  
  const web3 = new Web3(jsonRpcURL)


  const manager = currentAccount.address;
  const price = (new BigNumber(pricebnb * 1e18).toString());
  const quorum = maxusers;
  const token = tokensend;
  const time = duration;
  //const qtdtokensBig = new BigNumber(Number(qtdtokens)).pow(tokensList[0].decimais).toString();
  //const qtdtokensBig = new BigNumber.from(qtdtokens).mul(BigNumber.from(10).pow(tokensList[0].decimais))
  const qtdtokensBig = ethers.utils.parseUnits(qtdtokens.toString(), tokensList[0].decimais)

  console.log('==========================='); 
  console.log('manager: ' + manager);
  console.log('pricebnb: ' + pricebnb);
  console.log('price: ' + price);
  console.log('manquorumager: ' + quorum);
  console.log('token: ' + token);
  console.log('time: ' + time);
  console.log('maxperuser: ' + maxperuser);
  console.log('qtdtokens: ' + Number(qtdtokens));
  console.log('==========================='); 


  let thisRquestNFT;
  const nftContract = new web3.eth.Contract(nftMiniABI, token)

  
    async function viewNfts(){

      setDisableBtnApprove(true);
      setOwnerOf(false)
      setNftThumb(imgdeafult);
      setNameNFT('Loading...')


        if(qtdtokens > 0 && qtdtokens <= tokensList[0].maxcollection){
        setIsTyping(true);


          try {
            const ownerOf = await nftContract.methods.ownerOf(qtdtokens).call();
            console.log('NFT: ownerOf: ' + ownerOf)                

            const thisMetadata = await getNfts(qtdtokens, token, tokensList[0].name)
            setNftMetadata(thisMetadata.metadata);
            //const newbal = thisMetadata.balanceOf > 0 ? thisMetadata.balanceOf : 0;
            //setBalToken(newbal)
            if(thisMetadata === undefined){
              setNftThumb(imgdeafult);
            }else{
              const imagenft = thisMetadata.metadata.image;
              setNftThumb(imagenft);
            }
            console.log('NFT: ==========================='); 
            console.log('NFT: ID: ' + qtdtokens); 
            console.log('NFT: name: ' + thisMetadata.metadata.name); 
            console.log('NFT: ownerOf: ' + ownerOf);
            console.log('NFT: manager: ' + manager);       
            console.log('NFT: balanceOf: ' + thisMetadata.balanceOf); 
            console.log('NFT: image: ' + thisMetadata.metadata.image);       
            console.log('NFT: ==========================='); 

            const tnameNFT = `(ID ${qtdtokens}) ${thisMetadata.metadata.name}`;
            setNameNFT(tnameNFT)

            if(ownerOf == manager){
              setOwnerOf(true)
              setDisableBtnApprove(false);
            }else{
              ntOwner();
              setOwnerOf(false)
              setDisableBtnApprove(true);
            }

          } catch (e) {
            setNameNFT('-')
            setOwnerOf(false)
            setDisableBtnApprove(true);
            setNftThumb(imgdeafult);
          }
        setIsTyping(false);
        checkallowance();
        }else{
          setNameNFT('-')
          setOwnerOf(false)
          setDisableBtnApprove(true);
          setNftThumb(metaAll.image);
          setNftMetadata(metaAll);
        }

    }
      
    const clickQtdTokens = () => {
      viewNfts();    
    }



  
  const qtdPriceBigRefresh = ethers.utils.parseEther('0.002');

  const { config: configOwner, error: erroOwner } = usePrepareContractWrite({
      address: FarmHorsesOwnersAdress,
      abi: FarmHorsesOwnersABI,
      functionName: 'claimOwner',
      enabled: true,
      overrides: {
        from: currentAccount.address,
        value: qtdPriceBigRefresh,
      },      
      onSuccess(data) {
        console.log('claimOwner Success', data)
      },
      onError(error) {
        console.log('claimOwner Error', error)
      },    
  })
  const { data: dataOwner, isLoading: isLoadingOwner , isSuccess: isSuccessOwner, write: writeOwner } = useContractWrite(configOwner);



  async function updateRefreshNFTs(dataOwner){
    toast.dismiss();
    toast.info('Please wait, checking your Farm Horses NFTs on the Ethereum network...', {
      position: "top-right",
      autoClose: 60000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    
    await timeout(30000);
    toast.dismiss();
    console.log('isSuccessOwner: ' + JSON.stringify(dataOwner));    
    //navigate("/raffle?type=created"); 
    window.location.reload();
  }


  {(isSuccessOwner && typeof(dataOwner) !== 'undefined' && dataOwner != null) &&
    updateRefreshNFTs(dataOwner);    
  }

      

  console.log('NFT: GERAL ==========================='); 
  console.log('NFT: GERAL isNftDB: ' + isNftDB); 
  console.log('NFT: GERAL ownerOf: ' + ownerOf); 
  console.log('NFT: GERAL qtdtokens: ' + qtdtokens); 
  console.log('NFT: GERAL token: ' + token); 
  console.log('NFT: GERAL nftThumb: ' + nftThumb); 
  console.log('NFT: GERAL ==========================='); 
  
  const allArgs = isNftDB === 'true' ?
  [manager, price, quorum, token, time, maxperuser, true, qtdtokens, 0, zcoreClub]
  :
  [manager, price, quorum, token, time, maxperuser, false, 0, qtdtokensBig, zcoreClub]
  ;

  const { config, error } = usePrepareContractWrite({
    address: CreateLotteryAddress,
    abi: CreateLotteryABI,
    functionName: 'createRaffle',
    args: allArgs, 
    enabled: runContract,
    onSuccess(data) {
      console.log('Success', data)
    },
    onError(error) {
      console.log('Error', error)
    },    
  })
  const { data, isLoading, isSuccess, write } = useContractWrite(config);

  console.log('check: ===================================');
  console.log('check: isLoading: ' + isLoading);
  console.log('check: isSuccess: ' + isSuccess);
  console.log('check: ===================================');

  //{isLoading &&
    //console.log('isLoading: ' isLoading);
  //}
/*
  {isSuccess &&
    console.log(JSON.stringify(data));
  }  
*/

  async function updateRaffles(data){
    localStorage.setItem('lastUpdateDR', 0);
    toast.dismiss();
    toast.info('Wait, creating raffle...', {
      position: "top-right",
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    
    await timeout(10000);
    toast.dismiss();
    console.log('isSuccess: ' + JSON.stringify(data));    
    //navigate("/raffle?type=created"); 
    window.location.href = '/';
  }


  {(isSuccess && typeof(data) !== 'undefined' && data != null) &&
      updateRaffles(data);    
  }


  useEffect(() => {
    setOnclickCreate(isSuccess);
  }, [isSuccess]);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [propertiesDataState, setPropertiesDataState] = useState([
    { key: "", value: "" },
  ]);

  const [savedProperties, setSavedProperties] = useState([]);

  const handlePropertiesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...propertiesDataState];
    list[index][name] = value;
    setPropertiesDataState(list);
  };

  const handlePropertiesRemove = (id) => {
    const filteredRemaining = propertiesDataState.filter(
      (pds) => pds.id !== id
    );
    setPropertiesDataState(filteredRemaining);
  };

  const handlePropertiesAdd = () => {
    setPropertiesDataState([
      ...propertiesDataState,
      { key: "", value: "", id: Math.random() },
    ]);
  };

  const handleSavePropeties = () => {
    setSavedProperties([...propertiesDataState]);
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFixedToggler = () => {
    setFixedButtonToggler(true);
    setOpenButtonToggler(false);
  };

  const handleOpenToggler = () => {
    setOpenButtonToggler(true);
    setFixedButtonToggler(false);
  };

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const runBalance = (isNftDB === 'false') ? true : false;
  console.log('runBalance: ' + runBalance)

  const { data: dataBal, isError: isErrorBal, isLoading: isLoadingBal } = useBalance({
    address: currentAccount.address,
    enabled: runBalance,
    token: tokensend,
    onSuccess(data) {
      console.log('Success', data)
    },    
    onError(error) {
      console.log('Error', error)
    },    
  })

  useEffect(() => {
    if(isNftDB === "false"){
      if(dataBal){
        setBalToken( Number(dataBal.formatted) )
      }else{
        setBalToken(0)
      }
    }
    
  }, [dataBal?.formatted]);

  console.log(currentAccount.address)
  console.log(tokensend)
  console.log(dataBal?.formatted)
  console.log(dataBal?.symbol)



  const textTokenPrize = isNftDB == 'true' ? "NFT Collection Awarded" : "Token Awarded";

/*
const listNFTID = () => {
  for (let i = 0; i < multicalldata.length; i++) {
  }
}
*/

  return (
    <>
      <Box>
        {!isMobile ? (
          <AssetProperModal
            handlePropertiesChange={handlePropertiesChange}
            darkMode={darkMode}
            propertiesDataState={propertiesDataState}
            savedProperties={savedProperties}
            handlePropertiesAdd={handlePropertiesAdd}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            handlePropertiesRemove={handlePropertiesRemove}
            handleSavePropeties={handleSavePropeties}
          />
        ) : (
          <AssetProperModalMobile
            handlePropertiesChange={handlePropertiesChange}
            darkMode={darkMode}
            propertiesDataState={propertiesDataState}
            savedProperties={savedProperties}
            handlePropertiesAdd={handlePropertiesAdd}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            handlePropertiesRemove={handlePropertiesRemove}
            handleSavePropeties={handleSavePropeties}
          />
        )}
      </Box>
      {!isMobile ? (
        <Box zIndex={1000}>
          <Box sx={{ mt: 10.5, mb: 4 }}>
            <Typography
              component="div"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} style={{ color: '#ffffff' }} />
              </Box>
              <Typography
                variant="h6"
                component="p"
                color="secondary"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                }}
              >
                {t("CREATE_ASSET")}
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
              pr: 6,
              pl: 4,
              py: 4,
              borderRadius: "16px",
            }}
          >
  
            <Box component="form" sx={{ mt: 5 }}>
              <Grid
                zIndex={1000}
                container
                columns={{ md: 12 }}
                spacing={{ md: 15 }}
              >
                <Grid item md={6}>
                  <Box>



                    <Stack direction="column" key={13} spacing={2}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftDescription"
                      >
                        {t("DESCRIPTION")} *
                      </label>

                      <input
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          autoComplete="off"
                          onChange={handleMaxusers}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={t(
                            "CREATE_ASSET_PLACEHOLDER_PROVIDE_A_DETAILED_DESCRIPTION_OF_THE_ITEM"
                          )}
                          name="nftDescription"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />

                    </Stack>

                    <Stack direction="column" key={14} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftPrice"
                      >
                        {t("PRICE")} (0 for Free Raflle) *
                      </label>
                      <Stack direction="row" key={4} spacing={-9.5}>


                      <select 
                          autoComplete="off"
                          onChange={handlePriceBnb}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                          <option key={1} value={0}>FREE</option>
                          <option key={2} value={0.001}>0.001 {coinNetwork}</option>
                          <option key={3} value={0.005}>0.005 {coinNetwork}</option>
                          <option key={4} value={0.01}>0.01 {coinNetwork}</option>
                          <option key={5} value={0.05}>0.05 {coinNetwork}</option>
                          <option key={6} value={0.1}>0.10 {coinNetwork}</option>
                          <option key={7} value={0.5}>0.50 {coinNetwork}</option>
                          <option key={8} value={1}>1 {coinNetwork}</option>
                      </select>

                    </Stack>
                    </Stack>

                    <Stack direction="column" key={15} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="maxPeruser"
                      >
                        {t("Maximum Tickets per user")} *
                      </label>
                      <Stack direction="row" key={5} spacing={-9.5}>
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          onChange={handleMaxPerUser}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={t(
                            "Enter the maximum amount of Tickets per user"
                          )}
                          name="maxPeruser"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />
                    </Stack>     

                    
                                     

                    <Stack direction="column" key={6} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftToken"
                      >
                        {textTokenPrize} *
                      </label>
                      <Stack direction="row" key={1} spacing={-9.5}>

                      <select 
                          autoComplete="off"
                          onChange={handleTokenSend}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        {tokensListBox.map((data, index) => (
                          <option key={index} value={index}>{isNftDB == 'true' ? data.name : data.symbol}</option>
                        ))}
                      </select>

                  </Stack>



                  <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="qtdtokens"
                      >
                        {isNftDB == "true" ? (
                          `${t("NFT ID")} *`
                        ):(
                          `${t("Amount of Token Awarded")} *`
                        )}
                        
                      </label>
                      <Stack direction="row" key={5} spacing={-9.5}>                      
                      {isNftDB == 'true' ? (
                        <>
                      <GridNext.Container gap={2} justify="center">
                        <GridNext item md={6}>
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          onChange={handleQtdTokens}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={                        
                            isNftDB == "true" ? (
                              `${t("Enter the ID of your NFT")} *`
                            ):(
                              `${t("Enter the value of tokens that you will award")} *`
                            )}
                          name="qtdtokens"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "80%",
                            zIndex: 1000,
                          }}
                        />
                        </GridNext>
                      <GridNext item md={6}>
                      <GradientButtonPrimary
                      onClick={clickQtdTokens}
                      disabled={isTyping}
                      sx={{ 
                        paddingBottom: 0,
                        paddingTop: 0,
                        zIndex: '20000',
                        alignItems: "center"
                      }}
                    >
                      <IoSearchCircle style={{ fontSize: '38px', padding: '5px' }}/>
                      <Typography variant="body2" component="span">
                        {t("Search")}
                      </Typography>
                    </GradientButtonPrimary>     
                    </GridNext>
                    </GridNext.Container>
                    </>
                      ):(
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}                        
                          onChange={handleQtdTokens}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={                        
                            isNftDB == "true" ? (
                              `${t("Enter the ID of your NFT that you will send to the prize")} *`
                            ):(
                              `${t("Enter the value of tokens that you will award")} *`
                            )}
                          name="qtdtokens"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />                        
                      )}
                    </Stack>   


                      </Stack>
                    </Stack>




                    <Stack direction="column" key={7} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftName"
                      >
                        {t("NFT_NAME")} *
                      </label>

                      <select 
                          onChange={handleDuration}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        <option key={120} value={120}>2 MINUTES</option>
                        <option key={300} value={300}>5 MINUTES</option>
                        <option key={600} value={600}>10 MINUTES</option>
                        <option key={900} value={900}>15 MINUTES</option>
                        <option key={1800} value={1800}>30 MINUTES</option>
                        <option key={3600} value={3600}>1 HOUR</option>
                        <option key={43200} value={43200}>12 HOURS</option>
                        <option key={86400} value={86400}>1 DAY</option>
                        <option key={172800} value={172800}>2 DAYS</option>
                        <option key={604800} value={604800}>7 DAYS</option>
                      </select>

                    </Stack>






                    <Stack direction="column" key={8} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="zcoreClub"
                      >
                        {t("Users who can participate")} *
                      </label>

                      <select 
                          onChange={handleUsers}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        <option key={1} value={false}>Any User</option>
                        <option key={2} value={true}>ZCoreClub members only</option>
                      </select>

                    </Stack>                    


                  </Box>

                  {tallowance == 0 ? (
                      <GradientButtonPrimary
                      disabled={btnApprove}
                      onClick={approveallowance}
                      sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                    >
                      <IoShieldCheckmarkOutline style={{ fontSize: '22px' }}/>
                      <Typography variant="body2" component="span">
                        {t("Approve Contract")}
                      </Typography>
                    </GradientButtonPrimary>                    
/*

                        <GradientButtonSecondary
                          style={{ 
                            marginTop: '30px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',                      
                         }}
                          variant="contained"
                          onClick={approveallowance}
                          disabled={btnApprove}
                          >
                          <IoShieldCheckmarkOutline style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Approve Contract
                          </Typography>
                          </GradientButtonSecondary>
*/
                )  : (
                        <GradientButtonPrimary
                        disabled={!write || error || onclickCreate}
                        onClick={clickCreate}
                        sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                      >
                        <IoAddCircleOutline style={{ fontSize: '22px' }}/>
                        <Typography variant="body2" component="span">
                          {t("CREATE_ASSET")}
                        </Typography>
                      </GradientButtonPrimary>
                )}

                </Grid>


                <Grid item md={6}>

                    <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("You Send")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          style={{                            
                            color: `${ownerOf || isNftDB == "false" ? "#77DD77" : "#B6B9C9"}`,
                          }}
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {isNftDB == "true" ? (
                            `${nameNFT}`
                          ):(
                            `${qtdtokens} ${tokensendName} (BEP20)`
                          )}                 
                        </Typography>
                    </Box>
                    {isNftDB == "true" &&
                      <>
                      <Box
                      mb={4}
                      >
                        <img
                          style={{
                            filter: `${ownerOf || isNftDB == "false" ? "none" : "grayscale(100%)"}`,
                            display: 'block',
                            width: '250px',
                            height: '250px',
                            /*height: 400px;*/
                            borderRadius: '20px',
                          }}                          
                          src={nftThumb}
                          alt={nftThumb}
                          loading="eager"
                      />
                      </Box>
                      </>
                    }
                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Your Balance")}
                        </Typography>
                        
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={4}
                          fontWeight={500}
                        >
                          {isNftDB == "true" ? (
                            `${balToken} NFTs (this collection)`
                          ):(
                            `${balToken} ${tokensendName}`
                          )}
                          
                        </Typography>                        
                      </Box>         
                      
                      

                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Raffle Receive (if sold out)")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={4}
                          fontWeight={500}
                        >
                          {`${treceived.toFixed(4)} ${coinNetwork}`} ({`~$${(gpriceBnb*treceived).toFixed(2)}`} )
                        </Typography>
                      </Box>



                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("You receive (if sold out)")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={4}
                          fontWeight={500}
                        >
                          {`${((treceived)-((treceived) * (feYouPay/100))).toFixed(4)} ${coinNetwork}`} ({`~$${(gpriceBnb*(treceived)-((treceived) * (feYouPay/100))).toFixed(2)}`})
                        </Typography>
                      </Box>



                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          You have (pay {feYouPay}%)
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={1}
                          fontWeight={500}
                        >
                          {myFarmHorses} NFTs Farm Horses
                        </Typography>
                        <GradientButtonPrimary
                          disabled={!writeOwner || erroOwner}                                        
                          onClick={claimOwner}                          
                          sx={{ 
                            marginBottom: '30px',
                            paddingBottom: 0,
                            paddingTop: 0,
                            alignItems: "center"
                          }}
                        >
                          <BiRefresh style={{ fontSize: '28px', padding: '5px' }}/>
                          <Typography variant="body2" component="span">
                            Refresh My NFTs
                          </Typography>
                        </GradientButtonPrimary>                            
                      </Box>     



                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Platform Fee")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={0}
                          fontWeight={500}
                        >
                          <span style={{ color: '#FFFFFF' }}>{`${fee_zcore_nft}%`} with NFT Farm Horse</span><br />
                          <span style={{ color: '#FFFFFF' }}>{`${fee_zcore}%`} without NFT Farm Horse</span>
                        </Typography>
                      </Box>

                 


{/*
      <Box>
          <Card isHoverable variant="shadow" 
            css={{ p: "$1", mw: "400px" }} 
            style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '20px' , marginBottom: '18px' }}
          >
          <Card.Body css={{ py: "$1", pl: "$20", pr: "$20" }}>          
              <Text h3 color="warning" align="center">
              First create the raffle, then edits it to send the tokens, and only then it will be initiated.
              </Text>
          </Card.Body>
        </Card>                    
      </Box>
*/}
                </Grid>
              </Grid>


            </Box>
          </Box>
        </Box>

      ) : (
        <Box>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{                
                position: "relative",
                left: "38%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000",
                mt: -2,
              }}
            >
              <Box
                pb={0}
                ml={7}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography variant="subtitle1" color="secondary" mt={1.2}>
                <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} style={{ color: '#ffffff' }} />
              </Box>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="div"
                  sx={{
                    borderBottom: `${
                      darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                    }`,
                  }}
                >
                  {t("CREATE_ASSET")}
                </Typography>
              </Box>
            </Box>
          </Box>




          <Box component="form" sx={{ mt: 0 }}>
              <Grid
                zIndex={1000}
                container
                columns={{ md: 12 }}
                spacing={{ md: 15 }}
              >
                <Grid item md={12}>
                  <Box>



                    <Stack direction="column" key={8} spacing={2}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftDescription"
                      >
                        {t("DESCRIPTION")} *
                      </label>

                      <input
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          autoComplete="off"
                          onChange={handleMaxusers}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={t(
                            "CREATE_ASSET_PLACEHOLDER_PROVIDE_A_DETAILED_DESCRIPTION_OF_THE_ITEM"
                          )}
                          name="nftDescription"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />

                    </Stack>
                    <Stack direction="column" key={19} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftPrice"
                      >
                        {t("PRICE")} in {coinNetwork} *
                      </label>




                      <Stack direction="row" key={2} spacing={-9.5}>

                      <select 
                          autoComplete="off"
                          onChange={handlePriceBnb}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                          <option key={1} value={0}>FREE</option>
                          <option key={2} value={0.001}>0.001 {coinNetwork}</option>
                          <option key={3} value={0.005}>0.005 {coinNetwork}</option>
                          <option key={4} value={0.01}>0.01 {coinNetwork}</option>
                          <option key={5} value={0.05}>0.05 {coinNetwork}</option>
                          <option key={6} value={0.1}>0.10 {coinNetwork}</option>
                          <option key={7} value={0.5}>0.50 {coinNetwork}</option>
                          <option key={8} value={1}>1 {coinNetwork}</option>

                      </select>


                    </Stack>
                    </Stack>

                    <Stack direction="column" key={9} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="maxPerUser"
                      >
                        {t("Maximum Tickets per user")} *
                      </label>
                      <Stack direction="row" key={2} spacing={-9.5}>
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}                         
                          onChange={handleMaxPerUser}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={t(
                            "Enter the maximum amount of Tickets per user"
                          )}
                          name="maxPerUser"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />
                    </Stack>                    

                    <Stack direction="column" key={10} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftToken"
                      >
                        {textTokenPrize} *
                      </label>
                      <Stack direction="row" key={3} spacing={-9.5}>

                      <select 
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}                           
                          onChange={handleTokenSend}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        {tokensListBox.map((data, index) => (
                          <option key={index} value={index}>{isNftDB == 'true' ? data.name : data.symbol}</option>
                        ))}
                      </select>

                  </Stack>

                      </Stack>
                    </Stack>


                    <Stack direction="column" key={12} spacing={2} sx={{ mt: 3 }}>
                    <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="qtdtokens"
                      >
                        {isNftDB == "true" ? (
                          `${t("NFT ID")} *`
                        ):(
                          `${t("Amount of Token Awarded")} *`
                        )}
                      </label>
                      <Stack direction="row" key={2} spacing={-9.5}>
                      {isNftDB == 'true' ? (
                        <>
                      <GridNext.Container gap={2} justify="center">
                        <GridNext item md={6}>
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          onChange={handleQtdTokens}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={                        
                            isNftDB == "true" ? (
                              `${t("Enter the ID of your NFT")} *`
                            ):(
                              `${t("Enter the value of tokens that you will award")} *`
                            )}
                          name="qtdtokens"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "80%",
                            zIndex: 1000,
                          }}
                        />
                        </GridNext>
                      <GridNext item md={6}>
                      <GradientButtonPrimary
                      onClick={clickQtdTokens}
                      disabled={isTyping}
                      sx={{ 
                        paddingBottom: 0,
                        paddingTop: 0,
                        zIndex: '20000',
                        alignItems: "center"
                      }}
                    >
                      <IoSearchCircle style={{ fontSize: '38px', padding: '5px' }}/>
                      <Typography variant="body2" component="span">
                        {t("Search")}
                      </Typography>
                    </GradientButtonPrimary>     
                    </GridNext>
                    </GridNext.Container>
                    </>
                      ):(
                        <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}                        
                          onChange={handleQtdTokens}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          placeholder={                        
                            isNftDB == "true" ? (
                              `${t("Enter the ID of your NFT that you will send to the prize")} *`
                            ):(
                              `${t("Enter the value of tokens that you will award")} *`
                            )}
                          name="qtdtokens"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />                        
                      )}
                    </Stack>     
                </Stack>

                    <Stack direction="column" key={122} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="nftName"
                      >
                        {t("NFT_NAME")} *
                      </label>

                      <select 
                          onChange={handleDuration}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        <option key={120} value={120}>2 MINUTES</option>
                        <option key={300} value={300}>5 MINUTES</option>
                        <option key={600} value={600}>10 MINUTES</option>
                        <option key={900} value={900}>15 MINUTES</option>
                        <option key={1800} value={1800}>30 MINUTES</option>
                        <option key={3600} value={3600}>1 HOUR</option>
                        <option key={43200} value={43200}>12 HOURS</option>
                        <option key={86400} value={86400}>1 DAY</option>
                        <option key={172800} value={172800}>2 DAYS</option>
                        <option key={604800} value={604800}>7 DAYS</option>
                      </select>

                    </Stack>




                    <Stack direction="column" key={8} spacing={2} sx={{ mt: 3 }}>
                      <label
                        style={{
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        htmlFor="zcoreClub"
                      >
                        {t("Users who can participate")} *
                      </label>

                      <select 
                          onChange={handleUsers}
                          className={darkMode ? "inputField" : null}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "100%",
                            zIndex: 1000,        
                          }}
                      >
                        <option key={1} value={false}>Any User</option>
                        <option key={2} value={true}>ZCoreClub members only</option>
                      </select>

                    </Stack>                    

                  </Box>
                </Grid>


                <Grid item mt={5} md={6}>

                    <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("You Send")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {isNftDB == "true" ? (
                            `${nameNFT}`
                          ):(
                            `${qtdtokens} ${tokensendName} (BEP20)`
                          )}
                        </Typography>
                      </Box>

                      {isNftDB == "true" &&
                      <>
                      <Box
                      mb={4}
                      >
                        <img
                          style={{
                            filter: `${ownerOf || isNftDB == "false" ? "none" : "grayscale(100%)"}`,
                            display: 'block',
                            width: '250px',
                            height: '250px',
                            /*height: 400px;*/
                            borderRadius: '20px',
                          }}                          
                          src={nftThumb}
                          alt={nftThumb}
                          loading="eager"
                      />
                      </Box>
                      </>
                    }
                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Your Balance")}
                        </Typography>
                        
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={4}
                          fontWeight={500}
                        >
                          {isNftDB == "true" ? (
                            `${balToken} NFTs (this collection)`
                          ):(
                            `${balToken} ${tokensendName}`
                          )}
                          
                        </Typography>                        
                      </Box>                       

                      
                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Raffle Receive (if sold out)")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {`${treceived.toFixed(4)} ${coinNetwork}`} ({`~$${(gpriceBnb*treceived).toFixed(2)}`})
                        </Typography>
                      </Box>




                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("You receive (if sold out)")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={4}
                          fontWeight={500}
                        >
                          {`${((treceived)-((treceived) * (feYouPay/100))).toFixed(4)} ${coinNetwork}`} ({`~$${(gpriceBnb*(treceived)-((treceived) * (feYouPay/100))).toFixed(2)}`})
                        </Typography>
                      </Box>



                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          You have (pay {feYouPay}%)
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={1}
                          fontWeight={500}
                        >
                          {myFarmHorses} NFTs Farm Horses
                        </Typography>
                        <GradientButtonPrimary
                          disabled={!writeOwner || erroOwner}                                        
                          onClick={claimOwner}                          
                          sx={{ 
                            marginBottom: '30px',
                            paddingBottom: 0,
                            paddingTop: 0,
                            alignItems: "center"
                          }}
                        >
                          <BiRefresh style={{ fontSize: '28px', padding: '5px' }}/>
                          <Typography variant="body2" component="span">
                            Refresh My NFTs
                          </Typography>
                        </GradientButtonPrimary>                            
                      </Box>     


                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Platform Fee")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={0}
                          fontWeight={500}
                        >
                          <span style={{ color: '#FFFFFF' }}>{`${fee_zcore_nft}%`} with NFT Farm Horse</span><br />
                          <span style={{ color: '#FFFFFF' }}>{`${fee_zcore}%`} without NFT Farm Horse</span>
                        </Typography>
                      </Box>



{/*
                      <Box>
          <Card isHoverable variant="shadow" 
            css={{ p: "$1", mw: "400px" }} 
            style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '20px' , marginBottom: '18px' }}
          >
          <Card.Body css={{ py: "$1", pl: "$20", pr: "$20" }}>          
              <Text h3 color="warning" align="center">
              First create the raffle, then edits it to send the tokens, and only then it will be initiated.
              </Text>
          </Card.Body>
        </Card>                    
      </Box>
*/}
                </Grid>
              </Grid>

              {tallowance == 0 ?(
                      <Box>
                        <GradientButtonSecondary
                          style={{ 
                            marginTop: '15px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',                      
                         }}
                          variant="contained"
                          onClick={approveallowance}
                          disabled={btnApprove}
                          >
                          <IoShieldCheckmarkOutline style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Approve Contract
                          </Typography>
                          </GradientButtonSecondary>
                          </Box>
              ):(

              <GradientButtonPrimary
                disabled={!write || error || onclickCreate}
                onClick={clickCreate}
                sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
              >
                <Typography component="span" color="#ffffff">
                  <MdAddToPhotos />
                </Typography>
                <Typography variant="body2" component="span">
                  {t("CREATE_ASSET")}
                </Typography>
              </GradientButtonPrimary>
              )}
            </Box>


        </Box>
      )}
    </>
  );
};

export default CreateAssets;
