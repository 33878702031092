const SelectNumbers = ({
    maxNumber,
    darkMode,
    handleMyNumber,
    disableSelect,
}) => {

    let options = [];
    options.push(<option key={99999} value={0}>Select number</option>);
    //options.push({ value: 0, label: `Select number` });
    for (let i = 0; i < maxNumber; i++) {
        const number_r = i + 1;
        //options.push({ value: number_r, label: number_r });
        options.push(<option key={number_r} value={number_r}>{number_r}</option>)
    }    

return (<>
<select 
        disabled={disableSelect}
        autoComplete="off"
        onChange={handleMyNumber}
        style={{
          fontSize: "14px",
          border: "1px solid #c4c4c4",
          borderRadius: "6px",
          padding: "1rem 1.5rem",
          color: `${darkMode ? "#ffffff" : "#121212"}`,
          backgroundColor: `${
            darkMode ? "#171c26" : "#ffffff"
          }`,
          width: "100%",
          zIndex: 1000,        
        }}
        >          
          {options}
        </select>
</>)
        };

        
  
export default SelectNumbers;       