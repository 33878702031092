import React, { useEffect } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';

// Icons
import { AiFillCaretDown } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { FiChevronLeft } from "react-icons/fi";

const FAQ = ({ darkMode }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {!isMobile ? (
        <div style={{ overflowY: 'hidden' }}>
          <Box
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              position: 'relative',
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              mt: 11.5,
              mb: 5,
            }}
          >


<Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} style={{ color: '#ffffff' }} />
              </Box>
              <Typography
                variant="h6"
                component="p"
                color="secondary"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                }}
              >
                FAQ
              </Typography>


          </Box>
          <Box>


            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>This raffle looks suspicious, can you delete it?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  No, ZCore Web3Games is a decentralized platform. We cannot interfere with raffles because we are not the hosts. If you think a raffle looks suspicious you can report it on our discord server by opening a ticket and we can flag the host so you will know that they are gaming the system. MAKE SURE TO READ THE RAFFLE GUIDELINES TOO BEFORE USING THE PLATFORM!
                </Typography>
              </AccordionDetails>
            </Accordion>





            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                Are ZCore Web3Games responsible for Raffles?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  We guarantee a platform for users to host their raffles, but hosts are responsible for it.
                </Typography>
              </AccordionDetails>
            </Accordion>            







            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I didn't profit from this raffle, can I have a refund?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  No, we do not guarantee profits on our raffles. Raffles are supposed to be fun and you have to agree with the terms and conditions to host one.
                </Typography>
              </AccordionDetails>
            </Accordion>            








            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I bought a ticket by mistake, can I have a refund?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  No, raffle tickets aren't refundable once bought, so be careful about this!
                </Typography>
              </AccordionDetails>
            </Accordion>            









            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I had a 99.99% chance of winning and still lost, is this rigged?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  No! Understand probability, even with a 99% chance of winning you still have a 1% chance of losing! Want to understand how our raffles work? Click here.
                </Typography>
              </AccordionDetails>
            </Accordion>            








{/*
            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                The raffle timer ended and ticket sales still happened. Is that right?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
                  Yes. After the timer ends people in the queue will have their chance to buy tickets before the winners are drawn. If you see the raffle in the "Drawing Winners" phase it's because there are still some transactions processing. After the timer ends you cannot join the queue, so if you leave the queue before buying the ticket you will lose the chance to participate. Because of that we strongly advise you to not wait until the last minute to buy tickets. Any communication problem with our API could kick you out of the queue and end your chance of participating in the raffle. By leaving to the last minute you are accepting this risk and there is nothing we will be able to do in case that happens.
                </Typography>
              </AccordionDetails>
            </Accordion>            


*/}






            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                Is there a fee reduction for holders?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
Yes. We are excited to inform you that if you own an NFT from the Farm Horses collection, you are eligible for a special discount on our raffle fees. With this NFT, the raffle fee will be reduced from 5% to 0%, giving you even more chances to win great prizes!<br />
Please note that you can only use one NFT per wallet for each raffle you create. This means that if you have multiple NFTs, you can only use one per wallet for each raffle.
                </Typography>
              </AccordionDetails>
            </Accordion>            







            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                What is the contribution of the ZCore Web3Games platform to the growth of the ZCore Network?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  sx={{
                    backgroundColor: `${darkMode ? '#171C26' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                  style={{
                    padding: '20px',
                  }} 
                >
The ZCore Web3Games gaming platform directly contributes to the growth of the ZCore network, bringing benefits to holders of ZBO, ZCR, and ZEFI tokens.
<br /><br />
One of these benefits is a discount on the fee for creators of raffles who own NFTs from the Farm Horse collection. Instead of paying the standard 5% fee, they pay 0%, receiving 100% of the funds raised. Additionally, roulette players only pay 50% of the amount if they own a Farm Horse NFT. As more Farm Horse NFTs are sold, more ZEFI will be burned, directly contributing to an increase in the price of ZEFI. And if all the NFTs in the collection are sold, all the ZEFI issued will be burned. Let's not forget that with the sale of the entire collection, yield farming will be reactivated, with the price of ZEFI being much better.
<br /><br />
If a user wins at roulette and they have no referrals, the 10% that would have been sent to the referral is accumulated in a pool, and then a free raffle is created for ZCoreClub members only (those who own Farm Horse NFT).
<br /><br />
The system also directly affects the price of ZBO, as the ZEFI-ZBO pool tends to raise the price of ZBO with NFT purchases. And to top it off, the 5% fees generated by the ZCore Web3Games platform are used for the direct buyback WZCR tokens, which also contributes to its increase in value.
<br /><br />
Therefore, the ZCore Web3Games platform is an excellent opportunity for ZBO, ZCR, and ZEFI token holders to increase their investments and ensure even greater growth in the ZCore network. Take advantage of this opportunity and become a part of this gaming community!
                </Typography>
              </AccordionDetails>
            </Accordion>            




          </Box>
        </div>
      ) : (
        <div style={{ paddingBottom: '1rem' }}>
          <Box
            sx={{
              position: 'fixed',
              top: '0%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '10000',
              mt: 0,
            }}
          >
            <Typography
              variant='subtitle1'
              color='secondary'
              component='div'
              sx={{
                borderBottom: `${
                  darkMode ? '2px solid #ffffff' : '1px solid #171c26'
                }`,
              }}
            >
              
            </Typography>
          </Box>
          <Box sx={{ mt: 0 }}>
            
            
            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                  {t('This raffle looks suspicious, can you delete it?')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  {t('No, ZCore Web3Games is a decentralized platform. We cannot interfere with raffles because we are not the hosts. If you think a raffle looks suspicious you can report it on our discord server by opening a ticket and we can flag the host so you will know that they are gaming the system. MAKE SURE TO READ THE RAFFLE GUIDELINES TOO BEFORE USING THE PLATFORM!')}
                </Typography>
              </AccordionDetails>
            </Accordion>

            




            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                Are ZCore Web3Games responsible for Raffles?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  We guarantee a platform for users to host their raffles, but hosts are responsible for it.
                </Typography>
              </AccordionDetails>
            </Accordion>

            
            




            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I didn't profit from this raffle, can I have a refund?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  No, we do not guarantee profits on our raffles. Raffles are supposed to be fun and you have to agree with the terms and conditions to host one.
                </Typography>
              </AccordionDetails>
            </Accordion>

            




            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I bought a ticket by mistake, can I have a refund?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  No, raffle tickets aren't refundable once bought, so be careful about this!
                </Typography>
              </AccordionDetails>
            </Accordion>

            




            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                I had a 99.99% chance of winning and still lost, is this rigged?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  No! Understand probability, even with a 99% chance of winning you still have a 1% chance of losing! Want to understand how our raffles work? Click here.
                </Typography>
              </AccordionDetails>
            </Accordion>

            

{/*

            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                The raffle timer ended and ticket sales still happened. Is that right?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
                  Yes. After the timer ends people in the queue will have their chance to buy tickets before the winners are drawn. If you see the raffle in the "Drawing Winners" phase it's because there are still some transactions processing. After the timer ends you cannot join the queue, so if you leave the queue before buying the ticket you will lose the chance to participate. Because of that we strongly advise you to not wait until the last minute to buy tickets. Any communication problem with our API could kick you out of the queue and end your chance of participating in the raffle. By leaving to the last minute you are accepting this risk and there is nothing we will be able to do in case that happens.
                </Typography>
              </AccordionDetails>
            </Accordion>

            
*/}


            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                  Is there a fee reduction for holders?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
Yes. We are excited to inform you that if you own an NFT from the Farm Horses collection, you are eligible for a special discount on our raffle fees. With this NFT, the raffle fee will be reduced from 5% to 0%, giving you even more chances to win great prizes!<br />
Please note that you can only use one NFT per wallet for each raffle you create. This means that if you have multiple NFTs, you can only use one per wallet for each raffle.
                </Typography>
              </AccordionDetails>
            </Accordion>

            
                                                                        


            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                color: `${darkMode ? '#ffffff' : '#121212'}`,
              }}
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                }}
                expandIcon={
                  <Typography component='span' color='secondary'>
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography variant='subtitle1'>
                What is the contribution of the ZCore Web3Games platform to the growth of the ZCore Network?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant='body2'
                  style={{
                    padding: '15px',
                  }} 
                  sx={{
                    backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
                    color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: 'justify',
                    pr: 2,
                  }}
                >
The ZCore Web3Games gaming platform directly contributes to the growth of the ZCore network, bringing benefits to holders of ZBO, ZCR, and ZEFI tokens.
<br /><br />
One of these benefits is a discount on the fee for creators of raffles who own NFTs from the Farm Horse collection. Instead of paying the standard 5% fee, they pay 0%, receiving 100% of the funds raised. Additionally, roulette players only pay 50% of the amount if they own a Farm Horse NFT. As more Farm Horse NFTs are sold, more ZEFI will be burned, directly contributing to an increase in the price of ZEFI. And if all the NFTs in the collection are sold, all the ZEFI issued will be burned. Let's not forget that with the sale of the entire collection, yield farming will be reactivated, with the price of ZEFI being much better.
<br /><br />
If a user wins at roulette and they have no referrals, the 10% that would have been sent to the referral is accumulated in a pool, and then a free raffle is created for ZCoreClub members only (those who own Farm Horse NFT).
<br /><br />
The system also directly affects the price of ZBO, as the ZEFI-ZBO pool tends to raise the price of ZBO with NFT purchases. And to top it off, the 5% fees generated by the ZCore Web3Games platform are used for the direct buyback WZCR tokens, which also contributes to its increase in value.
<br /><br />
Therefore, the ZCore Web3Games platform is an excellent opportunity for ZBO, ZCR, and ZEFI token holders to increase their investments and ensure even greater growth in the ZCore network. Take advantage of this opportunity and become a part of this gaming community!
                </Typography>
              </AccordionDetails>
            </Accordion>


          </Box>
        </div>
      )}
    </>
  );
};

export default FAQ;
