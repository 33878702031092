import React from "react";

// Material components
import {
  Container,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

// Main logo
import MainLogo from "../../assets/mainLogo.png";
import MainLogoLight from "../../assets/mainLogoLight.png";

// Icons
import { RiTwitterFill, RiInstagramLine, RiTelegramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ darkMode }) => {
  const { t } = useTranslation();

  return (
    <Container sx={{ mt: 2, py: 6 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ maxWidth: "430px" }}>
          <Box>
            {darkMode ? (
              <img src={MainLogo} alt="Minto" />
            ) : (
              <img src={MainLogoLight} alt="Minto" />
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{ color: "#0f5f54", mb: 1, fontWeight: "bold" }}
              variant="subtitle1"
              component="h4"
            >
              {t("FOOTER_TITLE_MAIN")}
            </Typography>
            <Typography
              sx={{ color: "secondary.main" }}
              variant="body2"
              component="p"
            >
              {t("FOOTER_DETAILS")}
            </Typography>
          </Box>
          <Box sx={{ ml: -1.3 }}>
          <a rel="noopener" target="_blank" href="https://twitter.com/zcorecrypto">
            <IconButton>            
              <RiTwitterFill
                style={{ color: `${darkMode ? "#ffffff" : "#171c26"}` }}
              />            
            </IconButton>
            </a>
            <a rel="noopener" target="_blank" href="https://t.me/zcoreminers">
            <IconButton>
              <RiTelegramFill
                style={{ color: `${darkMode ? "#ffffff" : "#171c26"}` }}
              />
            </IconButton>
            </a>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>
          {/*
          <Box zIndex={1000}>
            <Typography
              color="secondary"
              sx={{ mb: 1, fontWeight: 700 }}
              variant="subtitle1"
              component="h4"
            >
              {t("FOOTER_TITLE_MARKETPLACE")}
            </Typography>
            <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/explore?type=all"
                >
                  {t("FOOTER_LINK_EXPLORE")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/create-asset"
                >
                  {t("FOOTER_LINK_CREATE")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/trending-sellers"
                >
                  {t("FOOTER_LINK_SELLERS")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/trending-creators"
                >
                  {t("FOOTER_LINK_CREATORS")}
                </Link>
              </ListItem>
            </List>
          </Box>
            */}
          <Box>
            <Typography
              color="secondary"
              sx={{ mb: 1, fontWeight: 700 }}
              variant="subtitle1"
              component="h4"
            >
              {t("ABOUT")}
            </Typography>
            <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/privacy-policy"
                >
                  {t("FOOTER_LINK_PRIVACY_POLICY")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/terms-and-condition"
                >
                  {t("FOOTER_LINK_TERMS_CONDITION")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/frequently-asked-questions"
                >
                  {t("FOOTER_LINK_FAQS")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                  <a 
                          style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                          rel="noopener noreferrer" href="https://testnet.bnbchain.org/faucet-smart" target="_blank">
                          {t("Get tBNB")}
                  </a>
              </ListItem>              
              {/*
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/contact-us"
                >
                  {t("FOOTER_LINK_CONTACT_US")}
                </Link>
              </ListItem>
            */}
            </List>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="caption"
        component="div"
        sx={{ color: "gray", textAlign: "center", mt: 2 }}
      >
        &copy; 2023 {t("FOOTER_COPYRIGHT")}
      </Typography>
    </Container>
  );
};

export default Footer;
