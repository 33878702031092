import Web3 from "web3";
import { nftMiniABI, MultiCallAddress , jsonRpcURL , CreateLotteryAddress , fee_zcore, tokensList } from "./contracts";
import Multicall from '@dopex-io/web3-multicall';

const getNfts = async (tokenID, contract, nameCollection) => {
          const web3 = new Web3(jsonRpcURL)

          const multicall = new Multicall({
            multicallAddress: MultiCallAddress,
            provider: jsonRpcURL,
            // defaultBlock: 1000 /* Optional */
          });          

          let allCalls = [];

          //const contractNft = new web3.eth.Contract(nftMiniABI, contract)    
          const nftContract = new web3.eth.Contract(nftMiniABI, contract)
          allCalls.push(nftContract.methods.tokenURI(tokenID))
          //address !== undefined && allCalls.push(nftContract.methods.ownerOf(tokenID));
          //address !== undefined && allCalls.push(nftContract.methods.balanceOf(address));
          
          console.log('allCalls: ' + tokenID);
          console.log('allCalls: ' + contract);
          //console.log('allCalls: ' + address);
          console.log('allCalls: ' + allCalls.length);
          const multicalldata = await multicall.aggregate(allCalls);
          console.log('multicalldata: ' + multicalldata.length);
          //const ownerOf = address == null ? 0 : await nftContract.methods.ownerOf(tokenID).call()
          //const balanceOf =  address == null ? 0 : await nftContract.methods.balanceOf(address).call()

          //const ownerOf = address !== undefined ? multicalldata[1] : null;
          //const balanceOf =  address !== undefined ? multicalldata[2] : null;


          //let artworkName = "#" + tokenID
          //let artworkImage = "https://us.123rf.com/450wm/yehorlisnyi/yehorlisnyi2104/yehorlisnyi210400016/167492439-no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image.jpg?ver=6";
          let metaAll = {
              name: "-",
              image: "https://zcore.network/raffles/balck2.png",
            };
          //if(ownerOf){                                  
            //let nftURI = await contractNft.methods.tokenURI(tokenID).call()
            let nftURI = multicalldata[0]

            console.log('metadataNFT: ' + nftURI);
            //console.log('metadataNFT: ' + JSON.stringify(metaAll));

            const newnftURI = nftURI.toString().replace("ipfs://", "https://ipfs.zcore.network/ipfs/");
            console.log('metadataNFT: ' + newnftURI);

            //console.log('metadataNFT: ' + newnftURI)
            const metadataNFT = await fetch(newnftURI, {      
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                  "Content-Type": "application/json",
                },
              });
              metaAll = await metadataNFT.json();
              console.log('metadataNFT: ' + JSON.stringify(metaAll));
              
            
            if(metaAll.name === undefined || metaAll.name === null){
                metaAll.name = nameCollection + " #" + tokenID;
            }
            //artworkName = metaAll.name;
            let metaImageCache = localStorage.getItem(tokenID+'_'+contract);
            if(metaImageCache === null){
              metaAll.image = (metaAll.image).toString().replace("ipfs://", "https://ipfs.zcore.network/ipfs/");
              localStorage.setItem(tokenID+'_'+contract , metaAll.image);
            }else{
              metaAll.image = metaImageCache;
            }
            
            console.log('metadataNFT: ' + metaAll.name)
        //}

        return {
            //balanceOf: balanceOf,
            //ownerOf: ownerOf, 
            metadata: metaAll,
        };
}

export default getNfts