import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Input,
  InputAdornment,
  Grow,
  MenuItem,
  OutlinedInput,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import NftBookmarks from './NftBookmarks';
import AuctionBookmark from './AuctionBookmark';
import SearchIconExplore from '../../assets/Icons/darkUIIcons/searchIconExplore.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import TuneIcon from '@mui/icons-material/Tune';
import FilterListIcon from '@mui/icons-material/FilterList';
import { BiSort } from 'react-icons/bi';
import BackgroundWrinkles1 from '../../assets/BackgroundWrinkles1.svg';
import BackgroundWrinklesLight from '../../assets/backgroundWrinklesLight.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { StyledMenu } from '../../components/StyledMenu/StyledMenu';

const Favourites = ({ darkMode }) => {
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t('DATE_CREATED'));
  const [orderOptions, setOrderOptions] = useState(t('NEWEST'));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);

  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let query = useQuery();

  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt='Wrinkles'
        />
      </Box>
      {!isMobile ? (
        <Box
          sx={{ width: '100%', mt: 11.5 }}
          color={darkMode ? '#ffffff' : '#121212'}
        >
          <Stack direction='row' alignItems='center' gap={3} mb={3}>
            <Box width='35%'>
              <Input
                disableUnderline
                fullWidth
                placeholder={t('SEARCH_IN_EXPLORE')}
                sx={{
                  background: `${darkMode ? '#171c26' : '#fff2f8'}`,
                  border: 'none',
                  py: 1.6,
                  px: 2,
                  borderRadius: '5px',
                }}
                startAdornment={
                  <InputAdornment position='start'>
                    <img src={SearchIconExplore} alt='Search' />
                  </InputAdornment>
                }
              />
            </Box>
            <Box>
              <Button
                onClick={handleOpenSortMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
                variant='contained'
                color='accent'
                fullWidth
                endIcon={
                  <Box color='secondary' mt={1.5}>
                    {openSortMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: 'capitalize' }}
                  variant='subtitle1'
                  color='secondary'
                >
                  {t('SORT_BY')}
                </Typography>
                <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                  {sortOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: '5px',
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={sortAnchorEl}
                open={openSortMenu}
                onClose={handleCloseSortMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSorOptions(t('DATE_CREATED'));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction='row' gap={1} alignItems='center'>
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('DATE_CREATED')}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSorOptions(t('POPULARITY'));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction='row' gap={1} alignItems='center'>
                    <BiSort style={{ height: 25, width: 25 }} />
                    <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                      {t('POPULARITY')}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
            {/* Order */}
            <Box>
              <Button
                onClick={handleOpenOrderMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
                variant='contained'
                color='accent'
                fullWidth
                endIcon={
                  <Box color='secondary' mt={1.5}>
                    {openOrderMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: 'capitalize' }}
                  variant='subtitle1'
                  color='secondary'
                >
                  {t('ORDER_BY')}
                </Typography>
                <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                  {orderOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: '5px',
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={orderAnchorEl}
                open={openOrderMenu}
                onClose={handleCloseOrderMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t('NEWEST'));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction='row' gap={1} alignItems='center'>
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('NEWEST')}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t('OLDEST'));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction='row' gap={1} alignItems='center'>
                    <UpdateIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('OLDEST')}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
          <Box
            bgcolor={darkMode ? '#171c26' : '#fff2f8'}
            sx={{
              borderRadius: '10px',
              pl: 5,
              py: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 15,
            }}
          >
            <Button
              color='secondary'
              onClick={() => navigate('/favourites?type=nft-bookmark')}
              sx={{ textTransform: 'capitalize' }}
            >
              <Typography
                sx={
                  location.search === '?type=nft-bookmark'
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? '#ffffff' : '#171c26'
                        }`,
                      }
                    : {}
                }
                variant='body2'
                component='span'
              >
                {t('NFT_BOOKMARK')}
              </Typography>
            </Button>
            <Button
              color='secondary'
              onClick={() => navigate('/favourites?type=auction-bookmark')}
              sx={{ textTransform: 'capitalize' }}
            >
              <Typography
                sx={
                  location.search === '?type=auction-bookmark'
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? '#ffffff' : '#171c26'
                        }`,
                      }
                    : {}
                }
                variant='body2'
                component='span'
              >
                {t('AUCTION_BOOKMARK')}
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: '100%', mt: 3 }}>
          <Stack direction='row' alignItems='center' gap={3} mb={2}>
            <Box>
              <OutlinedInput
                size='small'
                fullWidth
                startAdornment={
                  <InputAdornment position='start'>
                    <img
                      height={15}
                      width={15}
                      src={SearchIconExplore}
                      alt='Search'
                    />
                  </InputAdornment>
                }
              />
            </Box>
            {/* Sort */}
            <Box>
              <IconButton
                onClick={handleOpenSortMenu}
                variant='contained'
                fullWidth
              >
                <TuneIcon />
              </IconButton>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: '5px',
                    backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                  },
                }}
                anchorEl={sortAnchorEl}
                open={openSortMenu}
                onClose={handleCloseSortMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => setSorOptions('Date Created')}>
                  <Stack direction='row' gap={1} alignItems='center'>
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('DATE_CREATED')}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => setSorOptions('Popularity')}>
                  <Stack direction='row' gap={1} alignItems='center'>
                    <BiSort style={{ height: 25, width: 25 }} />
                    <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                      {t('POPULARITY')}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
            {/* Order */}
            <Box>
              <IconButton
                onClick={handleOpenOrderMenu}
                variant='contained'
                fullWidth
              >
                <FilterListIcon />
              </IconButton>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: '5px',
                    backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={orderAnchorEl}
                open={openOrderMenu}
                onClose={handleCloseOrderMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => setOrderOptions('Newest')}>
                  <Stack direction='row' gap={1} alignItems='center'>
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('NEWEST')}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => setOrderOptions('Oldest')}>
                  <Stack direction='row' gap={1} alignItems='center'>
                    <UpdateIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant='subtitle1' fontWeight={400}>
                      {t('OLDEST')}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'fixed',
                top: '3%',
                zIndex: '10000',
                width: '75%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box pb={2} ml={7}>
                <Typography
                  variant='subtitle1'
                  color='secondary'
                  component='div'
                  sx={{
                    borderBottom: `${
                      darkMode ? '2px solid #ffffff' : '1px solid #171c26'
                    }`,
                  }}
                >
                  {t('FAVOURITES')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              bgcolor={
                location.search === '?type=nft-bookmark'
                  ? {
                      backgroundColor: `#01D4FA`,
                    }
                  : {
                      backgroundColor: `${darkMode ? '#171c26' : '#fff2f8'}`,
                    }
              }
              onClick={() => navigate('/favourites?type=nft-bookmark')}
              sx={{
                color: `#ffffff`,
                py: 1,
                px: 2,
                borderRadius: '4px',
                zIndex: 1000,
              }}
            >
              <Typography
                variant='body2'
                color='secondary'
                sx={{ fontSize: '12px' }}
              >
                {t('NFT_BOOKMARK')}
              </Typography>
            </Box>
            <Box
              bgcolor={
                location.search === '?type=auction-bookmark'
                  ? {
                      backgroundColor: `#01D4FA`,
                    }
                  : {
                      backgroundColor: `${darkMode ? '#171c26' : '#fff2f8'}`,
                    }
              }
              onClick={() => navigate('/favourites?type=auction-bookmark')}
              sx={{
                color: `#ffffff`,
                py: 1,
                px: 2,
                borderRadius: '4px',
                zIndex: 1000,
              }}
            >
              <Typography
                variant='body2'
                color='secondary'
                sx={{ fontSize: '12px' }}
              >
                {t('AUCTION_BOOKMARK')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <div
        style={{
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ mt: 5, pb: 3 }}>
          <NftBookmarks darkMode={darkMode} queryName={query.get('type')} />
          <AuctionBookmark darkMode={darkMode} queryName={query.get('type')} />
        </Box>
      </div>
    </Box>
  );
};

export default Favourites;
