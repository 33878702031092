import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

import { EthereumProvider } from "@walletconnect/ethereum-provider";

import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { bscTestnet , bsc, polygon, polygonMumbai, sepolia } from "wagmi/chains";
import { getNetwork, getProvider , getAccount, goerli } from '@wagmi/core'
import funcDebug from "./contract/debug";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr', 'pt', 'ge'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
  });


bscTestnet.name = 'BSC Testnet';
bsc.name = 'BSC';
const projectId = "d88b266106af7abe736598d1d19ff0b8";

const chains = process.env.NODE_ENV === 'development' ? 
  [bsc]
  //[bscTestnet, bsc, polygon]
:
  [bsc];
  //[bscTestnet, bsc, polygon];

console.log( JSON.stringify(bscTestnet) )

// Wagmi client
const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider
})
/*
const { provider } = configureChains(chains, [
  await EthereumProvider.init({
     projectId: projectId ,
     rpcMap: { 
      56: 'https://bsc-dataseed.binance.org/',
      97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    }
    }),
]);
const wagmiClient = createClient({
  autoConnect: true,  
  connectors: modalConnectors({
    projectId: projectId,
    version: "2", // "1" or "2"
    appName: "ZCore Web3Games",
    chains,
  }),
  provider,
});
*/

/*
  const { chain } = getNetwork()
  try {    
    funcDebug('chain: ' + JSON.stringify(chain))
    const oldChain = localStorage.getItem('getNetwork');
    funcDebug('chain oldChain: ' + oldChain)
    localStorage.setItem('getNetwork', chain.id)
    if(Number(chain.id) !== Number(oldChain)){
      window.location.reload();
    }
  } catch (e) { 
    console.error('chain: ' + e);   
  }
*/

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);  
const currentAccount = getAccount()



//funcDebug(ethereumClient)
//funcDebug(currentAccount);

const chainImages = {
  56: "https://assets-cdn.trustwallet.com/blockchains/smartchain/info/logo.png",
  97: "/images/bsc_testnet.png",
}

const tokenImages = {
  BNB: "https://w7.pngwing.com/pngs/1007/775/png-transparent-bnb-cryptocurrencies-icon.png",
}

  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(
    <> 
      <WagmiConfig client={wagmiClient}>
          <App />
      </WagmiConfig>
      <Web3Modal
        tokenImages={tokenImages}
        themeMode="dark"
        projectId="d88b266106af7abe736598d1d19ff0b8"
        ethereumClient={ethereumClient}        
        enableNetworkView={true}
      />
    </> 
  );

reportWebVitals();
