// From Material UI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    Divider,
    Fade,
    IconButton,
    Modal,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    Skeleton,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import styles from "../../components/AuctionCardDetails/SingleAuctionCard.module.css";
  import TimeAgo from 'react-timeago'
  import moment from "moment";
import { Text } from "@nextui-org/react";
import { IoDice } from "react-icons/io5";




const LastSpins = ({
    isMobile,
    darkMode,
    mySpins,
}) => {

    //console.log('mySpins: in ' + JSON.stringify(mySpins))

return (<>

{/* Tabs and panels*/}
        {!isMobile ? (
            <Box>
              <Box sx={{ width: "100%", my: 16 , marginTop: '20px', marginBottom: '20px'}}>
                <Box
                  bgcolor={`${darkMode ? "#171c26" : "#fff2f8"}`}
                  borderRadius="10px"
                >
                          <Text
                            h1
                            size={20}
                            css={{
                                marginLeft: "20px",
                                textGradient: "45deg, $yellow600 -20%, $red600 100%",
                            }}
                            weight="bold"
                            >
                                My last spins on roulette
                            </Text>             
                </Box>

                  {/* Global tab styles */}
                  <div className="tabStylesFull">
                    {mySpins.slice(0,10).map((bd) => (
                      <Box mb={2} key={bd.data}>
                        <Typography
                          variant="body1"
                          className={styles.tabBoxContent}
                        >
                          <IoDice/>
                          <Typography variant="body2" component="span">
                            {`Bet number `}<span style={{ fontWeight: "bold" }}>{`[${bd.bet}]`}</span>
                            {` and draw number `}<span style={{ fontWeight: "bold" }}>{`[${bd.spin}]`}</span>
                            {` at `}<TimeAgo date={moment(bd.data * 1000)} />
                          </Typography>
                        </Typography>
                      </Box>
                    ))}
                  </div>

              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ width: "100%", my: 16 , marginTop: '20px', marginBottom: '20px'}}>
                <Box
                  bgcolor={`${darkMode ? "#171c26" : "#fff2f8"}`}
                  borderRadius="10px"
                >
                          <Text
                            h1
                            size={20}
                            css={{
                                marginLeft: "20px",
                                textGradient: "45deg, $yellow600 -20%, $red600 100%",
                            }}
                            weight="bold"
                            >
                                My last spins on roulette
                            </Text>             
                </Box>
                
                  <div className="tabStylesFull">
                  {mySpins.slice(0,5).map((bd) => (
                      <Box pr={2} my={3} ml={3} key={bd.data}>
                        <Typography                          
                          variant="body2"
                        >
                        <IoDice/>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", lineHeight: 2, marginLeft: "5px" }}
                            component="span"
                          >
                            {`Bet number `}<span style={{ fontWeight: "bold" }}>{`[${bd.bet}]`}</span>
                            {` and roulette`}<br />{`draws number `}<span style={{ fontWeight: "bold" }}>{`[${bd.spin}]`}</span>
                            {` at `}<TimeAgo date={moment(bd.data * 1000)} />
                          </Typography>
                        </Typography>
                      </Box>
                    ))}
                  </div>

              </Box>
            </Box>
          )}
</>)

        };

        
  
        export default LastSpins;          