import React, { useEffect, useState } from 'react';
import {
  Typography,
  Avatar,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Stack,
} from '@mui/material';
import CollectionCardFB from '../../components/Skeletons/CollectionCardFB';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SellerDetailsCard from '../../components/SellerDetailsCard/SellerDetailsCard';
import ArtCardFB from '../../components/Skeletons/ArtCardFB';

import Backdrop from '../../assets/exploreBackDropCircle.svg';
// import ProfileCover from '../../assets/dummyCoverImage.png';
import ProfileCover from '../../assets/userProfileCover.png';
import BackgroundWrinkles1 from '../../assets/BackgroundWrinkles1.svg';
import BackgroundWrinklesLight from '../../assets/backgroundWrinklesLight.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import CollectionCard from '../Collections/CollectionCard/CollectionCard';
// Tabpanel

import { useNavigate } from 'react-router-dom';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreatorsDetails = ({ darkMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [creatorData, setCreatorData] = useState([]);

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('/creatorData.json');
      const sellerCardData = res.data;
      setCreatorData(sellerCardData);
    };
    fetchData();
  }, []);

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // This will be deleted when the API endpoint added
  const filteredCreator = creatorData.filter((sd) => sd.id === id);
  console.log(filteredCreator);

  const MobileTabs = styled(Tabs)({
    border: 'none',
    backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
    '& .MuiTabs-indicator': {
      backgroundColor: 'inherit',
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? '#ffffff' : '#040404'}`,
    backgroundColor: `${darkMode ? '#171c26' : '#fff2f8'}`,
    borderRadius: '4px',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#01D4FA',
      borderRadius: '4px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      {' '}
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt='Wrinkles'
        />
      </Box>
      {!isMobile ? (
        <Box color={darkMode ? '#ffffff' : '#121212'}>
          <Box>
            {filteredCreator.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${
                    darkMode ? '#040404' : '#e3dcdc'
                  } 100%), url(${ProfileCover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  p: 5,
                  borderRadius: '10px',
                }}
              >
                <Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Avatar
                      src={fs.sellerImage}
                      alt={fs.sellerName}
                      sx={{ border: '2px solid #fff', width: 120, height: 120 }}
                    />
                  </Box>
                  <Stack
                    mt={2}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      gap={1}
                    >
                      <Typography variant='h5' color='secondary'>
                        {fs.sellerName}
                      </Typography>
                      <Typography variant='h5' color='secondary'>
                        {`(${fs.sellerUserName})`}
                      </Typography>
                    </Stack>
                    <Typography
                      mt={1}
                      variant='body2'
                      textAlign='center'
                      lineHeight={2}
                    >
                      {' '}
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              bgcolor={darkMode ? '#171C26' : '#fff2f8'}
              sx={{
                borderRadius: '10px',
                mt: 3,
                mb: 4,
              }}
            >
              <Tabs
                textColor='secondary'
                indicatorColor='secondary'
                value={tabValue}
                onChange={handleChange}
                centered
              >
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                  }}
                  label={`${t('COLLECTIONS')} (${filteredCreator.map(
                    (fs) => fs.sellerItems?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                  }}
                  label={`${t('CREATED')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'createdItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                  }}
                  label={`${t('OWNED')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'ownedItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                  }}
                  label={`${t('IN_AUCTION')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'auctionItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                  }}
                  label={`${t('IN_SALE')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'inSale')?.length
                  )})`}
                />
              </Tabs>
            </Box>
            <Box sx={{ mx: -3 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 6 }}
                  columns={{ xs: 1, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems.map((sdc) => (
                          <CollectionCard
                            darkMode={darkMode}
                            key={sdc.id}
                            id={sdc.id}
                            sellerCollection={sdc}
                          />
                        ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'createdItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'ownedItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'auctionItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'inSale')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ position: 'relative', overflowX: 'hidden', mt: -10 }}
          color={darkMode ? '#ffffff' : '#121212'}
        >
          <Box
            bgcolor={darkMode ? '#121212' : '#fff2f8'}
            sx={{
              borderRadius: '10px',
            }}
          >
            {filteredCreator.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${
                    darkMode ? '#040404' : '#e3dcdc'
                  } 100%), url(${ProfileCover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  p: 2,
                  borderRadius: '10px',
                }}
              >
                <Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Avatar
                      src={fs.sellerImage}
                      alt={fs.sellerName}
                      sx={{ border: '2px solid #fff', width: 150, height: 150 }}
                    />
                  </Box>
                  <Stack
                    mt={2}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      gap={1}
                    >
                      <Typography variant='h5' color='secondary'>
                        {fs.sellerName}
                      </Typography>
                      <Typography variant='h5' color='secondary'>
                        {`(${fs.sellerUserName})`}
                      </Typography>
                    </Stack>
                    <Typography mt={1} textAlign='justify' variant='caption'>
                      {' '}
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: '10px',
                mt: 3,
                mb: 4,
              }}
            >
              <MobileTabs
                variant='scrollable'
                textColor='secondary'
                indicatorColor='secondary'
                value={tabValue}
                onChange={handleChange}
                centered
              >
                <MobileTab
                  sx={{
                    textTransform: 'capitalize',
                    display: 'block',
                    mr: 2,
                  }}
                  label={`${t('COLLECTIONS')} (${filteredCreator.map(
                    (fs) => fs.sellerItems?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: 'capitalize',
                    display: 'block',
                    mr: 2,
                  }}
                  label={`${t('CREATED')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'createdItems')
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: 'capitalize',
                    display: 'block',
                    mr: 2,
                  }}
                  label={`${t('OWNED')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'ownedItems')
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: 'capitalize',
                    display: 'block',
                    mr: 2,
                  }}
                  label={`${t('IN_AUCTION')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'auctionItems')
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: 'capitalize',
                    display: 'block',
                  }}
                  label={`${t('IN_SALE')} (${filteredCreator.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'inSale')?.length
                  )})`}
                />
              </MobileTabs>
            </Box>
            <Box sx={{ mx: -3 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 6 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems.map((sdc) => (
                          <CollectionCard
                            darkMode={darkMode}
                            key={sdc.id}
                            id={sdc.id}
                            sellerCollection={sdc}
                          />
                        ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'createdItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'ownedItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'auctionItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredCreator.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredCreator.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'inSale')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreatorsDetails;
