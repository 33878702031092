import React, { useEffect, useState, useCallback } from "react";
// Material UI components
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  Zoom,
} from "@mui/material";

// Gradient buttons
import {
  GradientButtonPrimary,
  GradientButtonSecondary,
} from "../../Utils/GradientButtons/GradientButtons";

import ConnectWalletPopUp from "../ConnectWalletPopUp/ConnectWalletPopUp";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

// Icons
import { BsFillDice6Fill, BsTwitch } from "react-icons/bs";
import { HiCubeTransparent } from "react-icons/hi";
import { MdGavel } from "react-icons/md";
import { MdAddToPhotos } from "react-icons/md";
import { FaWallet, FaTicketAlt } from "react-icons/fa";
import { BsChevronDown, BsFillPersonFill } from "react-icons/bs";
import { IoPersonCircle, IoPersonSharp, IoTicket } from "react-icons/io5";
import { TiCancel } from "react-icons/ti";
import { FiPackage } from "react-icons/fi";
import { BiPalette , BiCoin , BiCoinStack, BiDice1, BiDice6 } from "react-icons/bi";
import NavUserAvatar from "../../assets/navUserAvatar.png";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAccount } from 'wagmi'
import funcDebug from "../../contract/debug";
import { fetchBalance, getProvider , getAccount, getNetwork } from '@wagmi/core'

// Authentication
import useAuth from "../../hooks/useAuth";

import MetaMaskIcon from "../../assets/Icons/darkUIIcons/metaMaskIcon.svg";
import MobileNavigation from "./MobileNavigation";

// Styled Menu
import { StyledMenu } from "../StyledMenu/StyledMenu";
import { ArtTrack, Person } from "@mui/icons-material";

import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";

// Main logo
import MainLogo from "../../assets/mainLogo.png";
import MainLogoLight from "../../assets/mainLogoLight.png";
import { ToastContainer, toast } from 'react-toastify';
import { ImDice } from "react-icons/im";


const Navigation = ({ darkMode }) => {

  const { address, isConnecting, isDisconnected } = useAccount()
  const { user, handleLogOut } = useAuth();
  const [walletAddress, setWalletAddress] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [mybalance, setMybalance] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [isNftDB, setIsNftDB] = useState(localStorage.getItem('isNftDB') === null || localStorage.getItem('isNftDB') === undefined ? true : localStorage.getItem('isNftDB'));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const openMenu = Boolean(anchorEl);
  const openAvatarMenu = Boolean(avatarAnchorEl);

  const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenAvatarMenu = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleCloseAvatarMenu = () => {
    setAvatarAnchorEl(null);
  };



  const currentAccount = getAccount();
  useEffect(() => {
    async function mybalance(address){
      const balance = await fetchBalance({
        address: address,
      })
      if(balance){
        setMybalance(balance?.formatted)
        console.log('navigation: mybalance ' + balance?.formatted );
      }
    }
    if(currentAccount){
      mybalance(currentAccount.address)      
    }
  }, [currentAccount]);

  
  useEffect(() => {
    if(localStorage.getItem('isNftDB') === "false"){
      setIsNftDB('false');
    }else{
      setIsNftDB('true');
    }
    
  }, []);

  funcDebug('isNftDB: ' + isNftDB)
  funcDebug('isNftDB: ' + localStorage.getItem('isNftDB'))
  

  const goTokens = () => {
    localStorage.setItem('isNftDB', 'false');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('false');
    //funcDebug('isNftDB: ' + localStorage.getItem('isNftDB'))
    navigate("/raffle?type=live&nft=false")
  }

  const goClub = () => {
    navigate("/zcoreclub")
  }  

  const goNft = () => {
    localStorage.setItem('isNftDB', 'true');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('true');
    //funcDebug('isNftDB: ' + localStorage.getItem('isNftDB'))
    navigate("/raffle?type=live&nft=true")
  }

  const ConnectWallletDesk = useCallback(() => {
  //const ConnectWallletDesk = () => {
    if(!isMobile){
    return (<>
            <Web3NetworkSwitch />            
            <Web3Button
              balance="show"
            />
    </>)
    }else{
      return null;
    }
  //}
}, [mybalance]);

  const ConnectWalllet = () => {
  if(isMobile){
    return (<>
    <Box 
    style={{
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: "flex", 
    gap: "10px"
    }}>        
    {/*    
    <GradientButtonPrimary
      style={{ 
        height: '40px'              
       }}
      onClick={goTocreate}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography 
      style={{ zIndex: '5555555555' }}
      color="#ffffff" component="span" mt={0.8}>
        <MdAddToPhotos />
      </Typography>
    </GradientButtonPrimary>
    */}
    <Web3NetworkSwitch />
    <Web3Button/>    
  </Box>
  </>
  );
    }else{
      return null;
    }
  }


  const goTocreate = () => {
    if(address === undefined){
      toast.error(`Connect your wallet!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });   
    }else{
      navigate("/create-asset");
    }
    
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleConnectWallet = async () => {
    handleCloseModal();
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
    }
  };

  const handleDisconnectWallet = () => {
    if (walletAddress) {
      window.location.reload();
    }
  };

  const notifySoon = () => toast.info('Coming Soon!', {
      theme: "dark",
  });

  
  return (
    <div
      style={{
        position: "relative",
        paddingTop: `${!isMobile ? "9rem" : "0"}`,
      }}
    >
      {!isMobile ? (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            zIndex: `${location.pathname === "/create-asset" ? 30000 : 300000}`,
            position: "fixed",
            top: "0%",
            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
            pt: 3,
            pb: 2,
          }}
        >


<Box>
              {darkMode ? (
                <img
                  style={{ width: "58px", height: "58px" }}
                  src={MainLogo}
                  alt="MainLogo"
                />
              ) : (
                <img
                  style={{ width: "58px", height: "58px" }}
                  src={MainLogoLight}
                  alt="MainLogoLight"
                />
              )}
</Box>

<Box>
            <Stack direction="row" spacing={5}>




              <Button
              onClick={goNft}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <BiPalette />
                </Typography>
                <Typography
                  sx={
                    isNftDB === "true" && location.pathname !== '/zcoreclub'
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#040708"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("NFTs")}
                </Typography>
              </Button>



              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={goTokens}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <BiCoinStack />
                </Typography>
                <Typography
                  sx={
                    isNftDB === "false" && location.pathname !== '/zcoreclub'
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#040708"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("Tokens")}
                </Typography>
              </Button>


              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                  borderBottom: location.pathname === '/zcoreclub' ? `2px solid #ffffff` : `none`
                }}
                onClick={goClub}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <ImDice />
                </Typography>
                <Typography

                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("ZCoreClub")}
                </Typography>
              </Button>

            </Stack>
          </Box>              

{/*                    

              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/profile/user-profile")}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <IoPersonCircle />
                </Typography>
                <Typography
                  sx={
                    location.pathname === "/profile/user-profile"
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#040708"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("SETTINGS_USER_PROFILE")}
                </Typography>
              </Button>
            </Stack>
          </Box>


    
          <Box>
            <Stack direction="row" spacing={5}>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/home")}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={1}>
                  <BsTwitch />
                </Typography>
                <Typography
                  sx={
                    location.pathname === "/home"
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#171c26"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("NAV_HOME")}
                </Typography>
              </Button>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/explore?type=all")}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <HiCubeTransparent />
                </Typography>
                <Typography
                  sx={
                    location.pathname === "/explore"
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#171c26"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("NAV_EXPLORE")}
                </Typography>
              </Button>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/raffle?type=live")}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <MdGavel />
                </Typography>
                <Typography
                  sx={
                    location.pathname === "/raffle"
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#040708"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("NAV_AUCTION")}
                </Typography>
              </Button>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/collections")}
                variant="text"
                color="secondary"
              >
                <Typography color="secondary" component="span" mt={0.5}>
                  <FiPackage />
                </Typography>
                <Typography
                  sx={
                    location.pathname === "/collections"
                      ? {
                          borderBottom: `2px solid ${
                            darkMode ? "#ffffff" : "#040708"
                          }`,
                        }
                      : null
                  }
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                >
                  {t("NAV_COLLECTION")}
                </Typography>
              </Button>
            </Stack>
          </Box>

*/}


          <Box style={{ display: "flex", gap: "5px" }}>
            {/*
            <GradientButtonPrimary
              style={{ 
                height: '40px'              
               }}
              onClick={goTocreate}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography color="#ffffff" component="span" mt={0.8}>
                <MdAddToPhotos />
              </Typography>
              <Typography variant="subtitle1" component="span"
                style={{ 
                  fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
                  fontStyle: 'normal',
                }}
              >
                {t("NAV_CREATE_ASSET")}
              </Typography>
            </GradientButtonPrimary>
            */}

<ConnectWallletDesk/>




{/*
            {user?.email ? null : (
              <GradientButtonSecondary
                onClick={() => navigate("/authentication/sign-in")}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography color="#ffffff" component="span" mt={0.8}>
                  <FaWallet />
                </Typography>
                <Typography variant="subtitle1" component="span">
                  {t("SIGNUP_LOGIN")}
                </Typography>
              </GradientButtonSecondary>
            )}
*/}
            {user?.email ? (
              <React.Fragment>
                {walletAddress ? (
                  <GradientButtonSecondary
                    id="basic-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClickTrigger}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={MetaMaskIcon}
                      alt="Meta Mask Icon"
                    />
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {walletAddress.slice(0, 10)} <BsChevronDown />
                    </Typography>
                  </GradientButtonSecondary>
                ) : (
                  <GradientButtonSecondary
                    onClick={handleOpenModal}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography color="#ffffff" component="span" mt={0.8}>
                      <FaWallet />
                    </Typography>
                    <Typography variant="subtitle1" component="span">
                      {t("NAV_CONNECT_WALLET")}
                    </Typography>
                  </GradientButtonSecondary>
                )}
              </React.Fragment>
            ) : null}
            {user?.email ? (
              <React.Fragment>
                <Avatar
                  onClick={handleOpenAvatarMenu}
                  alt={user?.userName}
                  src={NavUserAvatar}
                  sx={{ mt: 0.7, cursor: "pointer" }}
                />

                <StyledMenu
                  elevation={1}
                  TransitionComponent={Zoom}
                  anchorEl={avatarAnchorEl}
                  open={openAvatarMenu}
                  onClose={handleCloseAvatarMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    zIndex: `${
                      location.pathname === "/create-asset" ? 400000 : 400000
                    }`,
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/user/dummy");
                      handleCloseAvatarMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Person />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("MY_PROFILE")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <Box my={0.5} px={2}>
                    <Divider />
                  </Box>
                  <MenuItem
                    onClick={() => {
                      navigate("/mybids");
                      handleCloseAvatarMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <GavelIcon />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("ALL_BIDS")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <Box my={0.5} px={2}>
                    <Divider />
                  </Box>
                  <MenuItem
                    onClick={() => {
                      navigate("/mybids");
                      handleCloseAvatarMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <GavelIcon />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("ALL_BIDS")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <Box my={0.5} px={2}>
                    <Divider />
                  </Box>
                  <MenuItem
                    onClick={() => {
                      navigate("/profile/user-profile");
                      handleCloseAvatarMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <SettingsIcon />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("SETTINGS")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <Box my={0.5} px={2}>
                    <Divider />
                  </Box>
                  <MenuItem onClick={handleLogOut}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <LogoutIcon />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("LOGOUT")}
                      </Typography>
                    </Stack>
                    <Divider />
                  </MenuItem>
                </StyledMenu>
              </React.Fragment>
            ) : null}
          </Box>
        </Container>
      ) : ( 
        null
       )
      }


{/* REMOVIDO DO NULL ACIMA
        <MobileNavigation
          darkMode={darkMode}
          walletAddress={walletAddress}
          openMenu={openMenu}
          handleClickTrigger={handleClickTrigger}
          handleOpenModal={handleOpenModal}
        />        
*/}



<ConnectWalllet/>


      <ConnectWalletPopUp
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        darkMode={darkMode}
        handleConnectWallet={handleConnectWallet}
        handleDisconnectWallet={handleDisconnectWallet}
        walletAddress={walletAddress}
      />
      {!isMobile ? (
        <Menu
          id="basic-menu"
          PaperProps={{
            style: {
              backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
              padding: "0 10px",
              borderRadius: "10px",
            },
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ marginTop: "12px" }}
        >
          <MenuItem
            style={{
              width: "200px",
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              paddingLeft: "2rem",
              gap: 15,
              borderRadius: "10px",
              marginBottom: "10px",
            }}
            onClick={handleDisconnectWallet}
          >
            <Typography component="span" color="secondary">
              <TiCancel />
            </Typography>
            <Typography color="secondary">{t("DISCONNECT")}</Typography>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
              borderRadius: "10px",
              padding: "0 10px",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ marginTop: "-2rem" }}
        >
          <MenuItem
            style={{
              width: "130px",
              gap: 10,
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            {darkMode ? (
              <img
                style={{ height: "20px", width: "20px" }}
                src={MetaMaskIcon}
                alt="Metamask"
              />
            ) : (
              <img
                style={{ height: "20px", width: "20px" }}
                src={MetaMaskIcon}
                alt="Metamask"
              />
            )}
            <Typography
              variant="body1"
              color="secondary"
              sx={{ fontSize: "12px" }}
            >
              {walletAddress.slice(0, 10)}
            </Typography>
          </MenuItem>
          <MenuItem
            style={{
              width: "130px",
              gap: 10,
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
              marginBottom: "10px",
            }}
            onClick={() => {
              navigate("/user/dummy");
              handleCloseMenu();
            }}
          >
            <Typography component="span" color="secondary">
              <IoPersonSharp />
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              sx={{ fontSize: "12px" }}
            >
              {t("MY_PROFILE")}
            </Typography>
          </MenuItem>
          <MenuItem
            style={{
              width: "130px",
              gap: 10,
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
            }}
            onClick={handleDisconnectWallet}
          >
            <Typography component="span" color="secondary">
              <TiCancel />
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              sx={{ fontSize: "12px" }}
            >
              {t("DISCONNECT")}
            </Typography>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default Navigation;
