import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Input,
  InputAdornment,
  MenuItem,
  Grow,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import LiveAuctions from "../../components/LiveAuctions/LiveAuctions";
import EndingAuctions from "../../components/EndingAuctions/EndingAuctions";
import PastAuctions from "../../components/PastAuctions/PastAuctions";
//import SoonAuctions from "../../components/SoonAuctions/SoonAuctions";
import CreatedAuctions from "../../components/CreatedAuctions/CreatedAuctions";
import PurshasedAuctions from "../../components/PurshasedAuctions/PurshasedAuctions";
import useQuery from "../../hooks/useQuery";

import BackgroundWrinkles1 from "../../assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "../../assets/backgroundWrinklesLight.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import SearchIconExplore from "../../assets/Icons/darkUIIcons/searchIconExplore.svg";
import { Text } from "@nextui-org/react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledMenu } from "../../components/StyledMenu/StyledMenu";

import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import { BiSort } from "react-icons/bi";

import TuneIcon from "@mui/icons-material/Tune";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getProvider , getAccount } from '@wagmi/core'
import funcDebug from "../../contract/debug";
import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";
import { IoDice } from "react-icons/io5";

const Auction = ({ darkMode }) => {

  //const currentAccount = getAccount();
  //console.log(currentAccount);
  
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t("DATE_CREATED"));
  const [orderOptions, setOrderOptions] = useState(t("NEWEST"));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let query = useQuery();

  let qType = query.get("type");
  if(qType == null){
    qType = 'live';
  }

  funcDebug('query.get : ' + query.get("nft"))

  let qNft = 'Token Raffles';
  if(query.get("nft") == 'true'){
    qNft = 'NFT Raffles';
  }

  const location = useLocation();
  const navigate = useNavigate();

  const handleDetails = (id) => {
    navigate(`/raffle/${id}`);
  };
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);


  const goClub = () => {
    navigate(`/zcoreclub`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
      color={darkMode ? "#ffffff" : "#121212"}
    >
      <Box
        style={{
          position: "fixed",
          height: "100vh",
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt="Wrinkles"
        />
      </Box>
      {/*
      <Box sx={{ width: "100%", mt: 11.5, mb: 3 }}>
        {!isMobile ? (
          <Stack direction="row" alignItems="center" gap={3}>
            <Box width="35%">
              <Input
                disableUnderline
                fullWidth
                placeholder={t("SEARCH_IN_EXPLORE")}
                sx={{
                  background: `${darkMode ? "#171c26" : "#fff2f8"}`,
                  border: "none",
                  py: 1.6,
                  px: 2,
                  borderRadius: "5px",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={SearchIconExplore} alt="Search" />
                  </InputAdornment>
                }
              />
            </Box>
            <Box>
              <Button
                onClick={handleOpenSortMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
                variant="contained"
                color="accent"
                fullWidth
                endIcon={
                  <Box color="secondary" mt={1.5}>
                    {openSortMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: "capitalize" }}
                  variant="subtitle1"
                  color="secondary"
                >
                  {t("SORT_BY")}
                </Typography>
                <Typography sx={{ color: "gray" }} variant="subtitle1">
                  {sortOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={sortAnchorEl}
                open={openSortMenu}
                onClose={handleCloseSortMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("DATE_CREATED"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("DATE_CREATED")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("POPULARITY"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <BiSort style={{ height: 25, width: 25 }} />
                    <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                      {t("POPULARITY")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>


            <Box>
              <Button
                onClick={handleOpenOrderMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
                variant="contained"
                color="accent"
                fullWidth
                endIcon={
                  <Box color="secondary" mt={1.5}>
                    {openOrderMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: "capitalize" }}
                  variant="subtitle1"
                  color="secondary"
                >
                  {t("ORDER_BY")}
                </Typography>
                <Typography sx={{ color: "gray" }} variant="subtitle1">
                  {orderOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={orderAnchorEl}
                open={openOrderMenu}
                onClose={handleCloseOrderMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("NEWEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("NEWEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("OLDEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <UpdateIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("OLDEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
        ) : null}
      </Box>
*/}
      <Box 
      style={{ marginTop: `${
        isMobile ? "25px" : "50px"
      }` }}
      sx={{ width: "100%", mb: 3 }}
      >
        {!isMobile ? (
          <Box
            bgcolor={darkMode ? "#171c26" : "#fff2F8"}
            sx={{
              borderRadius: "10px",
              pl: 5,
              py: 1,
              display: "flex",
              alignItems: "center",
              textAlign: 'center',
              gap: 20,
            }}
          >
            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=live")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=live" || qType === "live"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("RECENT")}
              </Typography>
            </Button>




            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=ending")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=ending"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("ENDING")}
              </Typography>
            </Button>




            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=purchased")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=purchased"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("PURCHASED")}
              </Typography>
            </Button>



            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=created")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=created"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("CREATED")}
              </Typography>
            </Button>



            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=past")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=past"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("PAST_AUCTIONS")}
              </Typography>
            </Button>

{/*
            <Button
              color="secondary"
              onClick={() => navigate("/raffle?type=soon")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=soon"
                    ? {
                        borderBottom: `1px solid ${
                          darkMode ? "#ffffff" : "#171C26"
                        }`,
                      }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("SOON")}
              </Typography>
            </Button>
*/}


          </Box>
        ) : (
          <>
          <Box 
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: '-70px',
          }}
          >

              <Box
                bgcolor={
                  location.search === "?type=live"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=live")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 3,
                  mx: 1,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("RECENT")}
                </Typography>
              </Box>

              <Box
                bgcolor={
                  location.search === "?type=ending"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=ending")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 2,
                  mx: 1,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("ENDING")}
                </Typography>
              </Box>


              <Box
                bgcolor={
                  location.search === "?type=purchased"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=purchased")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 3,
                  mx: 1,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("PURCHASED")}
                </Typography>
              </Box>




</Box>

<Box 
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: '10px',
          }}
          >



              <Box
                bgcolor={
                  location.search === "?type=created"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=created")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 6,
                  mx: 1,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("CREATED")}
                </Typography>
              </Box>



              <Box
                bgcolor={
                  location.search === "?type=past"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=past")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 6,
                                    mx: 1,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("PAST_AUCTIONS")}
                </Typography>
              </Box>

{/*
              <Box
                bgcolor={
                  location.search === "?type=soon"
                    ? {
                        backgroundColor: `#004d44`,
                      }
                    : {
                        backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      }
                }
                onClick={() => navigate("/raffle?type=soon")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 3,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("SOON")}
                </Typography>
              </Box>
*/}


            </Box>
          </>          
        )}
        {/*
        <Box>
          {isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              sx={{ mt: "3rem" }}
            >
              <Box>
                <OutlinedInput
                  size="small"
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        height={15}
                        width={15}
                        src={SearchIconExplore}
                        alt="Search"
                      />
                    </InputAdornment>
                  }
                />
              </Box>

              <Box>
                <IconButton
                  onClick={handleOpenSortMenu}
                  variant="contained"
                  fullWidth
                >
                  <TuneIcon />
                </IconButton>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                    },
                  }}
                  anchorEl={sortAnchorEl}
                  open={openSortMenu}
                  onClose={handleCloseSortMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("DATE_CREATED"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("DATE_CREATED")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("POPULARITY"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <BiSort style={{ height: 25, width: 25 }} />
                      <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                        {t("POPULARITY")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>


              <Box>
                <IconButton
                  onClick={handleOpenOrderMenu}
                  variant="contained"
                  fullWidth
                >
                  <FilterListIcon />
                </IconButton>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                    },
                  }}
                  TransitionComponent={Grow}
                  anchorEl={orderAnchorEl}
                  open={openOrderMenu}
                  onClose={handleCloseOrderMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("NEWEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("NEWEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("OLDEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <UpdateIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("OLDEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack>
          )}
        </Box>
        */}

      <Box
      textAlign="center"
      sx={{ mt: "1rem" }}
      >
      <Text
        h1
        size={40}
        css={{
          textGradient: "45deg, $yellow600 -20%, $red600 100%",
        }}
        weight="bold"
      >
        {qNft}
      </Text>
      </Box>

      <Box
      textAlign="center"
      sx={{ mt: "1rem" }}
      >
      <Text color="success">
      <b>Raffle event starts on March 27th, 2023 but you can try your luck right now by clicking in ZCoreClub.</b>
      </Text>

<GradientButtonSecondary
onClick={goClub}
><IoDice
  style={{
    marginRight: '5px',
  }}
  />
ZCoreClub</GradientButtonSecondary>

      </Box>
      {/*

        <Box sx={{ mt: "1rem" }}>
          <LiveAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          />
          <EndingAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          />          
          <PastAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          />
          <CreatedAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          />
          <PurshasedAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          />          
        </Box>
      */}

          {/*
          <SoonAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={qType}
          /> 
          */}         


      </Box>
    </Box>
  );
};

export default Auction;
