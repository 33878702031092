const miniABI = [
    {
        "name": "approve",
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "name": "allowance",
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];


const nftMiniABI = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },    
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }],
        "name": "ownerOf",
        "outputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [{
            "internalType": "address",
            "name": "owner",
            "type": "address"
        }],
        "name": "balanceOf",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    },    
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }],
        "name": "getApproved",
        "outputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [{
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },    
];


let fee_zcore_nftAll = [];
let fee_zcoreAll = [];
let jsonRpcURLAll = [];
let jsonRpcURLPriceAll = [];

let MultiCallAddressAll = [];
let MultiCallAddressBSCAll = [];

let factoryAddressAll = [];
let wbnbAll = [];
let busdAll = [];

let explorerURLAll = [];
let coinNetworkAll = [];

let CreateLotteryAddressAll = [];
let FarmHorsesOwnersAdressAll = [];
let RouletteAll = [];

let tokensListAll = [];

let defaultNetwork = localStorage.getItem('getNetwork');


const polygon = 137;
const mumbai = 80001;
const sepolia = 11155111;
const bsc = 56;
const bscTestnet = 97;


if(
        defaultNetwork != bscTestnet && 
        defaultNetwork != mumbai && 
        defaultNetwork != polygon && 
        defaultNetwork != sepolia && 
        defaultNetwork != bsc
    ){
        defaultNetwork = bsc;
}

const thisNetwork = defaultNetwork;
console.log('chain thisNetwork: ' + thisNetwork)




// ===============================================================================
// == Polygon
// ===============================================================================



fee_zcore_nftAll[polygon] = 0;
fee_zcoreAll[polygon] = 5;
jsonRpcURLAll[polygon] = 'https://polygon-mainnet.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d';
MultiCallAddressAll[polygon] = '0xc4f1501f337079077842343Ce02665D8960150B0';

explorerURLAll[polygon] = 'polygonscan.com';
coinNetworkAll[polygon] = "MATIC";

// ZCORE
CreateLotteryAddressAll[polygon] = '0x';
FarmHorsesOwnersAdressAll[polygon] = '0x4555010cd11d1a920ce3b523b4e0b82f0f8dc881';
RouletteAll[polygon] = '0xE0FD9a1dc273e3e77166160fe1D1f0c4330e76E2';


// ALWAYS MAINNET
jsonRpcURLPriceAll[polygon] = 'https://polygon-mainnet.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d';
MultiCallAddressBSCAll[polygon] = '0xc4f1501f337079077842343Ce02665D8960150B0';
factoryAddressAll[polygon] = '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32'
wbnbAll[polygon] = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
busdAll[polygon] = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'  

tokensListAll[polygon] = [
    { 
        name: "USDC USDC",
        symbol: "USDC", 
        contract: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" , 
        decimais: 6 , 
        icon: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=024',
        contract_price: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" , 
        isnft: false,
        maxcollection: 0,
    },
    //{ symbol: "USDC", contract: "0x5fb166df567240B294Ad9235908e66C776a95a04" , decimais: 18 , icon: '' },
    //{ symbol: "BUSD", contract: "0xb3700AA699357D9c7ED1Ee6A84973b8c8BF6504f" , decimais: 18 , icon: '' },
    //{ symbol: "ETH", contract: "0xc1a1D95099cb1Bb806B034cfC3D23CF9d0E6E6d3" , decimais: 18 , icon: '' },    
];




// ===============================================================================
// == Mumbai TESTNET
// ===============================================================================



fee_zcore_nftAll[mumbai] = 0;
fee_zcoreAll[mumbai] = 5;
jsonRpcURLAll[mumbai] = 'https://polygon-mumbai.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d';
jsonRpcURLPriceAll[mumbai] = 'https://polygon-mainnet.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d';

MultiCallAddressAll[mumbai] = '0xE0FD9a1dc273e3e77166160fe1D1f0c4330e76E2';
MultiCallAddressBSCAll[mumbai] = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';

factoryAddressAll[mumbai] = '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32'
wbnbAll[mumbai] = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
busdAll[mumbai] = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'  

explorerURLAll[mumbai] = 'mumbai.polygonscan.com';
coinNetworkAll[mumbai] = "MATIC";

CreateLotteryAddressAll[mumbai] = '0xc76a19bfA221c9a197e307b1703943D68785f32A';
FarmHorsesOwnersAdressAll[mumbai] = '0xECfa5B457770A46640a12eB383c591839227E6E9';
RouletteAll[mumbai] = '0xB84C0713f0E71E7F9555A29BABca9e03e2714Fe3';

tokensListAll[mumbai] = [
    { 
        name: "USDC USDC",
        symbol: "USDC", 
        contract: "0x36160274B0ED3673E67F2CA5923560a7a0c523aa" , 
        decimais: 6 , 
        icon: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=024',
        contract_price: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" , 
        isnft: false,
        maxcollection: 0,
    },
    //{ symbol: "USDC", contract: "0x5fb166df567240B294Ad9235908e66C776a95a04" , decimais: 18 , icon: '' },
    //{ symbol: "BUSD", contract: "0xb3700AA699357D9c7ED1Ee6A84973b8c8BF6504f" , decimais: 18 , icon: '' },
    //{ symbol: "ETH", contract: "0xc1a1D95099cb1Bb806B034cfC3D23CF9d0E6E6d3" , decimais: 18 , icon: '' },    
];

// ===============================================================================
// == Sepolia TESTNET
// ===============================================================================


fee_zcore_nftAll[sepolia] = 0;
fee_zcoreAll[sepolia] = 5;
jsonRpcURLAll[sepolia] = 'https://sepolia.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d' 
jsonRpcURLPriceAll[sepolia] = 'https://mainnet.infura.io/v3/de9ceb7b7fea4c8db8afe991fdaed59d';

MultiCallAddressAll[sepolia] = '0xE0FD9a1dc273e3e77166160fe1D1f0c4330e76E2';
MultiCallAddressBSCAll[sepolia] = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';

factoryAddressAll[sepolia] = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
wbnbAll[sepolia] = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
busdAll[sepolia] = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'  

explorerURLAll[sepolia] = 'sepolia.etherscan.io';
coinNetworkAll[sepolia] = "SEP";

CreateLotteryAddressAll[sepolia] = '0xc76a19bfA221c9a197e307b1703943D68785f32A';
FarmHorsesOwnersAdressAll[sepolia] = '0xECfa5B457770A46640a12eB383c591839227E6E9';
RouletteAll[sepolia] = '0x60d437Ea80257E976fF5488149c12865fd566b16';

tokensListAll[sepolia] = [
    { 
        name: "Tether USD",
        symbol: "USDT", 
        contract: "0x36160274B0ED3673E67F2CA5923560a7a0c523aa" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=024',
        contract_price: "0xdAC17F958D2ee523a2206206994597C13D831ec7" , 
        isnft: false,
        maxcollection: 0,
    },
    //{ symbol: "USDC", contract: "0x5fb166df567240B294Ad9235908e66C776a95a04" , decimais: 18 , icon: '' },
    //{ symbol: "BUSD", contract: "0xb3700AA699357D9c7ED1Ee6A84973b8c8BF6504f" , decimais: 18 , icon: '' },
    //{ symbol: "ETH", contract: "0xc1a1D95099cb1Bb806B034cfC3D23CF9d0E6E6d3" , decimais: 18 , icon: '' },    
];

// ===============================================================================
// == BCS TESTNET
// ===============================================================================
fee_zcore_nftAll[bscTestnet] = 0;
fee_zcoreAll[bscTestnet] = 5;
jsonRpcURLAll[bscTestnet] = 'https://data-seed-prebsc-1-s1.binance.org:8545' 
jsonRpcURLPriceAll[bscTestnet] = 'https://bsc-dataseed.binance.org/';

MultiCallAddressAll[bscTestnet] = '0x358ae4b4D43f791274Bc1A8972e03652319DcC85';
MultiCallAddressBSCAll[bscTestnet] = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';

factoryAddressAll[bscTestnet] = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
wbnbAll[bscTestnet] = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
busdAll[bscTestnet] = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'  

explorerURLAll[bscTestnet] = 'bscscan.com';
coinNetworkAll[bscTestnet] = "tBNB";

CreateLotteryAddressAll[bscTestnet] = '0xc76a19bfA221c9a197e307b1703943D68785f32A';
FarmHorsesOwnersAdressAll[bscTestnet] = '0xECfa5B457770A46640a12eB383c591839227E6E9';
RouletteAll[bscTestnet] = '0x2DBd33c18a9086361ca5d7CF60305B5845Bf37E9';

tokensListAll[bscTestnet] = [
    { 
        name: "Binance-Peg BUSD Token",
        symbol: "BUSD", 
        contract: "0x716291dd4569D3311F445Ba3769Cd5b9c0D3cb8e" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/binance-usd-busd-logo.svg?v=024',
        contract_price: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" , 
        isnft: false,
        maxcollection: 0,
    },
    { 
        name: "Binance-Peg USD Coin",
        symbol: "USDC", 
        contract: "0x2E35B3824bf525193198BC4eC38E69d4Ef9436Ef" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=024',
        contract_price: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d" , 
        isnft: false,
        maxcollection: 0,
    },
    { 
        name: "Binance-Peg Ethereum Token",
        symbol: "ETH", 
        contract: "0x0B384B5cbc89CAb06d340dfC4117b9428e7F11E8" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=024',
        contract_price: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8" , 
        isnft: false,
        maxcollection: 0,
    },    
    { 
        name: "BoredApeYachtClub",
        symbol: "BAYC",
        contract: "0xaddBcF93546791804d9c09ec36bAc4D3087F29bE",
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=024',
        contract_price: "" , 
        isnft: true,
        maxcollection: 30,
    },        
    //{ symbol: "USDC", contract: "0x5fb166df567240B294Ad9235908e66C776a95a04" , decimais: 18 , icon: '' },
    //{ symbol: "BUSD", contract: "0xb3700AA699357D9c7ED1Ee6A84973b8c8BF6504f" , decimais: 18 , icon: '' },
    //{ symbol: "ETH", contract: "0xc1a1D95099cb1Bb806B034cfC3D23CF9d0E6E6d3" , decimais: 18 , icon: '' },    
];




// ===============================================================================
// == BCS MAINNET
// ===============================================================================

fee_zcore_nftAll[bsc] = 0;
fee_zcoreAll[bsc] = 5;
jsonRpcURLAll[bsc] = 'https://bsc-dataseed.binance.org/' 
jsonRpcURLPriceAll[bsc] = 'https://bsc-dataseed.binance.org/';

MultiCallAddressAll[bsc] = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';
MultiCallAddressBSCAll[bsc] = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';

factoryAddressAll[bsc] = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
wbnbAll[bsc] = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
busdAll[bsc] = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'  

explorerURLAll[bsc] = 'bscscan.com';
coinNetworkAll[bsc] = "BNB";

CreateLotteryAddressAll[bsc] = '0x829E2A1CB9027CC9698695b4e22CbFC1825652cB';
FarmHorsesOwnersAdressAll[bsc] = '0xdB7D85322Ba90E52022529D70eE496A0Ca61224f';
RouletteAll[bsc] = '0x882663fedC7670aE80f951F88AD77899aFf6189e';

tokensListAll[bsc] = [
    { 
        name: "Binance-Peg BUSD Token",
        symbol: "BUSD", 
        contract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/binance-usd-busd-logo.svg?v=024',
        contract_price: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" , 
        isnft: false,
        maxcollection: 0,
    },
    { 
        name: "Binance-Peg USD Coin",
        symbol: "USDC", 
        contract: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=024',
        contract_price: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d" , 
        isnft: false,
        maxcollection: 0,
    },
    { 
        name: "Binance-Peg Ethereum Token",
        symbol: "ETH", 
        contract: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8" , 
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=024',
        contract_price: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8" , 
        isnft: false,
        maxcollection: 0,
    },    
    { 
        name: "BoredApeYachtClub",
        symbol: "BAYC",
        contract: "0xaddBcF93546791804d9c09ec36bAc4D3087F29bE",
        decimais: 18 , 
        icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=024',
        contract_price: "" , 
        isnft: true,
        maxcollection: 30,
    },        
    //{ symbol: "USDC", contract: "0x5fb166df567240B294Ad9235908e66C776a95a04" , decimais: 18 , icon: '' },
    //{ symbol: "BUSD", contract: "0xb3700AA699357D9c7ED1Ee6A84973b8c8BF6504f" , decimais: 18 , icon: '' },
    //{ symbol: "ETH", contract: "0xc1a1D95099cb1Bb806B034cfC3D23CF9d0E6E6d3" , decimais: 18 , icon: '' },    
];


const fee_zcore_nft = fee_zcore_nftAll[thisNetwork];
const fee_zcore = fee_zcoreAll[thisNetwork];
const jsonRpcURL = jsonRpcURLAll[thisNetwork];
const jsonRpcURLPrice = jsonRpcURLPriceAll[thisNetwork];

const MultiCallAddress = MultiCallAddressAll[thisNetwork];
const MultiCallAddressBSC = MultiCallAddressBSCAll[thisNetwork];

const factoryAddress = factoryAddressAll[thisNetwork];
const wbnb = wbnbAll[thisNetwork];
const busd = busdAll[thisNetwork];

const explorerURL = explorerURLAll[thisNetwork];
const coinNetwork = coinNetworkAll[thisNetwork];

const CreateLotteryAddress = CreateLotteryAddressAll[thisNetwork];
const FarmHorsesOwnersAdress = FarmHorsesOwnersAdressAll[thisNetwork];
const RouletteAdress = RouletteAll[thisNetwork];

const tokensList = tokensListAll[thisNetwork];

export { thisNetwork, RouletteAdress, FarmHorsesOwnersAdress, factoryAddress, wbnb, busd, MultiCallAddressBSC, jsonRpcURLPrice, coinNetwork, explorerURL, nftMiniABI, fee_zcore_nft, MultiCallAddress , miniABI, CreateLotteryAddress , fee_zcore , tokensList, jsonRpcURL }