import { Badge, Text } from "@nextui-org/react";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

const BoxJackpot = ({
    totalPrize,
    coinNetwork,
    jackpotTotal,
    isMobile,
    windowHeight,
}) => {
    //const BoxJackpot = useCallback(event => {
    
      const textJackBnb = totalPrize > 0 ? `Jackpot: ${totalPrize} ${coinNetwork}` : `Earn BNB`;
    
      if(!isMobile){
    
        return (
          <div style={{
            padding: '0px',
            textAlign: 'center',
            marginBottom: (windowHeight <= 720 ? (`10px`) : (`30px`)),
          }}>        
          <Badge
            content={textJackBnb}
            disableOutline
            enableShadow 
            color="success" 
            placement="bottom-right"
            size={(windowHeight <= 720 ? (`xs`) : (`md`))}>
            <Text
              h1
              size={windowHeight <= 720 ? 50 : 80}
              css={{
              padding: '0px',
              margin: '0px',
              textGradient: "45deg, $blue600 -20%, $pink600 50%",    
            }}
            weight="bold"
            >
             {jackpotTotal > 0 ?
              `Win ${currencyFormat(jackpotTotal)}`
             :
              `Spin and win!`
             }
          </Text>
          </Badge>
          </div>
            )    
    
    
    
      }else{
    
        return (
          <div style={{
            padding: '0px',
            textAlign: 'center',
            marginBottom: '30px',
          }}>        
          <Badge
            content={`win: ${totalPrize} ${coinNetwork}`}
            disableOutline
            disableAnimation={true}
            enableShadow 
            color="success" 
            placement="bottom-right"
            size="md">
            <Text
              h1
              size={60}
              css={{
              padding: '0px',
              margin: '0px',
              textGradient: "45deg, $blue600 -20%, $pink600 50%",    
            }}
            weight="bold"
            >
             {jackpotTotal > 0 ?
              `${currencyFormat(jackpotTotal)}`
             :
              `Spin!`
             }
          </Text>
          </Badge>
          </div>
            )    
    
    }
    //}, [jackpotTotal, totalPrize, coinNetwork, windowHeight, isMobile]);
    }

export default BoxJackpot;