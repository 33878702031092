import React, { useEffect, useState } from 'react';

import { Container, Typography } from '@mui/material';

import axios from 'axios';

import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
// Single Artwork components
import SingleArtWork from './SingleArtWork';

// Styles
import styles from './ArtCardDetails.module.css';

import { dataRaffle } from "../../contract/GetRaffles";



const ArtCardDetails = ({ darkMode }) => {
  const { id } = useParams(); // Read from url

  const [artWorks, setArtWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage('Loading...');
    async function fetchData() {
      const allRaffles = await dataRaffle();
      //console.log('allRaffles: ' + JSON.stringify(allRaffles));
      setArtWorks(allRaffles);
      setIsLoading(false);    
    }
    fetchData();    
    /*
    axios.get('/artWorkData.json').then((res) => {
      setArtWorks(res.data);
      setIsLoading(false);
    });
    */
  }, [id]);

  // Filtering artwork by IDs
  const filteredArtWork = artWorks.filter((artWork) => artWork.id === id);

  return (
    <>
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />

      <Container className={styles.artCardDetailsContainer}>
        <Typography variant='h3' color='secondary.main'>
          {filteredArtWork.map((fa) => (
            <SingleArtWork darkMode={darkMode} key={fa.id} fa={fa} />
          ))}
        </Typography>
      </Container>
    </>
  );
};

export default ArtCardDetails;
