import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from "react";
import { BiPalette , BiCoin , BiCoinStack } from "react-icons/bi";
import { FaWallet } from 'react-icons/fa';
import { HiCubeTransparent } from 'react-icons/hi';
import { MdAddToPhotos, MdGavel } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiPackage } from 'react-icons/fi';

// Metamask Icon
import MetaMaskIcon from '../../assets/Icons/darkUIIcons/metaMaskIcon.svg';
import MetaMaskIconLight from '../../assets/Icons/lightUIIcons/metaMaskIcon.svg';
import { ToastContainer, toast } from 'react-toastify';
import { ImDice } from 'react-icons/im';

const MobileNavigation = ({
  darkMode,
  walletAddress,
  openMenu,
  handleClickTrigger,
  handleOpenModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const notifySoon = () => toast.info('Coming Soon!', {
    theme: "dark",
  });


  const [isNftDB, setIsNftDB] = useState('true');
  useEffect(() => {
    setIsNftDB(localStorage.getItem('isNftDB'));
  }, []);

  console.log('isNftDB: ' + isNftDB)
  console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
  

  const goTokens = () => {
    localStorage.setItem('isNftDB', 'false');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('false');
    //console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
    navigate("/raffle?type=live&nft=false")
  }

  const goNft = () => {
    localStorage.setItem('isNftDB', 'true');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('true');
    //console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
    navigate("/raffle?type=live&nft=true")
  }  

  const goClub = () => {
    navigate("/zcoreclub")
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 30000,
        bottom: 0,
        backgroundColor: `${darkMode ? '#040404' : '#fff2f8'}`,
        left: 0,
        right: 0,
      }}
    >
      <Box pt={1}>
        <Stack
          direction='row'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >


        <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={goNft}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <BiPalette />
              </Typography>
              <Typography
                component='p'
                sx={
                  isNftDB === "true" && location.pathname !== '/zcoreclub'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>

          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={goTokens}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <BiCoinStack />
              </Typography>
              <Typography
                component='p'
                sx={
                  isNftDB === "false" && location.pathname !== '/zcoreclub'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>


          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={goClub}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <ImDice />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/zcoreclub'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>

          {/*
          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/raffle?type=live')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <MdGavel />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/raffle'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>
          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/collections')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <FiPackage />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/collections'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>
          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/create-asset')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <MdAddToPhotos />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/create-asset'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button>
          </Box>
          */}
        </Stack>
      </Box>
    </Box>
  );
};

export default MobileNavigation;
