import React, { useRef } from "react";
import {
  Button,
  Grow,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
//Icons
import Slider from "react-slick";
import { BiSort } from "react-icons/bi";
import { MdHistory } from "react-icons/md";
import { MdTune } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { StyledMenu } from "../StyledMenu/StyledMenu";

// Tab filters
const filterTabs = [
  {
    id: 1,
    text: "All",
    path: "?type=all",
  },
  {
    id: 2,
    text: "FILTER_TAB_ART",
    path: "?type=art",
  },
  {
    id: 3,
    text: "FILTER_TAB_MUSIC",
    path: "?type=music",
  },
  {
    id: 4,
    text: "FILTER_TAB_POSTER",
    path: "?type=poster",
  },
  {
    id: 5,
    text: "FILTER_TAB_SIGNATURE",
    path: "?type=signature",
  },
  {
    id: 6,
    text: "FILTER_TAB_MEMES",
    path: "?type=memes",
  },
  // {
  //   id: 7,
  //   text: 'ccd',
  //   path: '?type=memes',
  // },
  // {
  //   id: 8,
  //   text: 'aa',
  //   path: '?type=memes',
  // },
  // {
  //   id: 9,
  //   text: 'cc',
  //   path: '?type=memes',
  // },
  // {
  //   id: 10,
  //   text: 'cc',
  //   path: '?type=memes',
  // },
  // {
  //   id: 11,
  //   text: 'cc1',
  //   path: '?type=memes',
  // },
  // {
  //   id: 12,
  //   text: 'fcc12',
  //   path: '?type=memes',
  // },
  // {
  //   id: 13,
  //   text: 'cc21313',
  //   path: '?type=emes',
  // },
  // {
  //   id: 14,
  //   text: 'cfc21312',
  //   path: '?type=mees',
  // },
  // {
  //   id: 15,
  //   text: 'cfc213',
  //   path: '?type=meme',
  // },
  // {
  //   id: 17,
  //   text: 'cfc123',
  //   path: '?type=me',
  // },
  // {
  //   id: 18,
  //   text: 'cfc23',
  //   path: '?type=mem',
  // },
  // {
  //   id: 19,
  //   text: 'cfc133',
  //   path: '?type=emes',
  // },
  // {
  //   id: 20,
  //   text: 'cfc112',
  //   path: '?type=mes',
  // },
  // {
  //   id: 21,
  //   text: 'cfc1',
  //   path: '?type=me=s',
  // },
  // {
  //   id: 22,
  //   text: 'cfc11',
  //   path: '?type=es',
  // },
];

const FilterTab = ({ darkMode }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const navigate = useNavigate();

  const openMenu = Boolean(anchorEl);

  const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          initialSlide: 2.2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const liveSlideRef = useRef();
  const nextButtonLive = () => {
    liveSlideRef.current.slickNext();
  };
  const previousButtonLive = () => {
    liveSlideRef.current.slickPrev();
  };

  const rand = 1 + Math.random() * (1000000 - 1);
  return (
    <>
      {!isMobile ? (
        <Box
          sx={{ mb: 8 }}
          style={{ overflow: "hidden", position: "relative" }}
        >
          <Box container alignContent={"center"} xs={12}>
            <Box
              key={rand}
              bgcolor={darkMode ? "#171c26" : "#FFF5F9"}
              sx={{
                textAlign: "center",
                py: 1,
                px: 4,
                borderRadius: "10px",

                justifyContent: "space-between",

                gap: 2,
              }}
            >
              <Slider ref={liveSlideRef} {...sliderSettings}>
                {filterTabs.map((ft) => (
                  <Button
                    color="secondary"
                    onClick={() => navigate(ft.path)}
                    style={{ textTransform: "capitalize" }}
                  >
                    <Typography
                      sx={
                        location.search === ft.path
                          ? {
                              borderBottom: `1px solid ${
                                darkMode ? "#ffffff" : "#171c26"
                              }`,
                            }
                          : null
                      }
                      variant="body2"
                      component="span"
                    >
                      {t(`${ft.text}`)}
                    </Typography>
                  </Button>
                ))}
              </Slider>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ my: 1, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              overflowX: "scroll",
              gap: 2,
              py: 2,
            }}
          >
            {filterTabs.map((ft) => (
              <Box key={ft.id}>
                <Box>
                  <Box
                    bgcolor={
                      location.search === ft.path
                        ? {
                            backgroundColor: `#01D4FA`,
                          }
                        : {
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#fff2f8"
                            }`,
                          }
                    }
                    onClick={() => navigate(ft.path)}
                    sx={{
                      py: 1,
                      px: 3,
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{ fontSize: "12px" }}
                    >
                      {t(`${ft.text}`)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FilterTab;
