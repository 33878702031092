import React, { useState } from "react";
import {
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import SaveIcon from "@mui/icons-material/Save";
import { GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";
import { useTranslation } from "react-i18next";
const ChangeEmailInterface = ({ darkMode }) => {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <>
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {t("CHANGE_EMAIL")}
          </Typography>
        </Box>
      )}
      {!isMobile ? (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
            }}
          >
            {isEmailSubmitted ? (
              <Box sx={{ px: 5, pt: 1.7 }}>
                {/* Verify OTP */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="sellerName"
                  >
                    {t("VERIFY_OTP")}
                  </label>
                  <Typography variant="caption" color="GrayText">
                    {t("AN_OTP_HAS_BEEN_SENT_TO_YOUR_EMAIL")}
                  </Typography>
                  <TextField
                    className={
                      darkMode ? "inputFieldAuth" : "inputFieldAuthLight"
                    }
                    placeholder={t("ENTER_OTP")}
                    name="verifyOTP"
                    type="number"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                  />
                </Stack>
                {/* Submit */}
                <Stack
                  mt={4}
                  width={"90%"}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <GradientButtonPrimary
                    onClick={() => setIsEmailSubmitted(false)}
                    sx={{ py: 1.5, px: 5 }}
                    startIcon={<SaveIcon />}
                  >
                    {t("SUBMIT")}
                  </GradientButtonPrimary>
                </Stack>
              </Box>
            ) : (
              <Box sx={{ px: 5, pt: 1.7 }} component="form">
                {/* Change Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="sellerName"
                  >
                    {t("ENTER_NEW_EMAIL")}
                  </label>
                  <TextField
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? "inputFieldAuth" : "inputFieldAuthLight"
                        }`,
                      },
                    }}
                    placeholder={t("ENTER_YOUR_CURRENT_PASSWORD")}
                    name="email"
                    type="email"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                  />
                </Stack>
                {/* Submit */}
                <Stack
                  mt={4}
                  width={"90%"}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <GradientButtonPrimary
                    onClick={() => setIsEmailSubmitted(true)}
                    sx={{ py: 1.5, px: 5 }}
                    startIcon={<SaveIcon />}
                  >
                    {t("SUBMIT")}
                  </GradientButtonPrimary>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            backgroundColor: `${darkMode ? "#121212" : "#fff2f8"}`,
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              p: 1,
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
            }}
          >
            {isEmailSubmitted ? (
              <Box>
                {/* Verify OTP */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="sellerName"
                  >
                    {t("VERIFY_OTP")}
                  </label>
                  <Typography variant="caption" color="GrayText">
                    {t("AN_OTP_HAS_SENT")}
                  </Typography>
                  <OutlinedInput
                    className={darkMode ? "inputField" : undefined}
                    placeholder={"Enter the OTP"}
                    name="verifyOTP"
                    type="number"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                  />
                </Stack>
                {/* Submit */}
                <Stack mt={4} direction="row" justifyContent="flex-end">
                  <GradientButtonPrimary
                    onClick={() => setIsEmailSubmitted(false)}
                    sx={{ py: 1.5, px: 5 }}
                    startIcon={<SaveIcon />}
                  >
                    {t("SUBMIT")}
                  </GradientButtonPrimary>
                </Stack>
              </Box>
            ) : (
              <Box component="form">
                {/* Change Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="sellerName"
                  >
                    {t("ENTER_NEW_EMAIL")}
                  </label>
                  <TextField
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? "inputFieldAuth" : "inputFieldAuthLight"
                        }`,
                      },
                    }}
                    placeholder={t("ENTER_NEW_EMAIL")}
                    name="email"
                    type="email"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                  />
                </Stack>
                {/* Submit */}
                <Stack mt={4} direction="row" justifyContent="flex-end">
                  <GradientButtonPrimary
                    onClick={() => setIsEmailSubmitted(true)}
                    sx={{ py: 1.5, px: 5 }}
                    startIcon={<SaveIcon />}
                  >
                    {t("SUBMIT")}
                  </GradientButtonPrimary>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChangeEmailInterface;
