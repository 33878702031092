import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grow,
  Input,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import { BiSort } from "react-icons/bi";
import ArtCardContainer from "../../components/ArtCardContainer/ArtCardContainer";
import FilterTab from "../../components/FilterTab/FilterTab";
import SearchIconExplore from "../../assets/Icons/darkUIIcons/searchIconExplore.svg";
import { StyledMenu } from "../../components/StyledMenu/StyledMenu";
import { useTranslation } from "react-i18next";
const Explore = ({ darkMode }) => {
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t("DATE_CREATED"));
  const [orderOptions, setOrderOptions] = useState(t("NEWEST"));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState("DATE");
  const [orderBy, setOrderBy] = useState(0);
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  return (
    <div>
      <Box
        color={darkMode ? "#ffffff" : "#121212"}
        mt={!isMobile ? 11.5 : 2}
        mb={3}
      >
        {!isMobile ? (
          <Stack direction="row" alignItems="center" gap={3}>
            {/* Search Bar */}
            <Box width="35%">
              <Input
                disableUnderline
                fullWidth
                placeholder={t("SEARCH_IN_EXPLORE")}
                sx={{
                  background: `${darkMode ? "#171c26" : "#fff2f8"}`,
                  border: "none",
                  py: 1.6,
                  px: 2,
                  borderRadius: "5px",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={SearchIconExplore} alt="Search" />
                  </InputAdornment>
                }
              />
            </Box>
            {/* Sort By */}
            <Box>
              <Button
                onClick={handleOpenSortMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
                variant="contained"
                color="accent"
                fullWidth
                endIcon={
                  <Box color="secondary" mt={1.5}>
                    {openSortMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: "capitalize" }}
                  variant="subtitle1"
                  color="secondary"
                >
                  {t("SORT_BY")}
                </Typography>
                <Typography sx={{ color: "gray" }} variant="subtitle1">
                  {sortOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={sortAnchorEl}
                open={openSortMenu}
                onClose={handleCloseSortMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("DATE_CREATED"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("DATE_CREATED")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("POPULARITY"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <BiSort style={{ height: 25, width: 25 }} />
                    <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                      {t("POPULARITY")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
            {/* Order */}
            <Box>
              <Button
                onClick={handleOpenOrderMenu}
                sx={{
                  py: 0.7,
                  width: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
                variant="contained"
                color="accent"
                fullWidth
                endIcon={
                  <Box color="secondary" mt={1.5}>
                    {openOrderMenu ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </Box>
                }
              >
                <Typography
                  sx={{ textTransform: "capitalize" }}
                  variant="subtitle1"
                  color="secondary"
                >
                  {t("ORDER_BY")}
                </Typography>
                <Typography sx={{ color: "gray" }} variant="subtitle1">
                  {orderOptions}
                </Typography>
              </Button>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    width: 330,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={orderAnchorEl}
                open={openOrderMenu}
                onClose={handleCloseOrderMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("NEWEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("NEWEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("OLDEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <UpdateIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("OLDEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" gap={3}>
            <Box>
              <OutlinedInput
                size="small"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      height={15}
                      width={15}
                      src={SearchIconExplore}
                      alt="Search"
                    />
                  </InputAdornment>
                }
              />
            </Box>
            {/* Sort */}
            <Box>
              <IconButton
                onClick={handleOpenSortMenu}
                variant="contained"
                fullWidth
              >
                <TuneIcon />
              </IconButton>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                  },
                }}
                anchorEl={sortAnchorEl}
                open={openSortMenu}
                onClose={handleCloseSortMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("DATE_CREATED"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("DATE_CREATED")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSorOptions(t("POPULARITY"));
                    handleCloseSortMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <BiSort style={{ height: 25, width: 25 }} />
                    <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                      {t("POPULARITY")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
            {/* Order */}
            <Box>
              <IconButton
                onClick={handleOpenOrderMenu}
                variant="contained"
                fullWidth
              >
                <FilterListIcon />
              </IconButton>
              <StyledMenu
                elevation={1}
                PaperProps={{
                  style: {
                    borderRadius: "5px",
                    backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                  },
                }}
                TransitionComponent={Grow}
                anchorEl={orderAnchorEl}
                open={openOrderMenu}
                onClose={handleCloseOrderMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("NEWEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <HistoryIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("NEWEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOrderOptions(t("OLDEST"));
                    handleCloseOrderMenu();
                  }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <UpdateIcon sx={{ height: 25, width: 25 }} />
                    <Typography variant="subtitle1" fontWeight={400}>
                      {t("OLDEST")}
                    </Typography>
                  </Stack>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
        )}
      </Box>
      <FilterTab darkMode={darkMode} />
      <ArtCardContainer darkMode={darkMode} />
    </div>
  );
};

export default Explore;
