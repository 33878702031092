import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PolygonIcon from "../../assets/Icons/darkUIIcons/polygon.svg";
import BSCIcon from "../../assets/Icons/darkUIIcons/bscicon.png";
import IPFSIcon from "../../assets/Icons/darkUIIcons/ipfs.svg";
import DextoolsIcon from "../../assets/dextools.png";
import truncateEthAddress from 'truncate-eth-address'
import { useNavigate } from 'react-router'
import TimeAgo from 'react-timeago'
import { Badge, Card, Grid, Text, Link, Button as ButtonNext } from "@nextui-org/react";
import MetaMaskIcon from '../../assets/metamask_icon.svg';
import getNfts  from "../../contract/getnfts";
import ConfettiExplosion from 'react-confetti-explosion';

//import MetaTags from 'react-meta-tags';

// From Material UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { add } from "date-fns";

// Custom Gradient button
import { GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";
import { IoAlert, IoAlertCircle, IoAlertCircleSharp, IoCart, IoConstruct, IoDice, IoHourglass, IoHourglassOutline, IoNotificationsCircle, IoSend, IoSendOutline } from "react-icons/io5";
// Icon
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock, HiTemplate } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";
import { FaDrawPolygon, FaThumbsUp } from "react-icons/fa";

import CountDownBoard from "../CountDownBoard/CountDownBoard";

import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";

import { getProvider , getAccount } from '@wagmi/core'
import { usePrepareContractWrite, useContractWrite, useBalance } from 'wagmi'

// Styles
import styles from "./SingleAuctionCard.module.css";
import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";
import LinkIcon from "@mui/icons-material/Link";
// bidding data information
import { biddingData } from "./biddingData";

// Tabpanel
import { TabPanel } from "./TabPanel";

import moment from "moment";
import { ethers } from "ethers";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
//import ZLotteryABI from "../../contract/abi/ZLottery";
import CreateLotteryABI from "../../contract/abi/CreateLotteryABI";
import { coinNetwork, explorerURL, tokensList , miniABI, CreateLotteryAddress } from "../../contract/contracts";
import BigNumber from "bignumber.js";
import { useProvider , useSigner } from 'wagmi'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SingleAuctionCard = ({ fa, darkMode, setParentCounter }) => {
  const {
    artworkImage: imagempadrao,
    artworkTitle,
    artworkDetails,
    artworkPrice,
    creator,
    creatorImage,
    owner,
    ownerImage,
    uploaded,
    likes,
    currentBid,
    highestBid,
    auctionStatus,
    basePrice,
    boughtAt,
    manager,
    players,
    price,
    quorum,
    time,
    token,
    jackpot,
    finished,    
    winner,
    myentries,
    allplayers,
    topplayers,
    id: raffleid,
    picked,
    maxperuser,
    isnft,
    nftid,
    requestId,
    zcoreclub,
  } = fa;
  
  //console.log(fa);

  const agora = Math.floor(Date.now() / 1000);
  const zcontract = CreateLotteryAddress;
  const currentAccount = getAccount();
  const chanceWin = players > 0 ? (myentries * 100) / players : 0;
  const chanceWinTotal = quorum > 0 ? (myentries * 100) / quorum : 0;

  console.log('requestId: ' + requestId);

  const tokenData = tokensList.filter(
    (tk) => tk.contract === token
  );

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
  };  

  const navigate = useNavigate();
  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const [tallowance, setTallowance] = React.useState(0);
  const provider = useProvider()
  const { data: signer } = useSigner()
  const [btnApprove, setBtnApprove] = React.useState(false);
  const [chanceWinNew, setChanceWinNew] = React.useState(chanceWin);
  const [chanceWinTotalNew, setChanceWinTotalNew] = React.useState(chanceWinTotal);
  const [balToken, setBalToken] = useState(0);
  const [nftThumb, setNftThumb] = React.useState(imagempadrao);
  const [nftMetadata, setNftMetadata] = React.useState(null);
  const [isExploding, setIsExploding] = React.useState(false);


  const currentAddress = currentAccount ? currentAccount.address : null;

  const descriptionMeta = "Raffle : "+ artworkTitle+ " - Max Entries : " + maxperuser + " - Ticket Price: " + basePrice.toFixed(4);
  const UrlMeta = "https://raffle.zcore.network/raffle/"+ raffleid;

  const GetMetaTag = () => {
    return null;
    /*
  return (
    <MetaTags>
      <meta name="title" content={artworkTitle} />
      <meta name="description" content={descriptionMeta}/>
      <meta name="image" content={nftThumb}/>

      <meta itemprop="name" content={artworkTitle}/>
      <meta itemprop="description" content={descriptionMeta}/>
      <meta itemprop="image" content={nftThumb}/>

      <meta property="og:title" content={artworkTitle}/>
      <meta property="og:description" content={descriptionMeta}/>
      <meta property="og:image" content={nftThumb}/>
      <meta property="og:url" content={UrlMeta}/>
      <meta property="og:site_name" content={artworkTitle}/>
      <meta property="og:locale" content="en_US"/>
      <meta property="og:type" content="website"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:creator" content="@ZCoreCrypto"/>
      <meta property="twitter:site" content="@ZCoreCrypto"/>
      <meta property="twitter:domain" content="zcore.network"/>
      <meta property="twitter:url" content={UrlMeta}/>
      <meta name="twitter:title" content={artworkTitle}/>

      <meta name="twitter:description" content={descriptionMeta}/>
      <meta name="twitter:image" content={nftThumb}/>
    </MetaTags>
  )
  */
  }


  useEffect(() => {
    async function viewNfts(){

      let metaImageCache = localStorage.getItem(nftid+'_'+token);
      if(metaImageCache === null){
        const thisownerOf = await getNfts(nftid, token, currentAccount.address)
        setNftMetadata(thisownerOf.metadata);
        const imagenft = thisownerOf.metadata.image;
        setNftThumb(imagenft);
      }else{
        setNftThumb(metaImageCache);
        //const thisownerOf = await getNfts(nftid, token, currentAccount.address)
        //setNftMetadata(thisownerOf.metadata);
      }


    }
    if (isnft){
      viewNfts();
    }    
  }, [isnft, nftid, token]);





  const { data: dataBal, isError: isErrorBal, isLoading: isLoadingBal } = useBalance({
    address: currentAddress,
    token: tokenData[0]?.contract,
    enabled: false,
    onError(error) {
      console.log('Error', error)
    },    
  })

  useEffect(() => {
    setBalToken(dataBal?.formatted)
  }, [dataBal?.formatted]);
  
/*
  console.log('========================================')
  console.log('currentAddress: ' + currentAddress);
  console.log('chanceWin: ' + chanceWin)
  console.log('chanceWinNew: ' + chanceWinNew)
  console.log('chanceWinTotal: ' + chanceWinTotal)
  console.log('chanceWinTotalNew: ' + chanceWinTotalNew)
  console.log('========================================')
*/

    async function checkallowance() {

    //console.log(signer);
    if(signer !== undefined && signer !== null){

      const apcontract = new ethers.Contract(
      tokenData[0].contract,
      miniABI,
      signer
    );
     
      const checkAllowance = await apcontract.allowance(manager , zcontract)
      const formattedAllowance = checkAllowance.toString();
      console.log('formattedAllowance: ' + formattedAllowance);
      setTallowance(formattedAllowance);
    }
  }

  async function approveallowance() {
    
    const apcontract = new ethers.Contract(
      tokenData[0].contract,
      miniABI,
      signer
    );

    const approveTx = await apcontract.approve(zcontract, ethers.constants.MaxUint256, {});
    setBtnApprove(true);
    try {
        //notify('Please wait...');

        toast.info(`Wait, approving contract ${tokenData[0].symbol}`, {
          position: "top-right",
          autoClose: 30000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });                    

        await approveTx.wait();
        console.log(`Transaction mined succesfully: ${approveTx.hash}`)
        toast.dismiss();
        setParentCounter(approveTx.hash);
        checkallowance();
        setBtnApprove(false);
    }
    catch (error) {
        console.log(`Transaction failed with error: ${error}`)
        setBtnApprove(false);
    }    

  }  


//const startDate = moment(Date.now());
const startDate = Math.floor(Date.now() / 1000)*1000;
const timeEnd = moment(finished*1000);
const diff = timeEnd.diff(startDate);
const diffDuration = moment.duration(diff);

  // This should come from the backend API
  /*
  const futureDate = add(new Date(), {
    days: diffDuration.days(),
    hours: diffDuration.hours(),
    minutes: diffDuration.minutes(),
  });
  */  
  const futureDate = new Date(timeEnd);

  /*
console.log('========================================')
console.log('startDate: ' + startDate);
console.log('new Date: ' + new Date());
console.log('timeEnd: ' + timeEnd);
console.log('timeEnd: ' + new Date(timeEnd));
console.log("Total Duration in millis:", diffDuration.asMilliseconds());
console.log("Days:", diffDuration.days());
console.log("Hours:", diffDuration.hours());
console.log("Minutes:", diffDuration.minutes());
console.log("Seconds:", diffDuration.seconds());
console.log('futureDate: ' + futureDate);
console.log('========================================')
*/

  const [qtd, setQtd] = React.useState(1);
  const [qtdPrice, setQtdPrice] = React.useState(basePrice.toFixed(4));
  const [qtdPriceBlock, setQtdPriceBlock] = React.useState(price.toString());
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [runContract, setRunContract] = React.useState(false);
  const [disableBoxEntrie, setDisableBoxEntrie] = React.useState(false);
  const [sendtoken, setSendToken] = React.useState(0);
  const [valueQtd, setValueQtd] = React.useState(0);
  const [enableJack, setEnableJack] = React.useState(false);


  console.log(finished + ' --> ' + (Math.floor(Date.now() / 1000)));
  useEffect(() => {
    //if(Number(finished) > 0 && picked == false){
      if(Number(finished) > (Math.floor(Date.now() / 1000))){
        if(Number(players) < Number(quorum)){
          setRunContract(true)
        }      
      }
    //}
  }, []);

  const qtdPriceBig = qtdPrice > 0 ? ethers.utils.parseEther(qtdPrice.toString()) : '0';


  console.log('========================================')
  console.log('qtd: ' + qtd)
  console.log('qtdPriceBig: ' + qtdPriceBig)
  console.log('price: ' + price)
  console.log('========================================')

  const { config, error } = usePrepareContractWrite({
    address: zcontract,
    abi: CreateLotteryABI,
    functionName: 'enter',
    args: [raffleid , qtd], 
    overrides: {
      from: currentAccount.address,
      value: qtdPriceBig,
    },
    enabled: runContract,
    onSuccess(data) {
      //refreshPage();
      console.log('Success', data)
    },
    onError(error) {
      console.log('Error', error)
    },    
  })
  const { data, isLoading, isSuccess, write } = useContractWrite(config);

  {isLoading &&
    console.log('isLoading');
  }

  if(isSuccess && typeof(data) !== 'undefined' && data != null){
    msgBuyTicket(data)
  }


  async function msgBuyTicket(data){
    console.log('msgBuyTicket');
    console.log('isSuccess: ' + JSON.stringify(data));
    toast.info(`Wait acquiring ticket`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    
    await timeout(10000);
    toast.dismiss();      
    //setParentCounter(data.hash);
    //navigate("/raffle?type=purchased");
    window.location.reload();
  }

  console.log('disableBoxEntrie: ' + disableBoxEntrie);


  console.log('========================')
  console.log(zcontract)
  console.log(qtd)
  console.log(qtdPriceBlock)
  //console.log(error)
  //console.log(write)
  console.log(btnDisabled)
  console.log('========================')



async function msgSendToken(data){
  toast.info(`Wait, sending ${tokenData[0].symbol}`, {
    position: "top-right",
    autoClose: 30000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });    
  await timeout(10000);
  toast.dismiss();
  console.log('isSuccess: ' + JSON.stringify(data));
  navigate("/raffle?type=created"); 
}


const sendtokenBig = sendtoken > 0 ? ethers.utils.parseUnits(sendtoken.toString(), tokenData[0].decimais) : '0';

if(sendtoken > 0){
  const ndecimais = new BigNumber(10).pow(tokenData[0].decimais);
  console.log('sendtoken: ' + sendtoken);
  console.log('decimais: ' + tokenData[0].decimais);
  console.log('ndecimais: ' + ndecimais);
  console.log(ethers.utils.parseUnits(sendtoken, tokenData[0].decimais));
}


const { config: configToken , error: errorToken } = usePrepareContractWrite({
  address: zcontract,
  abi: CreateLotteryABI,
  functionName: 'depositToken',
  args: [raffleid, sendtokenBig],
  enabled: (sendtoken > 0),
  onSuccess(data) {
    console.log('Success', data)
  },
  onError(error) {
    console.log('Error', error)
  },    
})
const { data: dataToken, isLoading: isLoadingToken, isSuccess: isSuccessToken, write: writeToken } = useContractWrite(configToken);

{isLoadingToken &&
  console.log('isLoadingToken');
}

if(isSuccessToken && typeof(dataToken) !== 'undefined' && dataToken != null){
    console.log('isSuccessToken: ' + JSON.stringify(dataToken));
    //setParentCounter(dataToken.hash);
    msgSendToken(dataToken)
}

const handleSendToken = (event) => {
  setSendToken(event.target.value);
};

const onclickPick = () => {
  writePick?.()
}



async function addMetamask(){

  //const tokenAddress = '0x0F5ab2b02CF5A94e3749E8D9103b89dD2228B4a6';
  //const tokenSymbol = 'BTCB';
  //const tokenDecimals = 18;
  //const tokenImage = 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=024';
  
  try {

    const wasAdded =  signer.provider.send('wallet_watchAsset', {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenData[0].contract, // The address that the token is at.
          symbol: tokenData[0].symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenData[0].decimais, // The number of decimals in the token
          image: tokenData[0].icon, // A string url of the token logo      
        },
       } 
    )

/*
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });
  */
    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }  
  
};





// JACKPOT
const { config: configJack , error: errorJack } = usePrepareContractWrite({
  address: zcontract,
  abi: CreateLotteryABI,
  functionName: 'transferNFTAndFunds',
  args: [raffleid],
  enabled: enableJack,
  onSuccess(data) {
    console.log('Success', data)
  },
  onError(error) {
    console.log('Error', error)
  },    
})
const { data: dataJack, isLoading: isLoadingJack, isSuccess: isSuccessJack, write: writeJack } = useContractWrite(configJack);

{isLoadingJack &&
  console.log('isLoadingJack');
}

if(isSuccessJack && typeof(dataJack) !== 'undefined' && dataJack != null){
    console.log('isSuccessJack: ' + JSON.stringify(dataJack));
    window.location.reload();
}




const runPick = (agora > finished && currentAddress == manager && picked == false && requestId == '0') ? true : false;
console.log('runPick: ' + runPick);
console.log('runPick: ' + requestId);
// PICKWINNER
const { config: configPick, error: errorPick } = usePrepareContractWrite({
  address: zcontract,
  abi: CreateLotteryABI,
  functionName: 'pickWinner',
  args: [raffleid],
  overrides:{
    gasLimit:400000
  },
  enabled: runPick,
  onSuccess(data) {
    console.log('Success', data)
  },
  onError(error) {
    console.log('Error', error)
  },    
})
const { data: dataPick, isLoading: isLoadingPick, isSuccess: isSuccessPick, write: writePick } = useContractWrite(configPick);


{(isSuccessPick && typeof(dataPick) !== 'undefined' && dataPick != null) &&
  pickLoad();
}


async function pickLoad(){
  toast.dismiss();
  toast.info('Wait, raffling winner...', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  await timeout(5000);
  toast.dismiss();
  window.location.reload();  
}

  const handleQtd = (event) => {

    const agora = Math.floor(Date.now() / 1000);
    let valorrec = event.target.value;

    if(Number(players) >= Number(quorum)){
      valorrec = 0;
      setBtnDisabled(true);
      setDisableBoxEntrie(true)
    }
    
    if(Number(agora) > Number(finished)){
      valorrec = 0;
      setBtnDisabled(true);
    }

    if(valorrec){
      if(Number(valorrec) > Number(quorum)){
        valorrec = quorum;
      }
    }

    setValueQtd(valorrec);

    if(valorrec >= 1 && (Number(myentries) + Number(valorrec)) <= quorum){

      const tottrec = (Number(myentries) + Number(valorrec));

      //const nplayers = (Number(myentries) + Number(valorrec)) > quorum ? quorum : players;
      // const tottrec = (Number(myentries) + Number(valorrec)) > quorum ? ((Number(quorum) - Number(players)) + Number(myentries)) : (Number(myentries) + Number(valorrec));

      console.log('========================')
      console.log('agora: ' + agora);
      console.log('myentries: ' + myentries);
      console.log('valorrec: ' + valorrec);
      console.log('tottrec: ' + tottrec);
      console.log('players: ' + players);
      console.log('quorum: ' + quorum);
      console.log('========================')
      
      let tmp_chanceWin = players > 0 ? (tottrec * 100) / (Number(players) + Number(valorrec)) : 0;
      if(tmp_chanceWin > 100){
        tmp_chanceWin = 100;
      }
      const tmp_chanceWinTotal = (tottrec * 100) / quorum;

      setChanceWinNew(tmp_chanceWin);
      setChanceWinTotalNew(tmp_chanceWinTotal);
    }else{
      setChanceWinNew(chanceWin);
      setChanceWinTotalNew(chanceWinTotal);
    }


    if(valorrec){



      if(valorrec < 1){
        setQtd(1);
        setQtdPrice(basePrice);
        setBtnDisabled(true);

        const buyprice = (new BigNumber(basePrice * 1e18).toString());
        setQtdPriceBlock(buyprice);

      }else{

        const nqtdPrice = event.target.value * (basePrice);
        setQtd(event.target.value);
        setQtdPrice(nqtdPrice.toFixed(4));
        setBtnDisabled(false);

        const buyprice = (new BigNumber(nqtdPrice.toFixed(4) * 1e18).toString());
        setQtdPriceBlock(buyprice);

      }    
    }
};  


/*
  useEffect(() => {
    if(manager == currentAccount.address ){
      checkallowance();
    }
    //setParentCounter(2);
  }, [signer]);
*/


  const [openModal, setOpenModal] = React.useState(false);

  const [likeState, setLikeState] = useState(false);
  const [bookmarkState, setBookmarkState] = useState(false);

  const [showBidInput, setShowBidInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [onclickSendToken, setOnclickSendToken] = useState(false);
  const [onclickSendBnb, setOnclickSendBnb] = useState(false);
  const [onclickJack, setOnclickJack] = useState(false);

  const { t } = useTranslation();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const clickSendToken = () => {
    setOnclickSendToken(true);
    writeToken?.();
  }

  const clickSendBnb = () => {
    setOnclickSendBnb(true);
    write?.();
  }

  const clickJack = () => {
    setOnclickJack(true);
    writeJack?.();
  }

  const MobileTabs = styled(Tabs)({
    border: "none",
    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "inherit",
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#0e560b",
      borderRadius: "4px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(Number(players) >= Number(quorum)){
      setDisableBoxEntrie(true)
    }  
  }, [disableBoxEntrie]);

  console.log('==================================================');
  console.log('currentAddress: ' + currentAddress);
  console.log('winner: ' + winner);
  console.log('picked: ' + picked);
  console.log('enableJack: ' + enableJack);
  console.log('==================================================');

  useEffect(() => {
    if(winner == currentAddress && picked == false){
      setEnableJack(true)
    }  
  }, [currentAddress, winner, picked]);




  console.log('zcoreclub: '+zcoreclub);
  console.log('zcoreclub: '+winner);

  const CardZCoreClub = props => {
    if(zcoreclub && winner == "0x0000000000000000000000000000000000000000"){
    return (
    <div align="center">
    <a href="https://club.zcore.network" rel="noreferrer" target={"_blank"}>
    <Card isHoverable variant="shadow" 
      css={{ p: "$6", mw: "400px" }} 
      style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '30px' }}
    >
      <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
     
          <Text h2 color="success" align="center">
            This is an exclusive raffle for ZCoreClub members only - mint your NFT and become a member to join in!
          </Text>
      </Card.Body>
      <Card.Image
      src="/images/cardszcoreclub.png"
      objectFit="cover"
      width="100%"
      height={200}
      alt="Card image background"
    />                  
    </Card>
    </a>
    </div>        
    );
    }else{
      return null;
    }
  }  
  

  const CardNotStarted = props => {
    return (
      <div align="center">
    <Card isHoverable variant="shadow" 
      css={{ p: "$6", mw: "400px" }} 
      style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '40px' }}
    >
      <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
          <Text h2 color="primary" align="center">
            Raffle not Started
          </Text>
      </Card.Body>
    </Card>
    </div>        
    );
  }  

  const CardEnded = props => {
    return (
      <div align="center">
    <Card isHoverable variant="shadow" 
      css={{ p: "$6", mw: "400px" }} 
      style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '40px' }}
    >
      <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
          <Text h2 color="primary" align="center">
          The maximum number of tickets has been reached for this raffle, and it is now awaiting the draw date.
          </Text>
      </Card.Body>
      <Card.Image
        src="/images/dices.png"
        objectFit="cover"
        width="100%"
        height={200}
        alt="Card image background"
      />                  
    </Card>
    </div>        
    );
  }


  const CardOwnerNotBuy = props => {
    if(currentAccount.address == manager){
    return (
      <div align="center">
    <Card isHoverable variant="shadow" 
      css={{ p: "$6", mw: "400px" }} 
      style={{ alignItems: 'center' , backgroundColor: '#040404', fontSize: '14px', marginTop: '40px' }}
    >
      <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
          <Text h2 color="primary" align="center">
          The raffle creator is not eligible to purchase tickets.
          </Text>
      </Card.Body>
    </Card>
    </div>        
    );
    }else{
      return null;
    }
  }
  
  const ConstBoxQtd = props => {

    if(maxperuser-myentries == 0){
      return null;
    }else{
      let options = [];
      options.push(<option key={(3000)} value={0}>Choose the number of tickets</option>);
      for (var iqt=0; iqt < (maxperuser-myentries); iqt++) {
        options.push(<option key={(3000+iqt)} value={iqt+1}>{iqt+1}</option>);
      }
  
      return ( 
      <select 
      autoComplete="off"
      onWheel={(e) => e.target.blur()}                                                  
      onChange={handleQtd}
      className={darkMode ? "inputField" : null}
      style={{
        fontSize: "14px",
        border: "1px solid #c4c4c4",
        borderRadius: "6px",
        padding: "1rem 1.5rem",
        color: `${darkMode ? "#ffffff" : "#121212"}`,
        backgroundColor: `${
          darkMode ? "#171c26" : "#ffffff"
        }`,
        width: "100%",
        zIndex: 1000,        
      }}
    >    
       {options}
    </select>    
    );
  
    }
  }


  const CardWinner = props => { 
    if(winner !== "0x0000000000000000000000000000000000000000"){
    if(myentries > 0){

      if(currentAddress == winner){
        setIsExploding(true);
      }

    return (
      <Card isHoverable variant="shadow" 
      css={{ p: "$6", mw: "400px" }} 
      style={{ backgroundColor: '#040404', fontSize: '14px', marginTop: '30px' }}
      >
        <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
          {currentAddress == winner ? (
            <>
              {picked == false ? (
            <Text h2 color="success" align="center">
              You are the winner!<br />Click to claim your jackpot.
            </Text>
              ):(
                <Text h2 color="success" align="center">
                You are the winner!<br />Jackpot has claimed.
              </Text>
              )}
            <ButtonNext 
                        disabled={!writeJack || errorJack || onclickJack || !enableJack}
                        onClick={clickJack}            
            flat color="success" auto>
              <b>CLAIM {artworkTitle}</b>
            </ButtonNext>    
            </>
          ):(
            <Text h2 color="error" align="center">
              You are not the winner.<br />Try again in another raffle!
            </Text>
          )}
        </Card.Body>
      </Card>
    );
    }else{
      return null;
      {/*
      return (
        <Card isHoverable variant="shadow" 
        css={{ p: "$6", mw: "400px" }} 
        style={{ backgroundColor: '#040404', fontSize: '14px', marginTop: '30px' }}
        >
          <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
              <Text h2 color="warning" align="center">
              You have not participated in this raffle. We suggest trying your luck in one that is still ongoing.
              </Text>
          </Card.Body>
        </Card>        
      );
      */}
    }
    }else{
      if(picked == true && players == 0){
        return (
          <>
        <Card isHoverable variant="shadow" 
          css={{ p: "$6", mw: "400px" }} 
          style={{ backgroundColor: '#040404', fontSize: '14px', marginTop: '30px' }}
          >
            <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
                <Text h2 color="success" align="center">
                There was no winner for this raffle because there were no participants.
                </Text>
            </Card.Body>
          </Card>  
          </>             
        );
      }else{
        return (
          <>
        <Card variant="shadow" 
          css={{ p: "$6", mw: "400px" }} 
          style={{ backgroundColor: '#040404', fontSize: '14px', marginTop: '30px' }}
          >
            <Card.Body css={{ py: "$2" }} style={{ padding: '0px' }}>
                <Text h2 color="warning" align="center">
                 The raffle time has run out, so now you just need to wait for the winner to be drawn.
                </Text>
            </Card.Body>
            <Card.Image
      src="/images/dices.png"
      objectFit="cover"
      width="100%"
      height={200}
      alt="Card image background"
    />            
          </Card>  
          </>             
        );
      }
    }
  }

  return (
    // Artwork details information
    <>      
      <Modal
        sx={{ zIndex: 500000 }}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            bgcolor={darkMode ? "#171c26" : "#fff2f8"}
            className={
              !isMobile ? styles.modalStyleWeb : styles.modalStyleMobile
            }
          >
            <Box className={styles.modalBox}>
              <Typography component="span" color="secondary" mt={0.5}>
                <HiTemplate fontSize={"1.5rem"} />
              </Typography>
              <Typography color="secondary" variant="h6" mt={-0.2}>
                {t("ITEM_DETAILS")}
              </Typography>
            </Box>
            <Typography
              // Global classes
              className={
                !isMobile ? "readMoreModalText" : "readMoreModalTextMobile"
              }
              variant="body2"
              color="secondary"
              lineHeight={2}
              height={"250px"}
              pr={2.5}
              sx={{
                overflowY: "auto",
              }}
            >
              {artworkDetails}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <GetMetaTag/>
      <Box mt={11} className={styles.detailsContainerBox}>
        <Box>
          {/* Top navigation */}
          {!isMobile ? (
            <Box className={styles.topNavigationBox}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} />
              </Box>
              <Typography
                className={styles.topNavigationTypo}
                component="div"
                borderBottom={`2px solid ${darkMode ? "#ffffff" : "#121212"}`}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ cursor: "pointer" }}
                  zIndex={2}
                  color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                >
                {t("ITEM_DETAILS")}
                </Typography>
                {/*
                {darkMode && (
                  <Typography
                    variant="h1"
                    component="p"
                    ml={-3}
                    className={styles.labelHighLighter}
                  ></Typography>
                )}
                */}
              </Typography>
            </Box>
          ) : (
            <Box             
            className={styles.topNavigationBoxMobile}>
              <Box
                width={"70px"}
                height={"60px"}
                mt={5}
                bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
              >
                <FiChevronLeft
                  onClick={() => window.history.back()}
                  fontSize={"1.5rem"}
                />
              </Box>
              
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  ml: 4,
                }}
              >
                <Typography
                  component="div"
                  borderBottom={`2px solid ${darkMode ? "#ffffff" : "#121212"}`}
                  position="relative"
                  display="flex"
                  alignItems="center"
                  ml={4}
                >
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ zIndex: 2 }}
                  >
                    
                  </Typography>
                </Typography>
              </Box>
              
            </Box>
          )}



          {!isMobile ? (







            <Box className={styles.detailsContainer}>
              <Box zIndex={10}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >

              {auctionStatus === "live" || auctionStatus === "past" ? 
                  <img
                  className={styles.artDisplayImage}
                  src={nftThumb}
                  alt={artworkTitle}
                  onLoad={() => setLoading(false)}
                  loading="eager"
                />
                : 
                <img
                style={{ 
                  filter: 'grayscale(100%)',
                  opacity: '1'
                 }}                             
                className={styles.artDisplayImage}
                src={nftThumb}
                alt={artworkTitle}
                onLoad={() => setLoading(false)}
                loading="eager"
                />
              }
                </Box>
                <Box
                  className={styles.artDisplayDetails}
                  bgcolor={`${darkMode ? "#171C26" : "#FFF2F8"}`}
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box gap={1}>

                    {auctionStatus === "live" && (
                    <Box>            
                       <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="white"
                        >
                         Ends in :                          
                        </Typography>          
                      <CountDownBoard
                        darkMode={darkMode}
                        futureDate={futureDate}
                        isCard={true}
                      />
                      
                    </Box>
                  )}

                    <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {isnft ? (
                            `${t("Collection")}`
                          ):(
                            `${t("Raffle Token")}`
                          )}
                          
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                          {isnft ? (
                            `${tokenData[0].name}`
                          ):(
                            `${tokenData[0].symbol}`
                          )}
                            
                          </Typography>
                          <Typography
                            component="span"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            ml={1}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                          </Typography>
                        </div>
                      </Box>

                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Contract")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                          >
                            {truncateEthAddress(token)}
                          </Typography>
                          {/*
                          <Typography
                            component="span"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            ml={1}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <ContentCopyIcon />
                          </Typography>
                          */}
                        </div>
                      </Box>
                      {/*
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TOKEN_ID")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {"21"}
                        </Typography>
                      </Box>
                      */}
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Type")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {isnft ? (
                            `ERC721`
                          ):(
                            `BEP20`
                          )}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("BLOCKCHAIN")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          BSC
                        </Typography>
                      </Box>
                      {/*
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("ROYALTY_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {5 + "%"}
                        </Typography>
                      </Box>
                      
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TRANSACTION_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          fontWeight={500}
                        >
                          5%
                        </Typography>
                      </Box>
                    */}
                    </Box>
                  </Box>
                </Box>
                {!isnft &&
                <Box
                  className={styles.artDisplayDetails}
                  sx={{
                    mt: 2,
                  }}
                  >
                  <Box className={styles.artDisplayDetailsContent}>
                    <Button
                      onClick={()=> window.open(`https://www.dextools.io/app/en/bnb/pair-explorer/${token}`, "_blank")}
                      variant="contained"
                      className={styles.artDisplayDetailsButton}
                      sx={{
                        borderRadius: 5,
                        p: 0,
                        mr: 1,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={DextoolsIcon}
                          alt="DexTools Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        {t("Poocoin")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        align="right"
                      >
                        
                      </Typography>
                    </Button>

                    <Button
                      onClick={()=> window.open(`https://${explorerURL}/token/${token}`, "_blank")}                    
                      variant="contained"
                      className={styles.artDisplayDetailsButton}
                      sx={{
                        borderRadius: 5,
                        p: 0,
                        mr: 1,
                        ml: 1,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={BSCIcon}
                          alt="BSC"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                      {t("BSC")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        
                      </Typography>
                    </Button>

                    <Button
                      onClick={addMetamask}                    
                      variant="contained"
                      className={styles.artDisplayDetailsButton}
                      sx={{
                        borderRadius: 5,
                        ml: 1,
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={MetaMaskIcon}
                          alt="BSC"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                      {t("Add")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        
                      </Typography>
                    </Button>

                  </Box>
                </Box>
                  }


                {currentAddress == manager &&
                <Box style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                }}>
                  <GradientButtonSecondary
                          style={{ 
                            marginTop: '30px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',                      
                         }}
                          onClick={onclickPick}
                          disabled={!writePick || errorPick || !runPick || isSuccessPick}
                          variant="contained"
                          >
                          <IoDice style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Pick Winner
                          </Typography>
                          </GradientButtonSecondary>                    
                  </Box>
                }
                </Box>                








              <Box
                className={styles.detailsContainerContentBox}
                bgcolor={`${darkMode ? "#171C26" : "#FFF2F8"}`}
                sx={{
                  position: "relative",
                }}
                >
   
                
                <Box>
                {isExploding && 
                  <ConfettiExplosion
                    particleCount={250}
                    width={1600}
                    duration={15000}
                    force={0.8}
                    sx={{
                      zIndex: 999999999
                    }}
                  />}
        

        <Text
        h1
        size={30}
        css={{
          margin: '0px !important',
          padding: '10px !important',
          textGradient: "45deg, $blue600 -20%, $pink600 50%",
        }}
        weight="bold"
      >
        {artworkTitle}
      </Text>
                    



                  <Grid.Container>
                    <Grid style={{
                        marginRight: '5px'
                      }} >
                        {zcoreclub ?
                          <Badge enableShadow disableOutline color="success">Only ZCoreClub</Badge>
                        :
                          <Badge enableShadow disableOutline color="error">Any User</Badge>
                        }
                    </Grid>
                    {/*
                    <Grid style={{
                        marginRight: '5px'
                      }} >
                      <Badge enableShadow disableOutline color="primary">Pool current: 0.1 BNB</Badge>
                    </Grid>
                    <Grid style={{
                      marginRight: '5px'
                      }} >
                      <Badge enableShadow disableOutline color="warning">Pool max: 0.1 BNB</Badge>
                    </Grid>
                    */}
                  </Grid.Container>

                  {/*
                  <Typography
                    variant="body2"
                    component="div"
                    lineHeight={1.5}
                    color="secondary.main"
                    mb={2}
                    textAlign="justify"
                  >
                    {artworkDetails?.length > 200
                      ? artworkDetails.slice(0, 200)
                      : artworkDetails}
                    {artworkDetails.length > 200 && (
                      <Button
                        variant="text"
                        onClick={handleOpenModal}
                        sx={{ color: "#01D4FA", textTransform: "lowercase" }}
                      >
                        ...{t("READ_MORE")}
                      </Button>
                    )}
                  </Typography>

                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{
                      backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      mb: 3,
                      border: "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Typography component="span" color="secondary">
                          <BsChevronDown
                            fontSize={"1rem"}
                            style={{ marginTop: "5px" }}
                          />
                        </Typography>
                      }
                    >
                      <Typography
                        variant="h6"
                        color="secondary"
                        ml={-2}
                        fontWeight={500}
                      >
                        {t("PROPERTIES")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ border: "none", ml: -2 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {fa?.properties &&
                        fa?.properties.length > 0 &&
                        fa?.properties[0].key != "" ? (
                          <>
                            {fa.properties?.map((property) => (
                              <Box
                                className={styles.propBox}
                                bgcolor={darkMode ? "#040404" : "#ffffff"}
                              >
                                <Typography
                                  variant="button"
                                  component="p"
                                  color="#01D4FA"
                                >
                                  {property.key}
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {property.value}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" color="secondary">
                              {t("NO_PROPERTIES_FOUND")}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
*/}
                <Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Ticket price")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {basePrice == 0 ? `FREE` : `${basePrice} ${coinNetwork}`}
                      </Typography>
                    </Box>

                  </div>                        

                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Total Entries")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {players}/{quorum}
                      </Typography>
                    </Box>

                  </div>



                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Max Tickets per User")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {maxperuser}
                      </Typography>
                    </Box>

                  </div>



                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("My Entries")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {myentries}
                      </Typography>
                    </Box>

                  </div>


                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Chance to win (Current)")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                    {chanceWinNew === chanceWin ? (                    
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      {chanceWinNew.toFixed(2)}%
                    </Typography>
                    ) : (
                      <>
                      <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      <span style={{ color: '#FF0000' , textDecoration: 'line-through' }} >{chanceWin.toFixed(2)}%</span> <span>{chanceWinNew.toFixed(2)}%</span>
                    </Typography>                      
                      </>
                    )}
                    </Box>

                  </div>


                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Chance to win (If sold out)")}
                      </Typography>
                    </Box>
                    <Box textAlign="right">                    
                    {chanceWinTotalNew === chanceWinTotal ? (                    
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      {chanceWinTotalNew.toFixed(2)}%
                    </Typography>
                    ) : (
                      <>
                      <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      <span style={{ color: '#FF0000' , textDecoration: 'line-through' }} >{chanceWinTotal.toFixed(2)}%</span> <span>{chanceWinTotalNew.toFixed(2)}%</span>
                    </Typography>                      
                      </>
                    )}
                    </Box>

                  </div>


                  <Divider className={styles.dividerBox} />
                  <Box className={styles.singleArtCardInfo}>
                    <Box 
                    onClick={()=> window.open(`https://${explorerURL}/address/${manager}`, "_blank")}
                    className={styles.avatarBox}>
                      <Box>
                        <Jazzicon diameter={35} seed={jsNumberForAddress(manager)} />
                        {/* <Avatar src={creatorImage} alt={creator} /> */}
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("CREATOR")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {creator}
                        </Typography>
                      </Stack>
                    </Box>
                    
                    {winner !== "0x0000000000000000000000000000000000000000" ? (
                    <Box 
                    onClick={()=> window.open(`https://${explorerURL}/address/${winner}`, "_blank")}
                    className={styles.avatarBox}>
                      <Box>
                      <Jazzicon diameter={35} seed={jsNumberForAddress(winner)} />
                        {/* <Avatar src={ownerImage} alt={owner} /> */}
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("OWNER")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {winner}
                        </Typography>
                      </Stack>
                    </Box>
                    ) : (
                      null
                    )}
                  </Box>
                  <Divider className={styles.dividerBox} />                  


                  <CardZCoreClub/>


                  {/*
                  <Box className={styles.cardFooter}>
                    <Stack direction="row" alignItems="center">
                      <IconButton>
                        <Typography component="span" color="secondary">
                          <HiOutlineClock className={styles.footerIcons} />
                        </Typography>
                      </IconButton>
                      <Typography
                        variant="caption"
                        component="span"
                        color={darkMode ? "#ffffff" : "#121212"}
                      >
                        {uploaded + "hr ago"}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box className={styles.userInteractionBox}>
                        <IconButton onClick={() => setLikeState(!likeState)}>
                          <Typography color="#e23e58" component="span">
                            <AiOutlineHeart className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                        <Typography
                          variant="caption"
                          component="span"
                          color={darkMode ? "#ffffff" : "#121212"}
                        >
                          {likes}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton>
                          <Typography component="span" color="secondary">
                            <BsBookmark className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                  */}
                </Box>
                {auctionStatus === "live" ? (                  
                  !disableBoxEntrie ? 
                    <>
                    <Box style={{ marginTop: '25px' , textAlign: 'center' }} >


{/* BOX QTD */}
<ConstBoxQtd/>
{/*
                    <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          name="qtdTicket"
                          placeholder={t(
                            "QTD"
                          )}
                          onChange={handleQtd}
                          style={{
                            textAlign: "center",
                            maxWidth: "50px",
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />

*/}
{/* BOX QTD */}


                      </Box>

                    <Box 
                    style={{ marginTop: '10px' , textAlign: 'center' }} 
                    >

                      <GradientButtonPrimary
                        disabled={!write || error || btnDisabled || onclickSendBnb}
                        onClick={clickSendBnb}
                        className={styles.gradientButtonClass}
                        variant="contained"
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={1}
                        >
                          <IoCart />
                        </Typography>
                        {valueQtd == 0 ? (
                        <Typography variant="body1" fontWeight='bold' component="span"
                        style={{ textTransform: 'none' }}
                        >                          
                            {t("Buy")} {t("Ticket")} for {qtdPrice == 0 ? `FREE`: `${qtdPrice} ${coinNetwork}`}                                                                            
                        </Typography>
                        ):(
                          <Typography variant="body1" fontWeight='bold' component="span"
                          style={{ textTransform: 'none' }}
                          >
                            {t("Buy")} {valueQtd} {t("Ticket")} for {qtdPrice == 0 ? `FREE`: `${qtdPrice} ${coinNetwork}`}
                          </Typography>  
                        )}
                      </GradientButtonPrimary>
                      <CardOwnerNotBuy/>
                      {/*
                      <GradientButtonSecondary
                        className={styles.gradientButtonClass}
                        variant="contained"
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                          {t(`Buy for ${artworkPrice}`)}
                        </Typography>
                      </GradientButtonSecondary>
                    */}
                    </Box>
                    </>
                     : 
                     <CardEnded/>
                ) : (
                  <Box className={styles.footerButtonContainer}>
                      {auctionStatus === "soon" ? (
                        <>
                        {currentAccount.address === manager ? (                          
                          <Box style={{ marginTop: '25px' , textAlign: 'center' }} >


                      {tallowance == 0 ? (
                        <GradientButtonSecondary
                          style={{ marginTop: '15px' }}
                          className={styles.gradientButtonClass}
                          variant="contained"
                          onClick={approveallowance}
                          disabled={btnApprove}
                          >
                          <IoConstruct style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Approve {tokenData[0].symbol} Contract
                          </Typography>
                          </GradientButtonSecondary>
                      ) : (

                        <>
                        <Typography variant="body1" component="span">
                          Balance : {balToken} {tokenData[0].symbol}
                        </Typography>
                      <Box>
                        <input
                        onChange={handleSendToken}
                        autoComplete="off"                          
                        className={darkMode ? "inputField" : null}
                        type="number"
                        name="tokensstart"
                        placeholder={t(
                          "Enter total token to send"
                        )}
                        style={{
                          textAlign: "center",
                          maxWidth: "200px",
                          fontSize: "14px",
                          border: "1px solid #c4c4c4",
                          borderRadius: "6px",
                          padding: "1rem 1.5rem",
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          backgroundColor: `${
                            darkMode ? "#171c26" : "#ffffff"
                          }`,
                          width: "90%",
                          zIndex: 1000,
                        }}                
                      />
                      </Box>

                        <GradientButtonSecondary
                          disabled={!writeToken || errorToken || onclickSendToken}
                          onClick={clickSendToken}                                
                          style={{ marginTop: '15px' }}
                          className={styles.gradientButtonClass}
                          variant="contained"
                          >
                          <IoSend style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Send {tokenData[0].symbol} and start Raffle
                          </Typography>
                          </GradientButtonSecondary>         
                        </>                                         
                      )}
                        </Box>
                        
                        ) : (

                          <>
                            <CardNotStarted/>
                          </>
                        
                        )}
                        </>
                        ) : (
                          <CardWinner/>
                        )}
                  </Box>
                )}
              </Box>









            </Box>



) : (





            <Box className={styles.detailsContainerMobile}>
              <Box zIndex={10}>
              {auctionStatus === "live" || auctionStatus === "past" ? 
                <img
                  className={styles.artDisplayImageMobile}
                  src={nftThumb}
                  alt={artworkTitle}
                  loading="eager"
                />
                : 
                <img
                style={{ 
                  filter: 'grayscale(100%)',
                  opacity: '1'
                 }}                
                  className={styles.artDisplayImageMobile}
                  src={nftThumb}
                  alt={artworkTitle}
                  loading="eager"
                />                
              }
              </Box>


              
              {/*
              <Box className={styles.userInteractionBoxMobile}>
                <Stack direction="row" alignItems="center">
                  <IconButton>
                    <Typography component="span" color="secondary">
                      <HiOutlineClock className={styles.footerIcons} />
                    </Typography>
                  </IconButton>
                  <Typography
                    variant="caption"
                    component="span"
                    color={darkMode ? "#ffffff" : "#121212"}
                  >
                    {uploaded} {t("HOURS_AGO")}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box className={styles.userInteractionBoxMobile}>
                    <IconButton onClick={() => setLikeState(!likeState)}>
                      <Typography component="span" color="secondary">
                        <AiOutlineHeart className={styles.footerIcons} />
                      </Typography>
                    </IconButton>
                    <Typography
                      variant="caption"
                      component="span"
                      color={darkMode ? "#ffffff" : "#121212"}
                    >
                      {likes}
                    </Typography>
                  </Box>
                  <Box mt={-0.5}>
                    <IconButton>
                      <Typography component="span" color="secondary">
                        <BsBookmark className={styles.footerIcons} />
                      </Typography>
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            */}
            {auctionStatus === "live" && (
                    <Box className={styles.artDisplayDetailsContent}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      color="white"
                    >Ends in:</Typography>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={2}
                        fontWeight={500}
                        sx={{
                          cursor: "pointer",
                        }}
                      >                        
                      <CountDownBoard
                      darkMode={darkMode}
                      futureDate={futureDate}
                      isCard={true}
                    />
                      </Typography>
                    </div>
                  </Box>
              )}
              <Box>
                <Box className={styles.cardFooterMobile}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <Jazzicon diameter={35} seed={jsNumberForAddress(manager)} />
                      {/* <Avatar src={creatorImage} alt={creator} /> */}
                    </Box>
                    <Stack direction="column" alignItems="left">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("CREATOR")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                        sx={{
                          textTransform: "lowercase",
                          maxWidth: "90px",
                        }}
                        noWrap={true}
                      >
                        {creator}
                      </Typography>
                    </Stack>
                  </Box>

                {winner !== "0x0000000000000000000000000000000000000000" ? 
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <Jazzicon diameter={35} seed={jsNumberForAddress(winner)} />
                    </Box>
                    <Stack direction="column" alignItems="left">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("OWNER")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                        sx={{
                          textTransform: "lowercase",
                          maxWidth: "90px",
                        }}
                        noWrap={true}
                      >
                        {winner}
                      </Typography>
                    </Stack>
                  </Box>
                  :
                    null
                }
                </Box>
                {/*
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="h2"
                    color="secondary.main"
                    mb={2}
                  >
                    {artworkTitle}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    lineHeight={1.8}
                    mb={2}
                    color="secondary.main"
                    textAlign="left"
                  >
                    {artworkDetails?.length > 200
                      ? artworkDetails?.slice(0, 200)
                      : artworkDetails}
                    {artworkDetails.length > 200 && (
                      <Button
                        variant="text"
                        onClick={handleOpenModal}
                        sx={{ fontSize: "11px", color: "#01D4FA" }}
                      >
                        ...{t("READ_MORE")}
                      </Button>
                    )}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("BASE_PRICE")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {basePrice}
                      </Typography>
                    </Box>
                    <Box textAlign="right">
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("HIGHEST_BID")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {highestBid}
                      </Typography>
                    </Box>
                  </div>
                </Box>
                */}

                {!isnft &&
                <Box
                  className={styles.artDisplayDetailsMobile}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box className={styles.artDisplayDetailsContentMob} gap={2}>
                    <Button
                      onClick={()=> window.open(`https://www.dextools.io/app/en/bnb/pair-explorer/${token}`, "_blank")}
                      variant="contained"
                      className={styles.artDisplayDetailsButtonMob}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={IPFSIcon}
                          alt="IPFS Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        {t("VIEW_ON_IPFS")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        align="right"
                      >
                        
                      </Typography>
                    </Button>
                    <Button
                      onClick={()=> window.open(`https://${explorerURL}/token/${token}`, "_blank")}                    
                      variant="contained"
                      className={styles.artDisplayDetailsButtonMob}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={BSCIcon}
                          alt="BSC"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        View on BSC
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        
                      </Typography>
                    </Button>

                    <Button
                      onClick={addMetamask}                    
                      variant="contained"
                      className={styles.artDisplayDetailsButtonMob}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={MetaMaskIcon}
                          alt="MetaMaskIcon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        Add Token
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        
                      </Typography>
                    </Button>

                  </Box>
                </Box>
}
                <Box
                  className={styles.artDisplayDetailsMobile}
                  bgcolor={`${darkMode ? "#171C26" : "#FFF2F8"}`}
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box gap={1}>
                    <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {isnft ? (
                            `${t("Collection")}`
                          ):(
                            `${t("Raffle Token")}`
                          )}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                          {isnft && !isMobile? (
                            `${tokenData[0].name}`
                          ):(
                            `${tokenData[0].symbol}`
                          )}
                          </Typography>
                        </div>
                      </Box>

                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("Contract")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {truncateEthAddress(token)}
                          </Typography>

                        </div>
                      </Box>

                      {/*
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          Token ID
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {21}
                        </Typography>
                      </Box>
                      */}
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          Type
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                            {isnft ? (
                            `ERC721`
                          ):(
                            `BEP20`
                          )}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("BLOCKCHAIN")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          BSC
                        </Typography>
                      </Box>
                      {/*
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("ROYALTY_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {5 + "%"}
                        </Typography>
                      </Box>
                      
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TRANSACTION_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          fontWeight={500}
                        >
                          5%
                        </Typography>
                      </Box>
                    */}
                    </Box>
                  </Box>
                </Box>
                {currentAddress == manager &&
                <Box style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                }}>
                  <GradientButtonSecondary
                          style={{ 
                            marginTop: '30px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',                      
                         }}
                          onClick={onclickPick}
                          disabled={!writePick || errorPick || !runPick || isSuccessPick}
                          variant="contained"
                          >
                          <IoDice style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Pick Winner
                          </Typography>
                          </GradientButtonSecondary>                    
                  </Box>
                }

{/*

                {auctionStatus === "live" ? (

                  
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <GradientButtonPrimary
                        className={styles.gradientButtonClass}
                        variant="contained"
                        onClick={() => setShowBidInput(true)}
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                          {t("PLACE_YOUR_BID")}
                        </Typography>
                      </GradientButtonPrimary>
                    </Box>
                    <Box>
                      {" "}
                      <GradientButtonSecondary
                        className={styles.gradientButtonClass}
                        variant="contained"
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                          {t(`Buy aaafor ${artworkPrice}`)}
                        </Typography>
                      </GradientButtonSecondary>
                    </Box>
                  </>


                ) : (
                  <Box className={styles.footerButtonContainer}>
                    <GradientButtonPrimary
                      className={styles.gradientButtonClass}
                      variant="contained"
                    >
                      {auctionStatus === "soon" ? (
                        <>
                        <IoHourglassOutline style={{ fontSize: '22px' }}/>
                        <Typography variant="body1" component="span">                        
                          {t("Raffle not Started")}
                        </Typography>
                        </>
                        ) : (
                          <>
                          <IoAlertCircleSharp style={{ fontSize: '22px' }}/>
                          <Typography variant="body1" component="span">                        
                          {t("AUCTION_ENDED")}
                          </Typography>
                          </>
                        )}
                    </GradientButtonPrimary>
                  </Box>
                  )}
*/}







              <Box
                className={styles.detailsContainerContentBox}
                bgcolor={`${darkMode ? "#171C26" : "#FFF2F8"}`}
                sx={{
                  position: "relative",
                  mt: 3
                }}
                >
                
                <Box>
                {isExploding && 
                  <ConfettiExplosion
                    particleCount={250}
                    width={1600}
                    duration={15000}
                    force={0.8}
                    sx={{
                      zIndex: 999999999
                    }}
                  />}                  
                  <Typography
                    variant="h5"
                    component="h1"
                    color="secondary.main"
                    mb={4}
                    fontWeight={500}
                  >
                    {artworkTitle}
                  </Typography>
                  {/*
                  <Typography
                    variant="body2"
                    component="div"
                    lineHeight={1.5}
                    color="secondary.main"
                    mb={2}
                    textAlign="justify"
                  >
                    {artworkDetails?.length > 200
                      ? artworkDetails.slice(0, 200)
                      : artworkDetails}
                    {artworkDetails.length > 200 && (
                      <Button
                        variant="text"
                        onClick={handleOpenModal}
                        sx={{ color: "#01D4FA", textTransform: "lowercase" }}
                      >
                        ...{t("READ_MORE")}
                      </Button>
                    )}
                  </Typography>

                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{
                      backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
                      mb: 3,
                      border: "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Typography component="span" color="secondary">
                          <BsChevronDown
                            fontSize={"1rem"}
                            style={{ marginTop: "5px" }}
                          />
                        </Typography>
                      }
                    >
                      <Typography
                        variant="h6"
                        color="secondary"
                        ml={-2}
                        fontWeight={500}
                      >
                        {t("PROPERTIES")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ border: "none", ml: -2 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {fa?.properties &&
                        fa?.properties.length > 0 &&
                        fa?.properties[0].key != "" ? (
                          <>
                            {fa.properties?.map((property) => (
                              <Box
                                className={styles.propBox}
                                bgcolor={darkMode ? "#040404" : "#ffffff"}
                              >
                                <Typography
                                  variant="button"
                                  component="p"
                                  color="#01D4FA"
                                >
                                  {property.key}
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {property.value}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" color="secondary">
                              {t("NO_PROPERTIES_FOUND")}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
*/}
                <Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Ticket price")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {basePrice == 0 ? `FREE` : `${basePrice} ${coinNetwork}`}
                      </Typography>
                    </Box>

                  </div>                        

                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Total Entries")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {players}/{quorum}
                      </Typography>
                    </Box>

                  </div>

                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Max Tickets per User")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {maxperuser}
                      </Typography>
                    </Box>

                  </div>


                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("My Entries")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {myentries}
                      </Typography>
                    </Box>

                  </div>


                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Chance to win (Current)")}
                      </Typography>
                    </Box>

                    <Box textAlign="right">
                    {chanceWinNew === chanceWin ? (                    
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      {chanceWinNew.toFixed(2)}%
                    </Typography>
                    ) : (
                      <>
                      <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      <span style={{ color: '#FF0000' , textDecoration: 'line-through' }} >{chanceWin.toFixed(2)}%</span> <span>{chanceWinNew.toFixed(2)}%</span>
                    </Typography>                      
                      </>
                    )}
                    </Box>

                  </div>


                  <Divider style={{ marginTop: '0px' , marginBottom: '15px' }} className={styles.dividerBox} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                  
                    <Box>
                       <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("Chance to win (If sold out)")}
                      </Typography>
                    </Box>
                    <Box textAlign="right">                    
                    {chanceWinTotalNew === chanceWinTotal ? (                    
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      {chanceWinTotalNew.toFixed(2)}%
                    </Typography>
                    ) : (
                      <>
                      <Typography
                      variant="subtitle1"
                      component="p"
                      color="secondary.main"
                      mb={1}
                      fontWeight={500}
                    >
                      <span style={{ color: '#FF0000' , textDecoration: 'line-through' }} >{chanceWinTotal.toFixed(2)}%</span> <span>{chanceWinTotalNew.toFixed(2)}%</span>
                    </Typography>                      
                      </>
                    )}
                    </Box>

                  </div>


                  <Divider className={styles.dividerBox} />
                  <Box className={styles.singleArtCardInfo}>
                    <Box 
                    onClick={()=> window.open(`https://${explorerURL}/address/${manager}`, "_blank")}
                    className={styles.avatarBox}>
                      <Box>
                        <Jazzicon diameter={35} seed={jsNumberForAddress(manager)} />
                        {/* <Avatar src={creatorImage} alt={creator} /> */}
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("CREATOR")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {creator}
                        </Typography>
                      </Stack>
                    </Box>
                    
                    {winner !== "0x0000000000000000000000000000000000000000" ? (
                    <Box 
                    onClick={()=> window.open(`https://${explorerURL}/address/${winner}`, "_blank")}
                    className={styles.avatarBox}>
                      <Box>
                      <Jazzicon diameter={35} seed={jsNumberForAddress(winner)} />
                        {/* <Avatar src={ownerImage} alt={owner} /> */}
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("OWNER")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {winner}
                        </Typography>
                      </Stack>
                    </Box>
                    ) : (
                      null
                    )}
                  </Box>
                  <Divider className={styles.dividerBox} />

                  <CardZCoreClub/>

                  {/*
                  <Box className={styles.cardFooter}>
                    <Stack direction="row" alignItems="center">
                      <IconButton>
                        <Typography component="span" color="secondary">
                          <HiOutlineClock className={styles.footerIcons} />
                        </Typography>
                      </IconButton>
                      <Typography
                        variant="caption"
                        component="span"
                        color={darkMode ? "#ffffff" : "#121212"}
                      >
                        {uploaded + "hr ago"}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box className={styles.userInteractionBox}>
                        <IconButton onClick={() => setLikeState(!likeState)}>
                          <Typography color="#e23e58" component="span">
                            <AiOutlineHeart className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                        <Typography
                          variant="caption"
                          component="span"
                          color={darkMode ? "#ffffff" : "#121212"}
                        >
                          {likes}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton>
                          <Typography component="span" color="secondary">
                            <BsBookmark className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                  */}
                </Box>
                {auctionStatus === "live" ? (                  
                  !disableBoxEntrie ? 
                    <>
                    <Box style={{ marginTop: '25px' , textAlign: 'center' }} >

                    <ConstBoxQtd/>
{/*
                    <input
                          autoComplete="off"
                          onWheel={(e) => e.target.blur()}
                          onKeyPress={preventMinus}
                          className={darkMode ? "inputField" : null}
                          type="number"
                          name="qtdTicket"
                          placeholder={t(
                            "QTD"
                          )}
                          onChange={handleQtd}
                          style={{
                            textAlign: "center",
                            maxWidth: "50px",
                            fontSize: "14px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "6px",
                            padding: "1rem 1.5rem",
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                            backgroundColor: `${
                              darkMode ? "#171c26" : "#ffffff"
                            }`,
                            width: "90%",
                            zIndex: 1000,
                          }}
                        />
*/}                        
                      </Box>

                    <Box 
                    style={{ marginTop: '10px' , textAlign: 'center' }} 
                    >

                      <GradientButtonPrimary
                        disabled={!write || error || btnDisabled || onclickSendBnb}
                        onClick={clickSendBnb}
                        className={styles.gradientButtonClass}
                        variant="contained"
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={1}
                        >
                          <IoCart />
                        </Typography>
                        {valueQtd == 0 ? (
                        <Typography variant="body1" fontWeight='bold' component="span"
                        style={{ textTransform: 'none' }}
                        >                          
                            {t("Buy")} {t("Ticket")} for<br />{qtdPrice == 0 ? `FREE`: `${qtdPrice} ${coinNetwork}`}                                                                            
                        </Typography>
                        ):(
                          <Typography variant="body1" fontWeight='bold' component="span"
                          style={{ textTransform: 'none' }}
                          >
                            {t("Buy")} {valueQtd} {t("Ticket")} for<br />{qtdPrice == 0 ? `FREE`: `${qtdPrice} ${coinNetwork}`}
                          </Typography>  
                        )}

                      </GradientButtonPrimary>
                      <CardOwnerNotBuy/>
                      {/*
                      <GradientButtonSecondary
                        className={styles.gradientButtonClass}
                        variant="contained"
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                          {t(`Buy for ${artworkPrice}`)}
                        </Typography>
                      </GradientButtonSecondary>
                    */}
                    </Box>
                    </>
                     : 
                     <CardEnded/>
                ) : (
                  <Box className={styles.footerButtonContainer}>
                      {auctionStatus === "soon" ? (
                        <>
                        {currentAccount.address === manager ? (                          
                          <Box style={{ marginTop: '25px' , textAlign: 'center' }} >


                      {tallowance == 0 ? (
                        <GradientButtonSecondary
                          style={{ marginTop: '15px' }}
                          className={styles.gradientButtonClass}
                          variant="contained"
                          onClick={approveallowance}
                          disabled={btnApprove}
                          >
                          <IoConstruct style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Approve {tokenData[0].symbol} Contract
                          </Typography>
                          </GradientButtonSecondary>
                      ) : (

                        <>
                        <Typography variant="body1" component="span">
                          Balance : {balToken} {tokenData[0].symbol}
                        </Typography>
                      <Box>
                        <input
                        onChange={handleSendToken}
                        autoComplete="off"                          
                        className={darkMode ? "inputField" : null}
                        type="number"
                        name="tokensstart"
                        placeholder={t(
                          "Enter total token to send"
                        )}
                        style={{
                          textAlign: "center",
                          maxWidth: "200px",
                          fontSize: "14px",
                          border: "1px solid #c4c4c4",
                          borderRadius: "6px",
                          padding: "1rem 1.5rem",
                          color: `${darkMode ? "#ffffff" : "#121212"}`,
                          backgroundColor: `${
                            darkMode ? "#171c26" : "#ffffff"
                          }`,
                          width: "90%",
                          zIndex: 1000,
                        }}                
                      />
                      </Box>

                        <GradientButtonSecondary
                          disabled={!writeToken || errorToken || onclickSendToken}
                          onClick={clickSendToken}                                
                          style={{ marginTop: '15px' }}
                          className={styles.gradientButtonClass}
                          variant="contained"
                          >
                          <IoSend style={{ fontSize: '22px' }}/>
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                            Send {tokenData[0].symbol} and start Raffle
                          </Typography>
                          </GradientButtonSecondary>         
                        </>                                         
                      )}
                        </Box>
                        
                        ) : (

                          <>
                            <CardNotStarted/>
                          </>
                        
                        )}
                        </>
                        ) : (
                          <CardWinner/>
                        )}
                  </Box>
                )}
              </Box>







              </Box>
            </Box>
          )}





        </Box>

        {/* Tabs and panels*/}
        {!isMobile ? (
          <Box className={styles.tabBox}>
            <Box sx={{ width: "100%", my: 16 , marginTop: '50px', marginBottom: '50px'}}>
              <Box
                bgcolor={`${darkMode ? "#171c26" : "#fff2f8"}`}
                borderRadius="10px"
              >
                <Tabs
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                >
                  <Tab
                    sx={{ color: "gray", ml: 5, textTransform: "capitalize" }}
                    label={t("RECENT_BID")}
                  />
                  <Tab
                    sx={{ color: "gray", ml: 12, textTransform: "capitalize" }}
                    label={t("HISTORY")}
                  />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                {/* Global tab styles */}
                <div className="tabStylesFull">
                  {allplayers.map((bd) => (
                    <Box mb={2} key={bd.data}>
                      <Typography
                        variant="body1"
                        className={styles.tabBoxContent}
                      >
                        <Jazzicon diameter={35} 
                                                  style={{
                                                    border: `2px solid ${
                                                      darkMode ? "#ffffff" : "#01D4FA"
                                                    }`,
                                                  }}
                        seed={jsNumberForAddress(bd.user)} />
                        {/*
                        <Avatar
                          sx={{
                            border: `2px solid ${
                              darkMode ? "#ffffff" : "#01D4FA"
                            }`,
                          }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                        */}
                        <Typography variant="body2" component="p">
                          <span style={{ fontWeight: "bold" }}>
                            <a target="_blank" rel="noreferrer" href={`https://${explorerURL}/address/${bd.user}`}>{bd.user}</a>
                          </span>{" get "}
                          <span style={{ fontWeight: "bold" }}>{bd.count}</span>{" ticket(s) "}
                          {t("BIDDING_AT")} <TimeAgo date={moment(bd.data * 1000)} />
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="tabStylesFull">
                {topplayers.map((bd) => (
                    <Box mb={2} key={bd.id}>
                      <Typography className={styles.tabBoxContent}>
                      <Jazzicon diameter={35} 
                                                style={{
                                                  border: `2px solid ${
                                                    darkMode ? "#ffffff" : "#01D4FA"
                                                  }`,
                                                }}
                      seed={jsNumberForAddress(bd.user)} />
                      {/*
                        <Avatar
                          sx={{
                            border: `2px solid ${
                              darkMode ? "#ffffff" : "#01D4FA"
                            }`,
                          }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                      */}
                        <Typography variant="body2" component="p">
                        <span style={{ fontWeight: "bold" }}>
                            <a target="_blank" rel="noreferrer" href={`https://${explorerURL}/address/${bd.user}`}>{bd.user}</a>
                          </span>{" - Total: "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.count}
                          </span>{" ticket(s)"}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
            </Box>
          </Box>
        ) : (
          <Box width="90%" className={styles.tabBox}>
            <Box mt={5} mb={-3}>
              <Box ml={-2} mb={2}>
                <MobileTabs
                  darkMode={darkMode}
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                >
                  <MobileTab
                    sx={{ color: "gray", textTransform: "capitalize" }}
                    label={t("RECENT_BID")}
                  />
                  <MobileTab
                    sx={{ color: "gray", ml: 3, textTransform: "capitalize" }}
                    label={t("HISTORY")}
                  />
                </MobileTabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <div className="tabStylesMobile">
                  {allplayers.map((bd) => (
                    <Box pr={2} my={3} key={bd.id}>
                      <Typography
                        className={styles.tabBoxMobile}
                        variant="body2"
                      >
                        <Jazzicon diameter={35} 
                                                  style={{
                                                    border: `2px solid ${
                                                      darkMode ? "#ffffff" : "#01D4FA"
                                                    }`,
                                                  }}
                        seed={jsNumberForAddress(bd.user)} />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "12px", lineHeight: 2 }}
                          component="p"
                        >
                          <span style={{ fontWeight: "bold" }}>
                            <a target="_blank" rel="noreferrer" href={`https://${explorerURL}/address/${bd.user}`}>{ truncateEthAddress(bd.user)}</a>
                          </span>{" get "}
                          <span style={{ fontWeight: "bold" }}>{bd.count}</span>{" ticket(s) "}
                          <br />{t("BIDDING_AT")} <TimeAgo date={moment(bd.data * 1000)} />
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="tabStylesMobile">
                  {topplayers.map((bd) => (
                    <Box pr={2} my={3} key={bd.id}>
                      <Typography
                        variant="body1"
                        className={styles.tabBoxMobile}
                      >
                      <Jazzicon diameter={35} 
                                                style={{
                                                  border: `2px solid ${
                                                    darkMode ? "#ffffff" : "#01D4FA"
                                                  }`,
                                                }}
                      seed={jsNumberForAddress(bd.user)} />
                        <Typography
                          variant="body2"
                          component="p"
                          sx={{ fontSize: "12px", lineHeight: 2 }}
                        >
                        <span style={{ fontWeight: "bold" }}>
                            <a target="_blank" rel="noreferrer" href={`https://${explorerURL}/address/${bd.user}`}>{ truncateEthAddress(bd.user)}</a>
                          </span>{" - Total: "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.count}
                          </span>{" ticket(s)"}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
            </Box>
          </Box>
        )}
      </Box>

    </>
  );
};

export default SingleAuctionCard;