import { Input, Grid, Link, Card, Col, Row, Button, Text, Loading, Badge } from "@nextui-org/react";
import { Box } from '@mui/system';

const CardNFT = ({
    writeOwner,
    erroOwner,
    myFarmHorses,
    claimOwner
}) => {


    return (
      <Card css={{     
          aspectRatio: 1,
          w: "100%"
      }}>
        <Card.Body css={{ p: 0 }}>
          <Card.Image
            src={"/images/cardszcoreclub_bg.png"}
            objectFit="fit"
            width="100%"
            alt="Relaxing app background"
          />
          {/*
                  <GradientButtonPrimary
                            disabled={!writeOwner || erroOwner}                                        
                            onClick={claimOwner}                          
                            sx={{ 
                              marginBottom: '30px',
                              paddingBottom: 0,
                              paddingTop: 0,
                              alignItems: "center"
                            }}
                          >
                            <BiRefresh style={{ fontSize: '28px', padding: '5px' }}/>
                            <Typography variant="body2" component="span">
                              Refresh My NFTs
                            </Typography>
                          </GradientButtonPrimary>
        */}
        </Card.Body>
        <Card.Footer
          isBlurred
          css={{
            position: "absolute",
            bgBlur: "#0f111466",
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1000000,
          }}
        >

<Box>
                  <Text size={12} weight="bold" transform="uppercase" color="white">
                    You have  {myFarmHorses == 1 ?
                      `1 NFT`
                    :
                      `More than 1 NFT`
                    }    
                  </Text>
</Box>

<Box>
              <Button
                disabled={!writeOwner || erroOwner}                                        
                onClick={claimOwner}
                color="primary"
                rounded
                shadow
                css={{ marginRight: '20px'}}
              >
                <Text
                  css={{ color: "inherit" }}
                  size={12}
                  weight="bold"
                  transform="uppercase"
                >
                  Claim Authority
                </Text>
              </Button>
</Box>

        </Card.Footer>
      </Card>
    )
  
  
  };
  
  export default CardNFT;
  
  
  