import { useTheme } from '@emotion/react';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from "react-icons/fi";

const PrivacyPolicy = ({ darkMode }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Box>
          <Box
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              position: 'relative',
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              mt: 11,
              mb: 5,
            }}
          >


              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} style={{ color: '#ffffff' }} />
              </Box>
              <Typography
                variant="h6"
                component="p"
                color="secondary"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                }}
              >
                Privacy Policy
              </Typography>
              

            
          </Box>
          <Box
            color={darkMode ? '#ffffff' : '#121212'}
            bgcolor={darkMode ? '#171c26' : '#fff2f8'}
            sx={{
              borderRadius: '16px',
              p: 6,
            }}
          >
            <Typography variant='h6' component='h6' sx={{ mb: 3 }}>
              {t('LAST_UPDATED')}: 24 Feb 2023
            </Typography>
            <Divider sx={{ backgroundColor: '#8E8E8E', opacity: '0.7' }} />
            <Box>
              <Typography variant='h6' component='h6' sx={{ my: 2 }}>
                {t('OUR_PRIVACY_POLICY')}
              </Typography>
              <Typography
                variant='body2'
                component='p'
                sx={{
                  lineHeight: 2,
                  textAlign: 'justify',
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
                {t('PRIVACY_POLICY_DESC')}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              position: 'fixed',
              top: '0%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '10000',
              mt: 0,
            }}
          >
            <Typography
              variant='subtitle1'
              color='secondary'
              component='div'
              sx={{
                borderBottom: `${
                  darkMode ? '2px solid #ffffff' : '1px solid #171c26'
                }`,
              }}
            >
              
            </Typography>
          </Box>
          <Box
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              borderRadius: '16px',
              mt: 0,
            }}
          >
            <Box>
              <Typography variant='h6' component='h6' sx={{ mb: 1 }}>
                {t('OUR_PRIVACY_POLICY')}
              </Typography>
              <Divider sx={{ backgroundColor: '#8E8E8E', opacity: '0.4' }} />
              <Typography
                variant='body2'
                component='p'
                sx={{
                  lineHeight: 2,
                  textAlign: 'justify',
                  mt: 2,
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
                {t('PRIVACY_POLICY_DESC')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PrivacyPolicy;
