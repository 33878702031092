import {
  Avatar,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./EditProfile.css";

import { styled } from "@mui/material/styles";

// Avatar
import UserAvatar from "../../assets/userProfileAvatar.png";

// Icons
import { FiSave } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { IoCameraSharp } from "react-icons/io5";
import LandscapeIcon from "@mui/icons-material/Landscape";

// Gradient Button
import { GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";

// React router dom
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { RiCameraOffFill } from "react-icons/ri";
import { Cancel } from "@mui/icons-material";
import Loader from "../Loader/Loader";
// Custom input style
const Input = styled("input")({
  display: "none",
});

const EditProfile = ({ darkMode }) => {
  const [userMail, setUserMail] = React.useState("");
  const [userAvatar, setUserAvatar] = React.useState(null);
  const [userCover, setUserCover] = React.useState(null);

  const [mailError, setMailError] = React.useState("");
  const [avatarError, setAvatarError] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");

  const handleSave = () => {};

  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isMail = (userEmail) => {
    return /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      userEmail
    );
  };

  const handleImageUpload = (e) => {
    setUserAvatar(URL.createObjectURL(e.target.files[0]));
  };

  const handleCoverUpload = (e) => {
    setUserCover(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage("Loading...");
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, [2]);
  const handleSubmitEditProfile = (e) => {
    e.preventDefault();
    console.log("sub");
    if (!isMail(userMail)) {
      setMailError("Looks like it is not an email");
      return;
    } else {
      setMailError("");
    }

    if (!userAvatar) {
      setAvatarError("Please upload a profile photo of yours!");
      return;
    } else {
      setAvatarError("");
    }
    // setIsLoading(false);
    navigate("/profile/user-profile");
  };

  return (
    <>
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {t("SETTINGS_EDIT_PROFILE")}
          </Typography>
        </Box>
      )}
      {!isMobile ? (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            postion: "relative",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,

              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              {userCover ? (
                <>
                  <img
                    height={"200px"}
                    width={"100%"}
                    style={{ opacity: "0.9", filter: "brightness(0.9)" }}
                    src={userCover}
                    alt="Cover"
                  />
                  <Box position="absolute" sx={{ top: 0, right: 0 }}>
                    <IconButton onClick={() => setUserCover(null)}>
                      <Cancel />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <React.Fragment>
                  <Tooltip title="Click to upload cover">
                    <IconButton>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleCoverUpload}
                        />
                        <LandscapeIcon
                          sx={{
                            height: 150,
                            width: 150,
                            color: "rgba(85, 85, 85, 0.7)",
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </Stack>
            <Box
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                p: 5,
                pt: 0,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ textAlign: "center", position: "relative", mt: -5 }}>
                {!userAvatar ? (
                  <Avatar
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={UserAvatar}
                    alt="User Name"
                  />
                ) : (
                  <Avatar
                    src={userAvatar}
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                  />
                )}
                {!userAvatar ? (
                  <Box
                    sx={{ position: "absolute", left: "5.2%", bottom: "-24%" }}
                  >
                    <Box>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography
                            component="span"
                            color="secondary"
                            fontSize={25}
                          >
                            <IoCameraSharp />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "absolute", left: "5.2%", bottom: "-24%" }}
                  >
                    <IconButton onClick={() => setUserAvatar(null)}>
                      <Typography
                        component="span"
                        color="secondary"
                        fontSize={25}
                      >
                        <RiCameraOffFill />
                      </Typography>
                    </IconButton>
                  </Box>
                )}
              </Box>
              {avatarError && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: "red", mt: 2 }}
                >
                  {avatarError}
                </Typography>
              )}
              <Box
                onSubmit={handleSubmitEditProfile}
                sx={{ mt: 5 }}
                component="form"
              >
                {/* Full Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="fullName"
                  >
                    {t("FULL_NAME")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_FULL_NAME_HERE")}
                    name="userFullName"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                  />
                </Stack>
                {/* User Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userName"
                  >
                    {t("USER_NAME")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_YOUR_USER_NAME_HERE")}
                    name="userName"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                  />
                </Stack>
                {/* Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userEmail"
                  >
                    {t("EMAIL")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="email"
                    placeholder={t("EDIT_PROFILE_ENTER_EMAIL_HERE")}
                    name="userEmail"
                    onChange={(e) => setUserMail(e.target.value)}
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                    required
                  />
                  {mailError && (
                    <Typography
                      variant="caption"
                      component="p"
                      sx={{ color: "red" }}
                    >
                      {mailError}
                    </Typography>
                  )}
                </Stack>
                {/* Bio */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userEmail"
                  >
                    Bio *
                  </label>
                  <textarea
                    placeholder="Enter your bio"
                    name="userBio"
                    required
                    rows={5}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#121212"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                      resize: "vertical",
                      zIndex: 1000,
                    }}
                  />
                </Stack>
                {/* Mobile Number */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="mobileNumber"
                  >
                    {t("MOBILE_NUMBER")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="number"
                    placeholder={t("EDIT_PROFILE_ENTER_MOBILE_NUMBER_HERE")}
                    name="userMobileNumber"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      width: "90%",
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    mt: 8,
                    mb: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 3,
                    mr: 1,
                  }}
                >
                  <Button
                    onClick={() => navigate("/profile/user-profile")}
                    variant="outlined"
                    color="pink"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                      padding: "8px 1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography component="span" color="secondary" mt={1}>
                      <AiOutlineCloseSquare />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: `${darkMode ? "#ffffff" : "#040404"}`,
                      }}
                    >
                      {t("CLOSE")}
                    </Typography>
                  </Button>
                  <GradientButtonPrimary
                    onclick={() => handleSave}
                    type="submit"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="#ffffff" mt={1}>
                      <FiSave />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: "#ffffff",
                      }}
                    >
                      {t("SAVE")}
                    </Typography>
                  </GradientButtonPrimary>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            postion: "relative",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,

              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              {userCover ? (
                <>
                  <img
                    src={userCover}
                    alt="Cover"
                    style={{
                      display: "block",
                      height: "100px",
                      width: "100%",
                    }}
                  />
                  <Box position="absolute" sx={{ top: 0, right: 0 }}>
                    <IconButton onClick={() => setUserCover(null)}>
                      <Cancel />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <React.Fragment>
                  <Tooltip title="Click to upload cover">
                    <IconButton>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleCoverUpload}
                        />
                        <LandscapeIcon
                          sx={{
                            height: 100,
                            width: 100,
                            color: "rgba(85, 85, 85, 0.7)",
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </Stack>
            <Box
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                p: 1,
                pt: 0,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ textAlign: "center", position: "relative", mt: -5 }}>
                {!userAvatar ? (
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={UserAvatar}
                    alt="User Name"
                  />
                ) : (
                  <Avatar
                    src={userAvatar}
                    sx={{
                      width: "80px",
                      height: "80px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                  />
                )}
                {!userAvatar ? (
                  <Box
                    sx={{ position: "absolute", left: "7%", bottom: "-24%" }}
                  >
                    <Box>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography
                            component="span"
                            color="secondary"
                            fontSize={25}
                          >
                            <IoCameraSharp />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "absolute", left: "7%", bottom: "-24%" }}
                  >
                    <IconButton onClick={() => setUserAvatar(null)}>
                      <Typography
                        component="span"
                        color="secondary"
                        fontSize={25}
                      >
                        <RiCameraOffFill />
                      </Typography>
                    </IconButton>
                  </Box>
                )}
              </Box>
              {avatarError && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: "red", mt: 2 }}
                >
                  {avatarError}
                </Typography>
              )}
              <Box
                onSubmit={handleSubmitEditProfile}
                sx={{ mt: 5 }}
                component="form"
              >
                {/* Full Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="fullName"
                  >
                    {t("FULL_NAME")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_FULL_NAME_HERE")}
                    name="userFullName"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                  />
                </Stack>
                {/* User Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userName"
                  >
                    {t("USER_NAME")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_YOUR_USER_NAME_HERE")}
                    name="userName"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                  />
                </Stack>
                {/* Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userEmail"
                  >
                    {t("EMAIL")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="email"
                    placeholder={t("EDIT_PROFILE_ENTER_EMAIL_HERE")}
                    name="userEmail"
                    onChange={(e) => setUserMail(e.target.value)}
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                    required
                  />
                  {mailError && (
                    <Typography
                      variant="caption"
                      component="p"
                      sx={{ color: "red" }}
                    >
                      {mailError}
                    </Typography>
                  )}
                </Stack>
                {/* Bio */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="userEmail"
                  >
                    Bio *
                  </label>
                  <textarea
                    placeholder="Enter your bio"
                    name="userBio"
                    required
                    rows={5}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#121212"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                      resize: "vertical",
                      zIndex: 1000,
                    }}
                  />
                </Stack>
                {/* Mobile Number */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <label
                    style={{
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor="mobileNumber"
                  >
                    {t("MOBILE_NUMBER")} *
                  </label>
                  <input
                    className={darkMode && "inputField"}
                    type="number"
                    placeholder={t("EDIT_PROFILE_ENTER_MOBILE_NUMBER_HERE")}
                    name="userMobileNumber"
                    required
                    style={{
                      fontSize: "14px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "6px",
                      padding: "1rem 1.5rem",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    mt: 4,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 2,
                    // width: "90%",
                  }}
                >
                  <Button
                    onClick={() => navigate("/profile/user-profile")}
                    variant="outlined"
                    color="pink"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="secondary" mt={1}>
                      <AiOutlineCloseSquare />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: `${darkMode ? "#ffffff" : "#040404"}`,
                      }}
                    >
                      {t("CLOSE")}
                    </Typography>
                  </Button>
                  <Button
                    onclick={() => handleSave}
                    color="pink"
                    type="submit"
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="#ffffff" mt={1}>
                      <FiSave />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: "#ffffff",
                      }}
                    >
                      {t("SAVE")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditProfile;
