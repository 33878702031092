import collection1 from "../../assets/collectionImages/collection1.png";
import collection2 from "../../assets/collectionImages/collection2.png";
import collection3 from "../../assets/collectionImages/collection3.png";
import collection4 from "../../assets/collectionImages/collection4.png";
import collection5 from "../../assets/collectionImages/collection5.png";
import collection6 from "../../assets/collectionImages/collection6.png";

export const CollectionData = [
  {
    id: "1",
    title: "Collection Name1",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection1,
  },
  {
    id: "2",
    title: "Collection Name2",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection2,
  },
  {
    id: "3",
    title: "Collection Name3",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection3,
  },
  {
    id: "4",
    title: "Collection Name4",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection4,
  },
  {
    id: "5",
    title: "Collection Name5",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection5,
  },
  {
    id: "6",
    title: "Collection Name6",
    createdBy: "@jider",
    totalNFT: 35,
    timeStamp: "12 hours ago",
    likes: 40,
    image: collection6,
  },
];
