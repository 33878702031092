import React from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AtIcon from '../../../assets/Icons/atIcon.svg';
import PersonFillIcon from '../../../assets/Icons/personFillIcon.svg';
import PersonOutlineIcon from '../../../assets/Icons/personOutlineIcon.svg';
import LockIcon from '../../../assets/Icons/lockIcon.svg';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const SignUpPage = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { handleSignUpUser } = useAuth();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      userName: '',
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().email('Enter a valid email').required('Required'),
      fullName: Yup.string().required('Required'),
      userName: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .required('Required'),
      password: Yup.string().required('Required'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        "Password doesn't match"
      ),
    }),
    onSubmit: (values) => {
      handleSignUpUser(
        values.email,
        values.fullName,
        values.userName,
        values.password,
        navigate
      );
    },
  });
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {!isMobile ? (
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          px={5}
          height='100vh'
          py={5}
        >
          <Container>
            <Box
              component='form'
              onSubmit={formik.handleSubmit}
              color={darkMode ? '#ffffff' : '#121212'}
              width='100%'
            >
              <Typography variant='h2' fontWeight={500}>
                {t('HI')}👋
              </Typography>
              <Typography variant='subtitle1' color='GrayText'>
                {t('LETS_SETUP_YOUR_ACCOUNT')}
              </Typography>
              <Stack mt={5} direction='column' gap={4}>
                <Box>
                  <TextField
                    className='authInputs'
                    id='email'
                    name='email'
                    type='email'
                    variant='outlined'
                    placeholder='example@email.com'
                    label='Email'
                    color='pink'
                    autoFocus
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={AtIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    className='authInputs'
                    id='fullName'
                    name='fullName'
                    type='text'
                    variant='outlined'
                    placeholder='John Doe'
                    label='Full Name'
                    color='pink'
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={PersonFillIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    autoComplete='off'
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.fullName}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    className='authInputs'
                    id='userName'
                    name='userName'
                    type='text'
                    variant='outlined'
                    placeholder='JhonnyPapa'
                    label='User Name'
                    color='pink'
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={PersonOutlineIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    autoComplete='off'
                  />
                  {formik.touched.userName && formik.errors.userName ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.userName}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    className='authInputs'
                    id='password'
                    name='password'
                    type='password'
                    variant='outlined'
                    placeholder='Enter a strong password'
                    label='Password'
                    color='pink'
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={LockIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    autoComplete='off'
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    className='authInputs'
                    id='confirmPassword'
                    name='confirmPassword'
                    type='password'
                    variant='outlined'
                    placeholder='Re enter the password'
                    label='Confirm Password'
                    color='pink'
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={LockIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    autoComplete='off'
                  />
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.confirmPassword}
                    </Typography>
                  ) : null}
                </Box>
              </Stack>
              <Box mt={4}>
                <Button
                  type='submit'
                  variant='contained'
                  color='blue'
                  fullWidth
                  sx={{ py: 1.5, fontSize: '1rem' }}
                >
                  {t('SIGNUP')}
                </Button>
              </Box>
              <Box mt={5}>
                <Typography variant='body1' textAlign='center'>
                  {t('ALREADY_HAVE_ACCOUNT')}
                  <Typography
                    onClick={() => navigate('/authentication/sign-in')}
                    color='blue'
                    component='span'
                    sx={{
                      textDecoration: 'underline',
                      color: '#01D4FA',
                      cursor: 'pointer',
                    }}
                  >
                    {t('LOGIN')}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Stack>
      ) : (
        <Box px={3} py={3}>
          <Box
            component='form'
            onSubmit={formik.handleSubmit}
            color={darkMode ? '#ffffff' : '#121212'}
            width='100%'
          >
            <Typography variant='h2' fontWeight={500}>
              {t('HI')}👋
            </Typography>
            <Typography variant='subtitle1' color='GrayText'>
              {t('LETS_SETUP_YOUR_ACCOUNT')}
            </Typography>
            <Stack mt={5} direction='column' gap={4}>
              <Box>
                <TextField
                  className='authInputs'
                  id='email'
                  name='email'
                  type='email'
                  variant='outlined'
                  placeholder='example@email.com'
                  label='Email'
                  color='pink'
                  autoFocus
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={AtIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className='authInputs'
                  id='fullName'
                  name='fullName'
                  type='text'
                  variant='outlined'
                  placeholder='John Doe'
                  label='Full Name'
                  color='pink'
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={PersonFillIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  autoComplete='off'
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.fullName}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className='authInputs'
                  id='userName'
                  name='userName'
                  type='text'
                  variant='outlined'
                  placeholder='JhonnyPapa'
                  label='User Name'
                  color='pink'
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={PersonOutlineIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  autoComplete='off'
                />
                {formik.touched.userName && formik.errors.userName ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.userName}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className='authInputs'
                  id='password'
                  name='password'
                  type='password'
                  variant='outlined'
                  placeholder='Enter a strong password'
                  label='Password'
                  color='pink'
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={LockIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  autoComplete='off'
                />
                {formik.touched.password && formik.errors.password ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.password}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className='authInputs'
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  variant='outlined'
                  placeholder='Re enter the password'
                  label='Confirm Password'
                  color='pink'
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={LockIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  autoComplete='off'
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.confirmPassword}
                  </Typography>
                ) : null}
              </Box>
            </Stack>
            <Box mt={4}>
              <Button
                type='submit'
                variant='contained'
                color='blue'
                fullWidth
                sx={{ py: 1.5, fontSize: '1rem' }}
              >
                Signup
              </Button>
            </Box>
            <Box mt={5}>
              <Typography variant='body1' textAlign='center'>
                Already have an account?{' '}
                <Typography
                  onClick={() => navigate('/authentication/sign-in')}
                  color='blue'
                  component='span'
                  sx={{
                    textDecoration: 'underline',
                    color: '#01D4FA',
                    cursor: 'pointer',
                  }}
                >
                  {t('LOGIN')}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SignUpPage;
