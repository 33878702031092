import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const GradientButtonPrimary = styled(Button)({
  color: "#ffffff",
  border: "none",
  background: "linear-gradient(91.93deg, #0e560b 1.75%, #3c8b2f 98.27%)",
  borderRadius: "10px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "linear-gradient(91.93deg, #0e560b 98.27%, #3c8b2f 1.75%)",
  },
  "&:active": {
    boxShadow: "none",
    background: "linear-gradient(91.93deg, #0e560b 98.27%, #3c8b2f 1.75%)",
  },
  "&:focus": {
    background: "linear-gradient(91.93deg, #0e560b 98.27%, #3c8b2f 1.75%)",
  },
});
const GradientButtonSecondary = styled(Button)({
  color: "#ffffff",
  border: "none",
  background: "linear-gradient(91.95deg, #0999b2 1.75%, #0697b1 98.13%)",
  borderRadius: "10px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "linear-gradient(91.95deg, #0999b2 98.13%, #0697b1 1.75%)",
  },
  "&:active": {
    boxShadow: "none",
    background: "linear-gradient(91.95deg, #0999b2 98.13%, #0697b1 1.75%)",
  },
  "&:focus": {
    background: "linear-gradient(91.95deg, #0999b2 98.13%, #0697b1 1.75%)",
  },
});

export { GradientButtonPrimary, GradientButtonSecondary };
