import { useTheme } from '@emotion/react';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from "react-icons/fi";
import { Input, Grid, Link, Card, Col, Row, Button, Text, Loading, Badge } from "@nextui-org/react";
import WheelComponent from './WheelComponent'
import styles from "./ZCoreClub.module.css";
import { IoDice, IoStar } from 'react-icons/io5';
import { GradientButtonPrimary, GradientButtonSecondary } from '../../Utils/GradientButtons/GradientButtons';
import RouletteABI from "../../contract/abi/RouletteABI";
import FarmHorsesOwnersABI from "../../contract/abi/FarmHorsesOwnersABI";
import { FarmHorsesOwnersAdress, RouletteAdress , tokensList, jsonRpcURL , coinNetwork, thisNetwork } from "../../contract/contracts";
import { fetchBalance, getProvider , getAccount, getNetwork } from '@wagmi/core'
import { usePrepareContractWrite, useContractWrite , useBalance, useSigner, useWaitForTransaction} from 'wagmi'
import { ethers } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfettiExplosion from 'react-confetti-explosion';
import getTokenPrice  from "../../contract/getprice";
import Web3 from "web3";
import { GiTwoCoins } from 'react-icons/gi';
import BigNumber from "bignumber.js";
import { BiRefresh } from 'react-icons/bi';
import { useSearchParams } from "react-router-dom";
import { styled } from '@nextui-org/react';
import funcDebug from "../../contract/debug";
import axios from 'axios';
import Select from "react-select";
import ButtonZcoreClub from './ButtonZcoreClub';
import BoxJackpot from './BoxJackpot';
import CardNFT from './CardNFT';
import LastSpins from './LastSpins';
import SelectNumbers from './SelectNumbers';



const ZCoreClub = ({ darkMode }) => {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const windowWidth = windowSize.current[0];
  const windowHeight = windowSize.current[1];
  //funcDebug(`width: ${windowSize.current[0]} ~ height: ${windowSize.current[1]}`);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const referral = searchParams.get("u");

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [myNumber, setMyNumber] = useState(0);
  const [winner, setWinner] = useState('0');
  const [thiswinner, setThisWinner] = useState(0);
  const [roulEnable, setRoulEnable] = useState(false);
  const [disableBuy, setDisableBuy] = useState(true);
  const [disableSelect, setDisableSelect] = useState(false);
  //const [segments, setsegments] = useState([]);
  const [segColors, setsegColors] = useState([]);
  //const [selectOptions, setSelectOptions] = useState([]);
  const [mySpins, setMySpins] = useState([]);

  const [priceBet, setPriceBet] = useState('0');
  //const [lastRequest, setLastRequest] = useState('0');
  const [isZcoreClub, setIsZcoreClub] = useState(false);
  const [isPlaying, setisPlaying] = useState(false);
  const [priceBnb, setPriceBnb] = useState(0);
  const [myBnb, setMyBnb] = useState(0);
  const [claimBal, setClaimBal] = useState(0);
  const [jackpotTotal, setJackpotTotal] = useState(currencyFormat(0));
  const [blockNumber, setBlockNumber] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const [youWin, setYouWin] = useState(false);
  const [disableClaim, setDisableClaim] = useState(false);

  const [totalPrize, setTotalPrize] = useState(0);
  //const [countRun, setCountRun] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [ticketPriceClub, setTicketPriceClub] = useState(0);
  const [myFarmHorses, setMyFarmHorses] = useState(0);
  const [myReferral, setMyReferral] = useState('0x0000000000000000000000000000000000000000');

  const [myCustomLink, setCustomLink] = useState(null);

  const { data: signer } = useSigner()

  //funcDebug('isMobile: ' + isMobile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  const currentAccount = getAccount();
  const { chain, chains } = getNetwork();

  

  useEffect(() => {
    async function mybalance(address){
      const balance = await fetchBalance({
        address: address,
      })
      console.log('chain: mybalance ' + balance?.formatted );
    }
    console.log('chain: mybalance ' + myNumber );
    if(currentAccount){
      mybalance(currentAccount.address)      
    }
  }, [myNumber, currentAccount]);


  console.log('chain: ' + chain?.id );
  console.log('chain: ' + chains );
  console.log('chain: ' + currentAccount?.status );

  useEffect(() => {
    async function GetBnbPrice(){
      const npriceToken = await getTokenPrice(tokensList[0].contract_price);
      funcDebug('setJackPot: priceToken BNB: ' + npriceToken.bnb)
      setPriceBnb(npriceToken.bnb)
    }
    if(priceBnb == 0){
      GetBnbPrice();
    }
  }, []);



  useEffect(() => {
    if(chain?.id == undefined || chain?.id == null || chain?.id !== thisNetwork){
      // localStorage.clear();
      // setDisableBuy(true)
    }
  }, [chain]);


  useEffect(() => {
    if(signer == undefined || signer == null){
      setDisableBuy(true)
      //setDisableSelect(true)
    }else{
      setDisableBuy(false)
      //setDisableSelect(false)
    }    
  }, [signer]);

  
  useEffect(() => {
    if(referral?.charAt(0) == 0 && referral?.charAt(1) == 'x' && referral?.length ==  42 && referral != '0x0000000000000000000000000000000000000000'){
      localStorage.setItem("referral", referral);
      setMyReferral(referral);
    }else{
      const temp_ref = (localStorage.getItem("referral") === null || localStorage.getItem("referral") === undefined)? '0x0000000000000000000000000000000000000000' : localStorage.getItem("referral");
      setMyReferral(temp_ref);
      // localStorage.setItem("referral", '0x0000000000000000000000000000000000000000');
    }
    funcDebug('referral: ' + localStorage.getItem("referral"));
  }, [referral]);


  useEffect(() => {
    if(myCustomLink == null && currentAccount !== null && currentAccount !== undefined){
      // POST request using axios inside useEffect React hook
      const urlRef = `https://web3games.zcore.network/zcoreclub?u=${currentAccount.address}`;    
      // funcDebug(urlRef)
      const data = { 
         "domain": "bit.ly",
         "tags": [
            "nft_referral"
          ],      
         "long_url": urlRef
      };
      axios.defaults.headers.common['Authorization'] = '00bfce267a867673a7af183ea725a2fc76e479ef';
      axios.post('https://api-ssl.bitly.com/v4/shorten', data)
        .then(response => setCustomLink(response.data.link));
    }
 // empty dependency array means this effect will only run once (like componentDidMount in classes)
 }, [currentAccount]);
 

 

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }  

  /*
  function randomWinner(){
    const rand = randomNumberInRange(0, maxNumber)
    funcDebug('runBet: new winner '+ rand)
    setWinner(`${rand}`)
  }
  */
  

  const maxNumber = 50;
  //const segments = [];
  //const segColors = [];

  const segments = [];
  for (let i = 0; i < maxNumber; i++) {
    const number_r = i + 1;
    segments.push(`${number_r}`);
  }


  useEffect(() => {
  const temp_segColors = [];    
  for (let i = 0; i < maxNumber; i++) {
    const number_r = i + 1;
    //temp_segments.push(`${number_r}`);
    if(number_r == myNumber){
        temp_segColors.push('#34A24F');
    }else{
        if(number_r % 2 == 0){
            temp_segColors.push('#EE4040');
        }else{
            temp_segColors.push('#000000');
        }
    }
  }
  //funcDebug('countdown temp_segments: ' + temp_segments.length)
  //setsegments(temp_segments)
  setsegColors(temp_segColors)
  
  if(myNumber === 0){
    setDisableBuy(true)
  }else{
    setDisableBuy(false)
  }
}, [myNumber]);


useEffect(() => {
  getmySpins();
}, [signer]);

/*
  const segments = [
    'better luck next time',
    'won 70',
    'won 10',
    'better luck next time',
    'won 2',
    'won uber pass',
    'better luck next time',
    'won a voucher'
  ]
  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
  ]
  */

  const onFinished = (ttwinner) => {
    if(Number(ttwinner) === Number(myNumber)){
      funcDebug('runBet: checkRun YOU WIN! ');
      setYouWin(true);
    }
    setThisWinner(Number(ttwinner))
    //funcDebug('runBet winner: ' + ttwinner)
    setJackPot();
    getmySpins();
  }  


  const buyNumber = () => {
    if(myNumber > 0){
      checkRoulette(true)
    }
    
  };

  const copyLink = () => {
    toast.dismiss();
    toast.success('Copied!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    navigator.clipboard.writeText(myCustomLink)    
  }

  const handleMyNumber = (event) => {
    if(signer != undefined || signer != null){
      setMyNumber(event.target.value);
      funcDebug('runBet: setMyNumber '+ myNumber)
      if(thiswinner > 0){
        setThisWinner(0)
      }
      if(youWin){
        setYouWin(false);
      }
      if(autoPlay){
        setAutoPlay(false);
      }    
      checklink();
    }
  };



  async function getmySpins(){
    if(signer !== undefined && signer !== null){

      try {

      const apcontract = new ethers.Contract(
        RouletteAdress,
        RouletteABI,
        signer
      );

      const myRequests = await apcontract.getMyRequest(currentAccount.address)
      //console.log('mySpins: ' + myRequests)
      let mspins = [];
      for (let i = 0; i < myRequests.length; i++) {
        // const amount = ((new BigNumber(myRequests[i][3]).toNumber()) / (10 ** 18));
        mspins.push({
          id: i,
          bet : Number(myRequests[i][1]),
          spin : Number(myRequests[i][2]),
          data : Number(myRequests[i][5]),
        })
      }
      mspins !== null && mspins.sort((a, b) => (b.id > a.id) ? 1 : -1)
      //console.log('mySpins: ' + mspins)
      setMySpins(mspins);  
    } catch (error) {
      console.error('mySpins: ' + error)
    }
    }
  }


  async function checklink(){
    
    if(signer !== undefined && signer !== null){

      try {

      const apcontract = new ethers.Contract(
        RouletteAdress,
        RouletteABI,
        signer
      );

      const linkBalance = await apcontract.getLinkBalance()
      const feelink = await apcontract.feeLink()
      const linkBalanceFormated = (linkBalance / (10 ** 18));
      const feelinkFormated = (feelink / (10 ** 18));
    
      funcDebug('runBet: link linkBalance '+ linkBalanceFormated)
      funcDebug('runBet: link feelink '+ feelinkFormated)
      if(linkBalance < feelink){
        setMyNumber(0);
        toast.dismiss();
        toast.error('Contract without gas to run chainlink oracle. Wait for the recharge!', {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    } catch (error) {
    }

    }
    
  }

/*
  //const [countdown, setCountdown] = React.useState(0);
  useEffect(() => {
      const timeout = setTimeout(() => {
          //const newcountdown = isPlaying ? countdown + 1 : countdown;
          //setCountdown(newcountdown);
          if(isPlaying){
            checkRun();
          }          
      }, 1000);
      return () => {
          clearTimeout(timeout);
      }
}, [isPlaying]);
*/


funcDebug('========================================')
//funcDebug('runBet: winner '+ winner)
funcDebug('runBet: myNumber '+ myNumber)
funcDebug('runBet: disableBuy '+ disableBuy)
funcDebug('runBet: disableSelect '+ disableSelect)
funcDebug('runBet: blockNumber '+ blockNumber)
funcDebug('========================================')


const LoadingBox = () => {
  if(!disableSelect){
    return null;
  }else{
    return <>
    <Loading style={{ marginTop: '30px' }} color="warning" textColor="warning">
          {blockNumber == '0'? 
            `Loading...`
          :
            `Waiting for blockchain confirmations...`          
          }
          </Loading>
          <Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />          
      </>
  }
}


const YouWinBox = () => {
//const YouWinBox = useCallback(event => {
  if(youWin){
    return <ConfettiExplosion
          particleCount={250}
          width={1600}
          duration={10000}
          force={0.8}
          sx={{
            zIndex: 999999999
          }}
        />
  }else{
    return null;
  }

//}, [youWin]);
}

/*

useEffect(() => {
  let options = [];
  options.push(<option key={99999} value={0}>Select number</option>);
  //options.push({ value: 0, label: `Select number` });
  for (let i = 0; i < maxNumber; i++) {
      const number_r = i + 1;
      //options.push({ value: number_r, label: number_r });
      options.push(<option key={number_r} value={number_r}>{number_r}</option>)
  }
  setSelectOptions(options);
}, []);





const BoxNumbers = () => {

  return <Select
        style={{
          zIndex: '99999999999999999',
        }}
        options={selectOptions}
        onChange={handleMyNumber}
        />

return <select 
        disabled={disableSelect}
        autoComplete="off"
        onChange={handleMyNumber}
        style={{
          fontSize: "14px",
          border: "1px solid #c4c4c4",
          borderRadius: "6px",
          padding: "1rem 1.5rem",
          color: `${darkMode ? "#ffffff" : "#121212"}`,
          backgroundColor: `${
            darkMode ? "#171c26" : "#ffffff"
          }`,
          width: "100%",
          zIndex: 1000,        
        }}
        >          
          {selectOptions}
        </select>
};
*/



useEffect(() => {

  async function checkRun() {
    funcDebug('========================================')
    funcDebug('runBet: checkRun start');
    //setCountRun(contrun)
    if(signer !== undefined && signer !== null){
  
          const apcontract = new ethers.Contract(
            RouletteAdress,
            RouletteABI,
            signer
          );
  
          try {


            const lrequest = await apcontract.roulette(currentAccount.address);
            // SAVE CHECK LAST
            //setLastRequest(lrequest);
            // CHECK BLOCKNUMER
            // const blockLast = lrequest.blocknum;
            
            //if(lrequest.blocknum > 0){
            //  setBlockNumber(lrequest.blocknum);
            //}

            //funcDebug('runBet: oldlrequest ' + lastRequest);
            funcDebug('runBet: newlrequest ' + lrequest);
            //const blockNumberBig = (new BigNumber(blockNumber).toString());
            //funcDebug('runBet: blockNumberBig ' + blockNumberBig.toNumber());
            const lastBetUser = await apcontract.BetUsers(lrequest)
            const bet_num = lastBetUser[1];
            const amount = lastBetUser[2];
            const spinned = lastBetUser[3];
            const prize = lastBetUser[4];
            const isrun = lastBetUser[5];
            funcDebug('runBet: checkRun lastRequest ' + lrequest);
            funcDebug('runBet: checkRun lastBetUser ' + lastBetUser);
            funcDebug('runBet: checkRun isrun ' + isrun);
            //funcDebug('runBet: checkRun blockLast ' + blockLast);
            funcDebug('runBet: checkRun blockNumber ' + blockNumber);
            funcDebug('runBet: checkRun myNumber bet ' + bet_num);
            funcDebug('runBet: checkRun spinned ' + spinned);

            if(spinned > 0 && bet_num == myNumber){
              setDisableSelect(false);
              setDisableBuy(true);
              setWinner(`${spinned}`);
              setBlockNumber(0);
              setisPlaying(false);
              setAutoPlay(true);
            }else{
              setTimeout(() => {
                checkRun();
                funcDebug('checkRun AGAIN ========================================')
              }, 1000);              
            }
  
          } catch (error) {
            funcDebug('runBet: checkRun: ' + error);
          }            
  
    }
    funcDebug('========================================')
  }  
  
  if(isPlaying){
      checkRun();
      funcDebug('checkRun START ========================================')
  }
  
}, [isPlaying]);

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

async function setJackPot(){

    funcDebug('setJackPot: ' + currentAccount.address);

    const web3 = new Web3(jsonRpcURL)    
    const apcontract = new web3.eth.Contract(RouletteABI, RouletteAdress);

    //setDisableBtnApprove(true);
    //const apcontract = new ethers.Contract(
    //  RouletteAdress,
    //  RouletteABI,
    //  signer
    //);

  try {






    var bnbBal = currentAccount.address !== undefined ? await web3.eth.getBalance(currentAccount.address) : 0; //Will give value in.
    const bnbBalFormated = (bnbBal / (10 ** 18));
    setMyBnb(bnbBalFormated);
    funcDebug('setJackPot: bnbBal: ' + bnbBalFormated);


    var clBal = currentAccount.address !== undefined ? await apcontract.methods.winners(currentAccount.address).call() : 0; //Will give value in.
    const clBalFormated = clBal == 0 ? 0 : (clBal.prize / (10 ** 18));
    setClaimBal(clBalFormated * 0.90) // REFERRAL
    funcDebug('setJackPot: clBal: ' + clBal.prize);
    funcDebug('setJackPot: clBalFormated: ' + clBalFormated);

    const pticket_club = await apcontract.methods.TICKET_CLUB().call();
    const pticket = await apcontract.methods.TICKET().call();

    setTicketPrice((pticket / (10 ** 18)))
    setTicketPriceClub((pticket_club / (10 ** 18)))

    const tprize = await apcontract.methods.PRIZE().call();
    const tprizeRef = tprize * 0.90; // REFERRAL
    const tprizeFormated = (tprizeRef / (10 ** 18));
    setTotalPrize(tprizeFormated.toFixed(4));
    funcDebug('setJackPot: tprizeFormated: ' + tprizeFormated.toFixed(4));
    funcDebug('setJackPot: priceBnb: ' + priceBnb);

    const jptotal = tprizeFormated * priceBnb;
    setJackpotTotal(jptotal)  

  } catch (error) {
    console.error(error);
  }

}

useEffect(() => {
  setJackPot();
}, [priceBnb, myNumber, jackpotTotal]);

async function checkRoulette(isbuy) {
  funcDebug('========================================')
  funcDebug('runBet: checkRoulette start');
  if(signer !== undefined && signer !== null){


      try {                  

        //setDisableBtnApprove(true);
        const apcontract = new ethers.Contract(
          RouletteAdress,
          RouletteABI,
          signer
        );


          const checkClub = await apcontract.getNftsFarmTotal(currentAccount.address)
          const checkPrice = checkClub == 0 ? await apcontract.TICKET() : await apcontract.TICKET_CLUB();
          setPriceBet(checkPrice.toString())
          funcDebug('runBet: checkPrice ' + checkPrice);

          const iszclub = checkClub == 0 ? false : true;
          setIsZcoreClub(iszclub)
          funcDebug('runBet: iszclub ' + iszclub);

          //const lrequest = await apcontract.roulette(currentAccount.address);
          // SAVE CHECK LAST
          //setLastRequest(lrequest)
          //funcDebug('runBet: lrequest ' + lrequest.id);


      } catch (error) {
        funcDebug('runBet: checkPrice: ' + error);
      }
    

      if(isbuy){
        //if(lastRequest.toString() === '0'){
          setDisableBuy(true);
          setDisableSelect(true);
          writeBet?.();
        //}else{
        //  setDisableBuy(true);
        //  toast.dismiss();
        //  toast.info('Please wait, There is a player spinning the roulette wheel...', {
        //    position: "top-right",
        //    autoClose: 15000,
        //    hideProgressBar: false,
        //    closeOnClick: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    progress: undefined,
        //    theme: "dark",
        //  });
        //  await timeout(15000);
        //  setDisableBuy(false);
        //}
      }

  }
  funcDebug('========================================')
}




// *********************************************************************
// **********  CLAIM
// *********************************************************************
const runClaim = claimBal > 0 ? true : false;
funcDebug('runClaim: ' + runClaim);

const { config: configClaim, error: errorClaim } = usePrepareContractWrite({
  chainId: chain?.id,
  address: RouletteAdress,
  abi: RouletteABI,
  functionName: 'sendWinner',
  enabled: runClaim,
  onSuccess(data) {
    funcDebug('runBet: Claim Success', data)
  },
  onError(error) {
    funcDebug('runBet: Claim Error', error)
  },    
})

const { contractWrite: contractWriteClaim , data: dataClaim, isLoading: isLoadingClaim, isSuccess: isSuccessClaim, write: writeClaim } = useContractWrite({
  ...configClaim,
});

const { waitForTransaction : waitForTransactionClaim } = useWaitForTransaction({
  confirmations: 1,
  hash: dataClaim?.hash,
  onSuccess(data) {
    funcDebug('Success 1 confirmations!', data)
    setJackPot();
    checkRoulette();
  },
})





// *********************************************************************
// **********  NFTS FARM HORSES
// *********************************************************************


useEffect(() => {
  async function checkMyNFTs() {

    funcDebug('mynfts start');
    if(signer !== undefined && signer !== null){

        try {

          //setDisableBtnApprove(true);
          const RouletteContract = new ethers.Contract(
            RouletteAdress,
            RouletteABI,
            signer
          );

          const totalNFTS = await RouletteContract.getNftsFarmTotal(currentAccount.address);
          funcDebug('mynfts total: ' + totalNFTS);
          setMyFarmHorses(totalNFTS.toNumber());
        } catch (error) {
          funcDebug('mynfts: ' + error);
          
        }
      
    }
  }
  checkMyNFTs();
}, [signer]);


const claimOwner = () => {
  writeOwner?.();
}

const feePriceBigRefresh = ethers.utils.parseEther('0.002');

const { config: configOwner, error: erroOwner } = usePrepareContractWrite({
    chainId: chain?.id,
    address: FarmHorsesOwnersAdress,
    abi: FarmHorsesOwnersABI,
    functionName: 'claimOwner',
    enabled: true,
    overrides: {
      from: currentAccount.address,
      value: feePriceBigRefresh,
    },      
    onSuccess(data) {
      funcDebug('claimOwner Success', data)
    },
    onError(error) {
      funcDebug('claimOwner Error', error)
    },    
})

const { data: dataOwner, isLoading: isLoadingOwner , isSuccess: isSuccessOwner, write: writeOwner } = useContractWrite(configOwner);





// *********************************************************************
// **********  SPIN
// *********************************************************************

// const gaslimitPlay = 600000; // POLYGON
const gaslimitPlay = 500000; // BSC

useEffect(() => {
  checkRoulette(false);
}, [signer]);


const runBet = myNumber > 0 ? true : false;
const argsSpin = [myNumber > 0 ? [Number(myNumber) + 0] : [0] , myReferral];
//const qtdPriceBigRefresh = ethers.utils.parseEther(priceBet);

// const argsSpin = [myNumber];
funcDebug('myNumber: ' + myNumber)
funcDebug('myReferral: ' + myReferral)
funcDebug('runBetv argsSpin: ' + argsSpin)
funcDebug('runBetv: ('+ myNumber +') ' + runBet);

// PICKWINNER
const { config: configBet, error: errorBet } = usePrepareContractWrite({
  chainId: chain?.id,
  address: RouletteAdress,
  abi: RouletteABI,
  functionName: 'spinWheelRef',
  args: argsSpin,
  overrides:{
    value: priceBet,
    gasLimit: gaslimitPlay,
  },
  enabled: runBet,
  cacheTime: 0,
  onSuccess(data) {
    funcDebug('runBetv: spinWheel Success', data)
    funcDebug('runBetv: spinWheel myNumber', myNumber)
    funcDebug('runBetv: spinWheel myReferral', myReferral)
  },
  onError(error) {
    funcDebug('runBetv: Error', error)
  },
})

// funcDebug('runBetv: ' + JSON.stringify(configBet));

const { contractWrite: contractWriteBet , data: dataBet, isLoading: isLoadingBet, isSuccess: isSuccessBet, write: writeBet } = useContractWrite(configBet);

const waitForTransaction = useWaitForTransaction({
  confirmations: 2,
  hash: dataBet?.hash,
  onSuccess(data) {
    funcDebug('Success 2 confirmations!', data)
    //checkLastRequest();
    setBlockNumber(data.blockNumber)
    setisPlaying(true);
  },
})

/*
async function checkLastRequest(){

  funcDebug('runBet: newlrequest RUN');
  if(signer !== undefined && signer !== null){

    funcDebug('runBet: newlrequest START');

    const apcontract = new ethers.Contract(
      RouletteAdress,
      RouletteABI,
      signer
    );

    try {

        const lrequest = await apcontract.lastRequest(currentAccount.address);
        // DISABLE CHECK LAST
        //setLastRequest(lrequest.id);
        setBlockNumber((lrequest.id).toString());
        funcDebug('runBet: newlrequest ' + lrequest.id);
        setisPlaying(true);


    } catch (error) {
      funcDebug('runBet: newlrequest: ' + error);
    }
    
  }
}
*/


//{(isSuccessBet && typeof(dataBet) !== 'undefined' && dataBet != null) &&
  //setisPlaying(true);
  //funcDebug('runBet: dataBet', dataBet)
//}


/*
React.useEffect(() => {
  const timeout = setTimeout(() => {
      setCountdown(countdown + 1);
      funcDebug('runBet: countdown ' + countdown);
  }, 2000);
  return () => {
      clearTimeout(timeout);
  }
}, [countdown]);
*/



const ButtonNoZcoreClub = () => {
//const ButtonNoZcoreClub = useCallback(event => {
  if(isZcoreClub){
    {/*
    return <>
          <Badge
          disableAnimation={true} 
          style={{
            marginTop: '30px',
          }}
          enableShadow disableOutline color="primary">
          You are a ZCore Club member!
        </Badge>
    </>;
    */}
    return null;
}else{
  return (
    <>
                <GradientButtonPrimary
                          style={{ 
                            width: '100%',
                            marginTop: '30px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',           
                         }}
                          onClick={buyNumber}
                          disabled={disableBuy || isZcoreClub}
                          variant="contained"
                          >
                          <Typography 
                            style={{ textTransform: 'none' }}
                            variant="body1" component="span">                        
                              <b>Spin {ticketPrice} {coinNetwork} {
                              priceBnb > 0 && 
                              `(~${currencyFormat(ticketPrice*priceBnb)})`
                              }</b>
                          </Typography>
                </GradientButtonPrimary>    
    </>    
  );
}
//}, [disableBuy, isZcoreClub, ticketPrice]);
}






const clickClain = () => {
  setDisableClaim(true);
  writeClaim?.()
}


const MyNumberMobile = () => {
  const my = "Your number: " + myNumber
  const dr = "Drawn: " + thiswinner
return (
  <>
  <div>{my}</div> 
  <div>{dr}</div>
  </>
  )
}


const BoxResponse = () => {
  if(Number(thiswinner) > 0){
    if(youWin){
      return (
        <Grid.Container justify="center" gap={2}>
          <Grid alignContent="center">
            <Badge size="xl" enableShadow disableOutline color="success">
              🤑 [{thiswinner}] You WIN!!!
            </Badge>
          </Grid>
        </Grid.Container>
        );      
    }else{
      return (
        <Grid.Container justify="center" gap={2}>
          <Grid alignContent="center" style={{ marginBottom: '20px' }}>
            <Badge size="xl" enableShadow disableOutline color="error">
              🎲 [{thiswinner}] you Lose. Try again!
            </Badge>
          </Grid>
        </Grid.Container>
        );  
    }
  }else{
    return null;
  }
}

const BoxBalance = () => {
return (
  <Grid.Container justify="center" gap={2}>
    <Grid alignContent="center">
        <Text style={{ padding: '0px', margin: '0px' }} color="success">
          <b>Balance: {myBnb.toFixed(4)} {coinNetwork} {
                              priceBnb > 0 && 
                              `(~${currencyFormat(myBnb*priceBnb)})`
                              }</b>
        </Text>
      </Grid>
      <Grid alignContent="center">
        <Button onClick={clickClain} disabled={disableClaim || !runClaim} flat color="success" style={{ fontSize: '18px'}} auto>
          <GiTwoCoins style={{ marginRight: '5px' }}/><b>Claim {claimBal.toFixed(4)} {coinNetwork} {
                              claimBal > 0 && 
                              `(~${currencyFormat(claimBal*priceBnb)})`
                              }</b>
        </Button>
      </Grid>

    </Grid.Container>
  );  
}

const CardRoleta = useCallback(event => {
  if(isMobile){
        return <WheelComponent
                autoPlay={autoPlay}
                ww={320}
                hh={320}
                centerX={160}
                centerY={160}
                numberColor='white'
                numberPrefix='Drawn: '
                yourNumber={myNumber}
                isOnlyOnce={true}
                segments={segments}
                segColors={segColors}
                winningSegment={winner}
                onFinished={(twinner) => onFinished(twinner)}
                primaryColor='black'
                contrastColor='white'
                buttonText='🤑🤑'
                size={230}
                upDuration={200}
                downDuration={2000}
                fontFamily='Poppins'
        />
  }else{
    
    if(windowHeight <= 720){
        return <WheelComponent
                autoPlay={autoPlay}
                ww={470}
                hh={480}
                centerX={210}
                centerY={210}
                numberColor='white'
                numberPrefix='Drawn: '
                yourNumber={myNumber}
                isOnlyOnce={true}
                segments={segments}
                segColors={segColors}
                winningSegment={winner}
                onFinished={(twinner) => onFinished(twinner)}
                primaryColor='black'
                contrastColor='white'
                buttonText='🤑🤑'
                size={200}
                upDuration={200}
                downDuration={2000}
                fontFamily='Poppins'
        />

    }else{

        return <WheelComponent
                autoPlay={autoPlay}
                ww={570}
                hh={600}
                centerX={270}
                centerY={270}
                numberColor='white'
                numberPrefix='Drawn: '
                yourNumber={myNumber}
                isOnlyOnce={true}
                segments={segments}
                segColors={segColors}
                winningSegment={winner}
                onFinished={(twinner) => onFinished(twinner)}
                primaryColor='black'
                contrastColor='white'
                buttonText='🤑🤑'
                size={250}
                upDuration={200}
                downDuration={2000}
                fontFamily='Poppins'
        />
    }
  }        
}, [autoPlay, myNumber, winner, segColors]);




  return (
    <>
      {!isMobile ? (
        <Box sx={{
          mt: 5,          
        }}>
{/*          
          <Box
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              position: 'relative',
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              mt: 11,
              mb: 5,
            }}
          >




            <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} style={{ color: '#ffffff' }} />
              </Box>
              <Typography
                variant="h6"
                component="p"
                color="secondary"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                }}
              >
                ZCoreClub
              </Typography>
            </Box>
*/}          
        
          <Box
            bgcolor={darkMode ? '#171c26' : '#fff2f8'}
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              borderRadius: '16px',
              p: 3,
            }}
          >
            
    <BoxJackpot
        totalPrize={totalPrize}
        coinNetwork={coinNetwork}
        jackpotTotal={jackpotTotal}
        isMobile={isMobile}
        windowHeight={windowHeight}
        />

    <Grid.Container justify="center">
      <Grid alignContent="center" style={{ flexBasis: '0' }} xs={8}>
        <CardRoleta/>
    </Grid>
    <Grid alignContent="center" xs={4}>

    <YouWinBox/>
    
    <Box style={{ 
        display: 'visible',
        width: '100%',
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
    }}>

            <Text 
                css={{
                  marginTop: (windowHeight <= 720 ? (`8px`) : (`0px`)),
                  paddingTop: '0px',
                  textGradient: "45deg, $yellow600 -20%, $red600 100%",
                }}
                h2>
                  {Number(myNumber) > 0 ?
                    (Number(thiswinner) > 0 ?
                      `Your number: ${myNumber} / Drawn: ${thiswinner}`
                      :
                      `Your number: ${myNumber}`
                    )
                    :
                    `Choose your number`
                  }
                </Text>


    <SelectNumbers
        maxNumber={maxNumber}
        darkMode={darkMode}
        handleMyNumber={handleMyNumber}
        disableSelect={disableSelect}
    />      


    <>

    

<ButtonNoZcoreClub/>
<ButtonZcoreClub
    isZcoreClub={isZcoreClub}
    buyNumber={buyNumber}
    disableBuy={disableBuy}
    coinNetwork={coinNetwork} 
    ticketPriceClub={ticketPriceClub}
    priceBnb={priceBnb}
/>

<LoadingBox/>

                <Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />
                <BoxResponse/>
                <BoxBalance/>

                
</>
    




            </Box>
    </Grid>

    





    



<Grid.Container justify="center">
      <Grid alignContent="center" style={{
        padding: '10px',        
      }} xs={6}>


      <LastSpins
    isMobile={isMobile}
    darkMode={darkMode}
    mySpins={mySpins}
/>

  


              </Grid>

              <Grid alignContent="center" style={{
                display: 'inline',
                padding: '10px',
              }} xs={6}>


              <Typography
                sx={{
                  padding: 2,
                  fontSize: 16,
                  textAlign: 'justify',
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
The drawn number is generated through Chainlink VRF oracle for random numbers, with the roulette serving as an entertaining animation, and all processes auditable via smart contracts. Users should approach the roulette game purely for enjoyment, as ZCore Web3Games is a fun platform utilizing cryptocurrencies and tokens, not an investment or financial returns site.<br />
Payments are managed through smart contracts, ensuring that if one user wins in a block, the next user will receive the amount specified in the smart contract, preventing simultaneous wins. ZCoreClub members enjoy a 50% discount on play; please refer to the text below for information on joining the club and enhancing your entertainment experience.
              </Typography>      






</Grid>
</Grid.Container>




</Grid.Container>

<Divider style={{ marginTop: '15px' , marginBottom: '30px' }} className={styles.dividerBox} />

{currentAccount?.address ? (
        <>
        
<Box textAlign="center" alignContent="center">
          <Badge size="xl" enableShadow disableOutline color="success">
              Earn 10% of your referrals' prizes. Share your link now!
            </Badge>
</Box>
{myCustomLink &&
<Box textAlign="center" p={3}>
            <Input
            style={{
              fontWeight: 'bold',
              marginRight: '-15px',
            }}
              onClick={copyLink}
              readOnly 
              size="xl" 
              status="success" 
              value={myCustomLink}
            />
</Box>            
}
          </>          
):(
  <>
  
        
          <Badge size="xl" enableShadow disableOutline color="success">
            Earn 10% of your referrals' prizes. Share your referral link (connect your wallet)
            </Badge>
        
          </>  
)}


<Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />

<Grid.Container gap={2} justify="center">
      <Grid alignContent="center" xs={4}>
        <CardNFT
            writeOwner={writeOwner}
            erroOwner={erroOwner}
            myFarmHorses={myFarmHorses}
            claimOwner={claimOwner}
        />
      </Grid>
      <Grid xs={8}>
      <Box>
              <Typography variant='h6' component='h6' sx={{ my: 2 }}>
                {t('Identify Yourself as a ZCoreClub Member')}
              </Typography>
              <Typography
                variant='body2'
                component='p'
                sx={{
                  lineHeight: 2,
                  textAlign: 'justify',
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
                With the Farm Horses NFTs now available on the Ethereum network, simply clicking the button next to it will claim your authority for the current network. If you have more than one NFT in your wallet, you will automatically be recognized as a member of the ZCoreClub on the site.
                <br /><br />
                If you do not yet have a Farm Horses NFT, don't worry, you can mint one right now by clicking the link below.<br /><br />
                <Link 
                style={{
                    fontSize: '16px'
                }}
                color="success" target="_blank" isExternal href="https://club.zcore.network/">
                    https://club.zcore.network/
                    </Link>
              </Typography>
            </Box>

      </Grid>
</Grid.Container>






          </Box>
        </Box>
      ) : (        
          <Box            
            bgcolor={darkMode ? '#171c26' : '#fff2f8'}
            color={darkMode ? '#ffffff' : '#121212'}
            sx={{
              paddingTop: '5px',
              marginTop: '0px',
              borderRadius: '16px',               
            }}
          >
          <Box
          sx={{
            padding: '0px',
            margin: '0px',
          }}
          >



            
      <BoxJackpot
        totalPrize={totalPrize}
        coinNetwork={coinNetwork}
        jackpotTotal={jackpotTotal}
        isMobile={isMobile}
        windowHeight={windowHeight}
      />

    <Grid.Container
      style={{
        display: 'inline',
      }} 
    justify="center">
      <Grid alignContent="center" style={{ 
        flexBasis: '0',
        display: 'visible',
        width: '100%',
        paddingLeft: '15px',
        paddingRight: '15px',        
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',        
        }}>
        <CardRoleta/>



    <YouWinBox/>
    

    

            <Text 
                css={{
                  marginTop: '0px',
                  paddingTop: '0px',
                  textGradient: "45deg, $yellow600 -20%, $red600 100%",
                }}
                h2>
                  {Number(myNumber) > 0 ?
                    (Number(thiswinner) > 0 ?
                      <MyNumberMobile/>
                      :
                      `Your number: ${myNumber}`
                    )
                    :
                    `Choose your number`
                  }
                </Text>

                <BoxResponse/>

<LoadingBox/>

    <SelectNumbers
        maxNumber={maxNumber}
        darkMode={darkMode}
        handleMyNumber={handleMyNumber}
        disableSelect={disableSelect}
    />      



    <ButtonNoZcoreClub/>
<ButtonZcoreClub
    isZcoreClub={isZcoreClub}
    buyNumber={buyNumber}
    disableBuy={disableBuy}
    coinNetwork={coinNetwork} 
    ticketPriceClub={ticketPriceClub}
    priceBnb={priceBnb}
/>




                <Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />                
                
                <BoxBalance/>


    </Grid>




    <Grid>

    <LastSpins
    isMobile={isMobile}
    darkMode={darkMode}
    mySpins={mySpins}
/>

<Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />

              <Typography
                sx={{
                  padding: 2,
                  fontSize: 16,
                  lineHeight: 1.5,
                  textAlign: 'justify',
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
The drawn number is generated through Chainlink VRF oracle for random numbers, with the roulette serving as an entertaining animation, and all processes auditable via smart contracts. Users should approach the roulette game purely for enjoyment, as ZCore Web3Games is a fun platform utilizing cryptocurrencies and tokens, not an investment or financial returns site.<br />
Payments are managed through smart contracts, ensuring that if one user wins in a block, the next user will receive the amount specified in the smart contract, preventing simultaneous wins. ZCoreClub members enjoy a 50% discount on play; please refer to the text below for information on joining the club and enhancing your entertainment experience.
              </Typography>      
    </Grid>

    {currentAccount?.address ? (
        <>
        <Grid>      
        <Box sx={{
          paddingLeft: '30px',
          paddingRight: '30px',
          textAlign: 'center'
         }} 
         >
          <Text color="warning">
            Earn 10% of your referrals' prizes. Share your referral link:          
          </Text>
          {myCustomLink &&
          <Input
              readOnly 
              onClick={copyLink}
              size="xl" 
              status="success" 
              value={myCustomLink}
            />
            }
            </Box>    
          </Grid>
          </>          
):(
  <>
          <Box textAlign="center" alignContent="center">
            <Text color="warning">
              Earn 10% of your referrals' prizes. Share your referral link (connect your wallet)
            </Text>
            </Box>
          </>  
)}

</Grid.Container>

<Divider style={{ marginTop: '15px' , marginBottom: '15px' }} className={styles.dividerBox} />

<Grid.Container gap={2} justify="center">
      <Grid alignContent="center">
      <CardNFT
            writeOwner={writeOwner}
            erroOwner={erroOwner}
            myFarmHorses={myFarmHorses}
            claimOwner={claimOwner}
        />
      </Grid>
      <Grid>
      <Box>
              <Typography variant='h6' component='h6' sx={{ my: 2 }}>
                {t('Identify Yourself as a ZCoreClub Member')}
              </Typography>
              <Typography
                variant='body2'
                component='p'
                sx={{
                  lineHeight: 2,
                  textAlign: 'justify',
                  color: `${darkMode ? '#c4c4c4' : 'GrayText'}`,
                }}
              >
                With the Farm Horses NFTs now available on the Ethereum network, simply clicking the button next to it will claim your authority for the current network. If you have more than one NFT in your wallet, you will automatically be recognized as a member of the ZCoreClub on the site.
                <br /><br />
                If you do not yet have a Farm Horses NFT, don't worry, you can mint one right now by clicking the link below.<br /><br />
                <Link 
                style={{
                    fontSize: '16px'
                }}
                color="success" target="_blank" isExternal href="https://club.zcore.network/">
                    https://club.zcore.network/
                    </Link>
              </Typography>
            </Box>

      </Grid>


    </Grid.Container>

            </Box>
          </Box>
      )}
    </>
  );
};

export default ZCoreClub;