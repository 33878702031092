import React from 'react';
import { Box } from '@mui/system';

import BackgroundWrinkles1 from '../../../assets/BackgroundWrinkles1.svg';
import BackgroundWrinklesLight from '../../../assets/backgroundWrinklesLight.svg';
import Backdrop from '../../../assets/exploreBackDropCircle.svg';
import { Grid } from '@mui/material';
import { CollectionData } from '../CollectionData';
import CollectionCard from '../CollectionCard/CollectionCard';

const CollectionContainer = ({ darkMode }) => {
  return (
    <Box>
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt='Wrinkles'
        />
      </Box>
      <div style={{ position: 'fixed', left: '25%' }}>
        {darkMode && <img src={Backdrop} alt='Bakcdrop' />}
      </div>
      <Box mt={8}>
        <Grid
          container
          spacing={{ xs: 4, md: 6 }}
          columns={{ xs: 1, sm: 12, md: 12 }}
        >
          <>
            {CollectionData.map((collection) => (
              <CollectionCard
                darkMode={darkMode}
                key={collection.id}
                id={collection.id}
                collection={collection}
              />
            ))}
          </>
        </Grid>
      </Box>
    </Box>
  );
};

export default CollectionContainer;
