import React, { useEffect, useState } from 'react';
import {
  Typography,
  Avatar,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import axios from "axios";
import SellerDetailsCard from "../../components/SellerDetailsCard/SellerDetailsCard";
import ArtCardFB from "../../components/Skeletons/ArtCardFB";
import CollectionCardFB from "../../components/Skeletons/CollectionCardFB";

import Backdrop from "../../assets/exploreBackDropCircle.svg";

import BackgroundWrinkles1 from "../../assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "../../assets/backgroundWrinklesLight.svg";
import AddIcon from "@mui/icons-material/Add";
import ProfileCover from "../../assets/userProfileCover.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CreateCollection from "../../components/CreateCollection/CreateCollection";
import CollectionCard from "../Collections/CollectionCard/CollectionCard";
import Loader from "../../components/Loader/Loader";
// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DummyUserProfile = ({ darkMode }) => {
  const { t } = useTranslation();

  const [sellerData, setSellerData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setLoaderMessage("Loading...");
      const res = await axios.get("/sellerData.json");
      const sellerCardData = res.data;
      setSellerData(sellerCardData);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Modal
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // This will be deleted when the API endpoint added
  const filteredSeller = sellerData.filter((sd) => sd.id === '1');

  const MobileTabs = styled(Tabs)({
    border: 'none',
    backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
    '& .MuiTabs-indicator': {
      backgroundColor: 'inherit',
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? '#ffffff' : '#040404'}`,
    backgroundColor: `${darkMode ? '#171c26' : '#fff2f8'}`,
    borderRadius: '4px',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#01D4FA',
      borderRadius: '4px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {"My Profile"}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt='Wrinkles'
        />
      </Box>
      {!isMobile ? (
        <Box zIndex={100} color={darkMode ? '#ffffff' : '#121212'}>
          <Box
            bgcolor={darkMode ? '#121212' : '#fff2f8'}
            sx={{
              borderRadius: '10px',
            }}
          >
            {filteredSeller.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${
                    darkMode ? "#040404" : "#e3dcdc"
                  } 100%), url(${ProfileCover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: 5,
                  borderRadius: '10px',
                }}
              >
                <Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Avatar
                      src={fs.sellerImage}
                      alt={fs.sellerName}
                      sx={{ border: '2px solid #fff', width: 120, height: 120 }}
                    />
                  </Box>
                  <Stack
                    mt={2}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      gap={1}
                    >
                      <Typography color="secondary" variant="h5">
                        {fs.sellerName}
                      </Typography>
                      <Typography color="secondary" variant="h5">
                        ({fs.sellerUserName})
                      </Typography>
                    </Stack>
                    <Typography
                      color="secondary"
                      mt={1}
                      variant='body2'
                      textAlign='center'
                      lineHeight={2}
                    >
                      {' '}
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              bgcolor={darkMode ? '#171C26' : '#fff2f8'}
              sx={{
                borderRadius: "10px",

                mb: 4,
              }}
            >
              <Tabs
                textColor='secondary'
                indicatorColor='secondary'
                value={tabValue}
                onChange={handleChange}
                centered
              >
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                    zIndex: "601",
                  }}
                  label={`${t("COLLECTIONS")} (${filteredSeller.map(
                    (fs) => fs.sellerItems?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                    zIndex: "601",
                  }}
                  label={`${t('CREATED')} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'createdItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                    zIndex: "601",
                  }}
                  label={`${t('OWNED')} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'ownedItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                    zIndex: "601",
                  }}
                  label={`${t('IN_AUCTION')} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'auctionItems')
                        ?.length
                  )})`}
                />
                <Tab
                  sx={{
                    color: 'gray',
                    textTransform: 'capitalize',
                    display: 'block',
                    mx: 5,
                    zIndex: "601",
                  }}
                  label={`${t("IN_SALE")} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === 'inSale')?.length
                  )})`}
                />
              </Tabs>
            </Box>

            <Box sx={{ mx: -3, mt: -2 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Stack mb={3} direction="row" justifyContent="flex-end">
                  <Button
                    onClick={handleOpenModal}
                    sx={{ py: 1.5, px: 5, zIndex: "12" }}
                    variant="contained"
                    color="accent"
                    startIcon={<AddIcon />}
                  >
                    {t("CREATE")}
                  </Button>
                </Stack>
                <Grid
                  container
                  spacing={{ xs: 2, md: 6 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems.map((sdc) => (
                          <CollectionCard
                            darkMode={darkMode}
                            key={sdc.id}
                            mode={"new"}
                            id={sdc.id}
                            sellerCollection={sdc}
                          />
                        ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === "createdItems")
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === "ownedItems")
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === "auctionItems")
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === "inSale")
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ position: "relative", overflowX: "hidden", mt: -10 }}
          color={darkMode ? "#ffffff" : "#121212"}
        >
          <Box
            bgcolor={darkMode ? "#121212" : "#fff2f8"}
            sx={{
              borderRadius: "20px",
              p: 1,
            }}
          >
            {filteredSeller.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${
                    darkMode ? "#040404" : "#e3dcdc"
                  } 100%), url(${ProfileCover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: 2,
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar
                      src={fs.sellerImage}
                      alt={fs.sellerName}
                      sx={{ border: "2px solid #fff", width: 150, height: 150 }}
                    />
                  </Box>
                  <Stack
                    mt={2}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <Typography variant="h5" color="secondary">
                        {fs.sellerName}
                      </Typography>
                      <Typography variant="h5" color="secondary">
                        {`(${fs.sellerUserName})`}
                      </Typography>
                    </Stack>
                    <Typography mt={1} textAlign="justify" variant="caption">
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: "10px",
                mt: 3,
                mb: 4,
              }}
            >
              <MobileTabs
                variant="scrollable"
                textColor="secondary"
                indicatorColor="secondary"
                value={tabValue}
                onChange={handleChange}
                centered
              >
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mr: 2,
                  }}
                  label={`${t("COLLECTIONS")} (${filteredSeller.map(
                    (fs) => fs.sellerItems?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mr: 2,
                  }}
                  label={`${t("CREATED")} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === "createdItems")
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mr: 2,
                  }}
                  label={`${t("OWNED")} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === "ownedItems")
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mr: 2,
                  }}
                  label={`${t("IN_AUCTION")} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === "auctionItems")
                        ?.length
                  )})`}
                />
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                  }}
                  label={`${t("IN_SALE")} (${filteredSeller.map(
                    (fs) =>
                      fs.sellerItems.filter((si) => si.tag === "inSale")?.length
                  )})`}
                />
              </MobileTabs>
            </Box>
            <Box sx={{ mx: -3 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Stack mb={3} direction="row" justifyContent="flex-end">
                  <Button
                    onClick={handleOpenModal}
                    sx={{ py: 1.5, px: 5 }}
                    variant="contained"
                    color="accent"
                    startIcon={<AddIcon />}
                  >
                    Create
                  </Button>
                </Stack>
                <Grid
                  container
                  spacing={{ xs: 2, md: 6 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems.map((sdc) => (
                          <CollectionCard
                            darkMode={darkMode}
                            key={sdc.id}
                            sellerCollection={sdc}
                          />
                        ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt="Bakcdrop" />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === "createdItems")
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'ownedItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'auctionItems')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} darkMode={darkMode} index={4}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >
                  <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 8 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => <ArtCardFB key={n} />)
                  ) : (
                    <>
                      {filteredSeller.map((fs) =>
                        fs.sellerItems
                          .filter((si) => si.tag === 'inSale')
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                            />
                          ))
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
      <CreateCollection
        open={openModal}
        handleClose={handleCloseModal}
        darkMode={darkMode}
      />
    </Box>
  );
};

export default DummyUserProfile;
