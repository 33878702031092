//import { ethers } from "ethers";
import Web3 from "web3";
import { factoryAddress, wbnb, busd, MultiCallAddressBSC, jsonRpcURLPrice, MultiCallAddress , jsonRpcURL , CreateLotteryAddress , fee_zcore, tokensList, thisNetwork } from "./contracts";
import Multicall from '@dopex-io/web3-multicall';
import funcDebug from "./debug";
import factoryABI from "./abi/factoryABI";
import pairABI from "./abi/pairABI";



const getTokenPrice = async (tokenAddress) => {
  try {

    const chainID = localStorage.getItem('getNetwork');
    if(chainID == null || chainID == undefined){
      chainID = thisNetwork;
    }

    const agora = Math.floor(Date.now() / 1000);
    let lastUpdate = localStorage.getItem(chainID + '_lastUpdatePRICE'+tokenAddress);
    let atualiza = true;
    funcDebug('priceToken: lastUpdatePrice: agora: '+ agora);
    funcDebug('priceToken: lastUpdatePrice: lastUpdate: '+ lastUpdate);

    
    if(lastUpdate < agora){
        // ATUALIZA
        localStorage.setItem(chainID + '_lastUpdatePRICE'+tokenAddress, (agora + (60 * 60))); // 1 HORA
        funcDebug('priceToken: lastUpdatePrice: ATUALIZA');
        atualiza = true;
    }else{
        // NÃO ATUALIZA
        funcDebug('priceToken: lastUpdatePrice: NÃO ATUALIZA');
        atualiza = false;
    }

    let responsePrice;

    if(atualiza){    


      let allCalls = [];

      funcDebug('priceToken: ========================================')
      funcDebug('priceToken: ' + tokenAddress)
      funcDebug('priceToken: ' + thisNetwork)
      funcDebug('priceToken: ========================================')

      //const jsonRpcURLPrice = 'https://bsc-dataseed.binance.org/';
      // const MultiCallAddressBSC = '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb';
      //const web3 = new Web3(jsonRpcURL)
      const web3 = new Web3(jsonRpcURLPrice)
    
      //const factoryAddress = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
      //const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
      //const busd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'    


      /*
      const multicall = new Multicall({
        multicallAddress: MultiCallAddressBSC,
        provider: jsonRpcURLPrice,
      });
      const lastBlockHash = multicall.getLastBlockHash();
      funcDebug('priceToken: ' + JSON.stringify(lastBlockHash))
      */

      const factory = new web3.eth.Contract(factoryABI, factoryAddress)
      const pairAddress = await factory.methods.getPair(wbnb, tokenAddress).call()
      const pair = new web3.eth.Contract(pairABI, pairAddress) 
      
      const pairBnbusdAddress = await factory.methods.getPair(wbnb, busd).call()
      const pairBnbusdContract = new web3.eth.Contract(pairABI, pairBnbusdAddress)


      ////allCalls.push(pairBnbusdContract.methods.getReserves())
      //allCalls.push(pair.methods.token0())
      //allCalls.push(pair.methods.token1())
      //allCalls.push(pair.methods.getReserves())
      //const multicalldata = await multicall.aggregate(allCalls);
      
      const token0_bnb = await pairBnbusdContract.methods.token0().call()
      const token1_bnb = await pairBnbusdContract.methods.token1().call()
      const getReservesBnb = await pairBnbusdContract.methods.getReserves().call()
      //const getReserves = multicalldata[0]
      let bnbPrice;
      if(thisNetwork == 11155111 || thisNetwork == 137){
        bnbPrice = ((getReservesBnb._reserve0/1e18) / (getReservesBnb._reserve1/1e6));
      }else if(thisNetwork == 80001){
          bnbPrice = ((getReservesBnb._reserve0/1e18) / (getReservesBnb._reserve1/1e6));
      }else{
        bnbPrice = (getReservesBnb._reserve1/getReservesBnb._reserve0);
      }


      const token0 = await pair.methods.token0().call()
      const token1 = await pair.methods.token1().call()
      const getReservesToken = await pair.methods.getReserves().call()
      //const token0 = multicalldata[1]
      //const token1 = multicalldata[2]
      //const getReservesToken = multicalldata[3]
      const priceTokeninBNB = token0 == wbnb ? getReservesToken._reserve0 / getReservesToken._reserve1 : getReservesToken._reserve1 / getReservesToken._reserve0;


      funcDebug('priceToken: ========================================')
      funcDebug('priceToken: getReservesBnb ' + pairBnbusdAddress)
      funcDebug('priceToken: getReservesBnb ' + getReservesBnb._reserve0)
      funcDebug('priceToken: getReservesBnb ' + getReservesBnb._reserve1)
      funcDebug('priceToken: getReservesBnb ' + token0_bnb)
      funcDebug('priceToken: getReservesBnb ' + token1_bnb)
      funcDebug('priceToken: ========================================')
      funcDebug('priceToken: getReservesToken ' + getReservesToken._reserve0)
      funcDebug('priceToken: getReservesToken ' + getReservesToken._reserve1)
      funcDebug('priceToken: getReservesToken ' + token0)
      funcDebug('priceToken: getReservesToken ' + token1)
      funcDebug('priceToken: ' + priceTokeninBNB)
      funcDebug('priceToken: ' + priceTokeninBNB * bnbPrice)
      funcDebug('priceToken :========================================') 


      const result = priceTokeninBNB * bnbPrice;      
      responsePrice = {
                token: result,
                bnb: bnbPrice,
            };
      if(bnbPrice > 0){
        localStorage.setItem(chainID + '_responsePrice'+tokenAddress, JSON.stringify(responsePrice));
      }else{
        localStorage.setItem(chainID + '_lastUpdatePRICE'+tokenAddress, 0);
      }      
      return responsePrice;


    }else{
      responsePrice = JSON.parse(localStorage.getItem(chainID + '_responsePrice'+tokenAddress));
      return responsePrice;
    }

  } catch (error) {
      console.error('priceToken: '+ error)
  }

  
}

export default getTokenPrice