import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Grow,
  useMediaQuery,
  IconButton,
  Zoom,
  MenuItem,
  Skeleton,
} from "@mui/material";
import CountDownBoard from "../../components/CountDownBoard/CountDownBoard";

import { AiOutlineHeart } from "react-icons/ai";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteFilledIcon from "@mui/icons-material/Favorite";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

import { add } from "date-fns";

// Styles
import styles from "./BidCard.module.css";
import { GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";
import { FaThumbsUp } from "react-icons/fa";
import { KeyboardArrowDown } from "@mui/icons-material";
import { StyledMenu } from "../../components/StyledMenu/StyledMenu";
import AddToCollectionModal from "../../components/SellerDetailsOwnedModals/AddToCollectionModal";

import DeleteFromCollection from "../../components/SellerDetailsOwnedModals/DeleteFromCollection";
import RemoveFromCollection from "../../components/SellerDetailsOwnedModals/RemoveFromCollection";

import { useNavigate } from "react-router-dom";

// This should come from the backend API
const futureDate = add(new Date(), {
  days: 7,
  hours: 12,
  minutes: 21,
});

const BidCard = ({
  nftDetails,
  darkMode,
  mode,
  collectionid,
  trending,
  bidAmount,
  bidStatus,
  bidTxn,
}) => {
  const {
    artworkImage,
    artworkTitle,
    artworkDetails,
    artworkPrice,
    creator,
    creatorImage,
    owner,
    ownerImage,
    uploaded,
    likes,
    currentBid,
    highestBid,
    auctionStatus,
    basePrice,
    boughtAt,
  } = nftDetails;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [formOfSale, setFormOfSale] = useState(nftDetails?.formOfSale);

  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const myProfile = JSON.parse(localStorage.getItem("userProfile"));
  const snackClose = () => {
    setSnackOpen(false);
  };

  const [isLiked, setIsLiked] = useState(nftDetails?.isLiked);
  const [totalLikes, setTotalLikes] = useState(nftDetails?.totalLikes);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const getStatusColor = (status) => {
    if (status == "BID") {
      return "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)";
    } else if (status == "ALLOTED") {
      return "linear-gradient(91.95deg, #21D78A 1.75%, #5AFEAF 98.13%)";
    } else if (status == "REFUNDED") {
      return "linear-gradient(91.95deg, #E15858 1.75%, #FE5A5A 98.13%);";
    }
  };

  // Add to collection modal
  const [openAddToCollectionModal, setOpenAddToCollectionModal] =
    useState(false);

  // Delete from collection modal
  const [openDeleteFromCollectionModal, setOpenDeleteFromCollectionModal] =
    useState(false);

  const [openStopSaleModal, setOpenStopSaleModal] = useState(false);

  // Remove from collection modal
  const [openRemoveFromCollectionModal, setOpenRemoveFromCollectionModal] =
    useState(false);

  const [openBurnNftModal, setOpenBurnNftModal] = useState(false);

  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigationHandler = (id) => {
    navigate(`/raffle/${id}`);
  };

  return (
    <>
      <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
        <Grid
          className={styles.sellerCard}
          item
          sm={trending ? 12 : 6}
          xs={trending ? 12 : 1}
          md={trending ? 12 : 4}
        >
          <div className={styles.sellerCard}>
            <Box
              className={styles.sellerCardGradient}
              sx={{ padding: "1px", borderRadius: "20px" }}
            >
              <Box
                bgcolor={darkMode ? "#121212" : "#ffffff"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  padding: "1rem",
                  zIndex: "10",

                  position: "relative",
                  boxShadow: `${
                    !darkMode && "0px 4px 4px rgba(0, 0, 0, 0.25)"
                  }`,
                }}
              >
                <Box style={{ position: "relative" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "250px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onLoad={() => setLoading(false)}
                    src={artworkImage}
                    alt={artworkTitle}
                    hidden={loading}
                    onClick={() => {
                      navigationHandler(nftDetails.id);
                    }}
                  ></img>
                  {trending ? (
                    <>
                      {loading ? (
                        <Skeleton
                          sx={{
                            backgroundColor: `${
                              darkMode ? "#171C26" : "#fff2f8"
                            }`,
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                          animation="wave"
                          variant="rectangular"
                          style={{
                            width: "100%",
                            height: "250px",
                            borderRadius: "10px",
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {loading ? (
                        <Skeleton
                          sx={{
                            backgroundColor: `${
                              darkMode ? "#171C26" : "#fff2f8"
                            }`,
                          }}
                          animation="wave"
                          variant="rectangular"
                          style={{
                            width: "100%",
                            height: "250px",
                            borderRadius: "10px",
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </Box>
                <Box sx={{ mt: 3 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        color="secondary.main"
                        mb={1}
                        onClick={() => {
                          navigationHandler(nftDetails.id);
                        }}
                      >
                        {artworkTitle}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        margin: "auto 0px",
                        cursor: "pointer",
                      }}
                    >
                      <a alt="check" target="_blank">
                        <Typography
                          variant="caption"
                          component="p"
                          color="secondary.main"
                          mb={1}
                          sx={{
                            background: `${getStatusColor(
                              auctionStatus === "live"
                                ? "BID"
                                : nftDetails.id % 2 === 0
                                ? "ALLOTED"
                                : "REFUNDED"
                            )}`,
                            borderRadius: "4px",
                            padding: "0px 10px",
                          }}
                        >
                          {auctionStatus === "live"
                            ? "BID"
                            : nftDetails.id % 2 === 0
                            ? "ALLOTED"
                            : "REFUNDED"}
                        </Typography>
                      </a>
                    </Box>
                  </div>

                  <>
                    {auctionStatus === "live" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="gray"
                          >
                            {t("BASE_PRICE")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="p"
                            color="secondary.main"
                            mb={2}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigationHandler(nftDetails.id);
                            }}
                          >
                            {basePrice}
                          </Typography>
                        </Box>
                        <Box textAlign="right">
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="gray"
                          >
                            {t("BID_PRICE")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="p"
                            color="secondary.main"
                            mb={2}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigationHandler(nftDetails.id);
                            }}
                          >
                            {currentBid}
                          </Typography>
                        </Box>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="gray"
                          >
                            {t("BASE_PRICE")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="p"
                            color="secondary.main"
                            mb={2}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigationHandler(nftDetails.id);
                            }}
                          >
                            {basePrice}
                          </Typography>
                        </Box>
                        <Box textAlign="right">
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="gray"
                          >
                            {t("BOUGHT_AT")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="p"
                            color="secondary.main"
                            mb={2}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigationHandler(nftDetails.id);
                            }}
                          >
                            {boughtAt}
                          </Typography>
                        </Box>
                      </div>
                    )}
                  </>

                  <Divider
                    style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Box>
                        <Avatar
                          sx={{
                            color: "#fff !important",
                          }}
                          src={creatorImage}
                          alt={creator}
                        />
                      </Box>
                      <Stack direction="column" alignItems="left">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("CREATOR")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "120px",
                          }}
                          noWrap={true}
                        >
                          {creator}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Box>
                        <Avatar
                          sx={{
                            color: "#fff !important",
                          }}
                          src={ownerImage}
                          alt={owner}
                        />
                      </Box>
                      <Stack direction="column" alignItems="left">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("OWNER")}
                        </Typography>
                        <Typography
                          noWrap={true}
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "120px",
                          }}
                        >
                          {owner}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <AddToCollectionModal
              open={openAddToCollectionModal}
              handleClose={() => setOpenAddToCollectionModal(false)}
              darkMode={darkMode}
            />
            <DeleteFromCollection
              open={openDeleteFromCollectionModal}
              handleClose={() => setOpenDeleteFromCollectionModal(false)}
              darkMode={darkMode}
            />
            <RemoveFromCollection
              open={openRemoveFromCollectionModal}
              handleClose={() => setOpenRemoveFromCollectionModal(false)}
              darkMode={darkMode}
            />
          </div>
        </Grid>
      </Grow>
    </>
  );
};

export default BidCard;
