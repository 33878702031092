import React, { useEffect } from "react";

import anime from "animejs";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { Stack } from "@mui/material";
import loading from "../../assets/loading.svg";

const Loader = ({ isLoading, message, darkMode }) => {
  useEffect(() => {
    var p = document.getElementById("metamask-icon");
    if (p) {
      var pathEls = p.querySelectorAll("path");
      for (var i = 0; i < pathEls.length; i++) {
        var pathEl = pathEls[i];
        var offset = anime.setDashoffset(pathEl);
        pathEl.setAttribute("stroke-dashoffset", offset);
        anime({
          targets: pathEl,
          strokeDashoffset: [offset, 0],
          duration: anime.random(1000, 3000),
          delay: 1,
          loop: true,
          direction: "alternate",
          easing: "easeInOutSine",
          autoplay: true,
        });
      }
    }
  });

  return (
    <div>
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            zIndex: "1000000000000000",
            backgroundColor: "rgba(0, 0, 0, 1)",
          }}
        >
          <Stack alignItems="center" direction="column">
            <Box
              sx={{
                textAlign: "center",
              }}
            >
    <div>
        <img id="image0" width="221" height="221" src={loading} />
    </div>              
            </Box>
            <Box>
              <span
                style={{
                  color: darkMode ? "white" : "#171c26",
                  textAlign: "center",
                }}
              >
                {message}
              </span>
            </Box>
          </Stack>
        </Backdrop>
      )}
    </div>
  );
};

export default Loader;
