import { useState } from "react";
import { useEffect } from "react";


// Material UI
import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";

// React Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
//check
// Helmet
import Helmet from "react-helmet";

// Modules
import IndividualNft from "./Pages/Collections/Individualnft";
import MyBidsContainer from "./Pages/MyBids/MyBidsContainer";
import ArtCardDetails from "./components/ArtCardDetails/ArtCardDetails";
import Layout from "./components/Layout/Layout";
import useCustomTheme from "./hooks/useCustomTheme";
import Auction from "./Pages/Auction/Auction";
import Explore from "./Pages/Explore/Explore";
import Favourites from "./Pages/Favourites/Favourites";
import TrendingSellers from "./Pages/TrendingSellers/TrendingSellers";
import Home from "./Pages/Home/Home";
import SellersDetails from "./Pages/SellerDetails/SellersDetails";
import UserProfile from "./Pages/UserProfile/UserProfile";
import TrendingCreators from "./Pages/TrendingCreators/TrendingCreators";
import ProfileInterface from "./components/ProfileInterface/ProfileInterface";
import KYCInterface from "./components/KYCInterface/KYCInterface";
import LanguageInterface from "./components/LanguageInterface/LanguageInterface";
import ThemeInterface from "./components/ThemeInterface/ThemeInterface";
import EditProfile from "./components/ProfileInterface/EditProfile";
import KYCPending from "./components/KYCInterface/KYCPending";
import KYCApproved from "./components/KYCInterface/KYCApproved";
import TermsAndCondition from "./Pages/Terms&Condition/TermsAndCondition";
import FAQ from "./Pages/FAQ/FAQ";
import ZCoreClub from "./Pages/ZCoreClub/ZCoreClub";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CreateAssets from "./Pages/CreateAssets/CreateAssets";
import DummyUserProfile from "./Pages/DummyUser/DummyUserProfile";
import AuctionCardDetails from "./components/AuctionCardDetails/AuctionCardDetails";
import CreatorsDetails from "./Pages/CreatorsDetails/CreatorsDetails";
import AnimatedLoader from "./Utils/AnimatedLoader/AnimatedLoader";

// CSS Styles
import "./App.css";
import SignUpPage from "./Pages/Authentication/SignUpPage/SignUpPage";
import AuthInterface from "./Pages/Authentication/AuthInterface";
import SignInPage from "./Pages/Authentication/SignInPage/SignInPage";
import VerifyEmailPage from "./Pages/Authentication/VerifyEmailPage/VerifyEmailPage";
import SetPasswordPage from "./Pages/Authentication/SetPasswordPage/SetPasswordPage";
import AuthProvider from "./Contexts/AuthProvider";
import ChangePasswordInterface from "./components/ChangePasswordInterface/ChangePasswordInterface";
import ChangeEmailInterface from "./components/ChangeEmailInterface/ChangeEmailInterface";
import Collections from "./Pages/Collections/Collections";
import { ToastContainer } from "react-toastify";
import { useAccount, useNetwork } from 'wagmi'
import getTokenPrice  from "./contract/getprice";
import { thisNetwork, tokensList} from "./contract/contracts";
import funcDebug from "./contract/debug";
import { getNetwork } from '@wagmi/core'
import { useSwitchNetwork } from 'wagmi'





function App() {

  const { address, isConnecting, isDisconnected } = useAccount()
  const { chain } = getNetwork()
  if(chain){
    localStorage.setItem('getNetwork', chain.id)
  }else{
    localStorage.setItem('getNetwork', thisNetwork)
  }
  const { chains, error, isLoading, pendingChainId, switchNetwork } =  useSwitchNetwork()
  funcDebug('switchNetwork: ===========================');
  funcDebug('switchNetwork: ' + thisNetwork);
  funcDebug('switchNetwork: ' + chain?.id);
  funcDebug('switchNetwork: ' + chains.length);
  funcDebug('switchNetwork: ===========================');
  useEffect(() => {
  funcDebug('switchNetwork: ' + thisNetwork);
    if(Number(thisNetwork) !== Number(chain?.id) && chain){      
      //window.location.reload()
    }
  }, [chains, thisNetwork, chain]);

  if(process.env.NODE_ENV === 'development'){
    console.log('------>>> ' + JSON.stringify(process.env))
  }
  
  

  //const [searchParams, setSearchParams] = useSearchParams();
  //const referral = searchParams.get("u");
  //funcDebug('referral: ' + referral);

  useEffect(() => {
    funcDebug('currentAddress: ' + address)
    funcDebug('currentAddress: ' + isConnecting)
    funcDebug('currentAddress: ' + isDisconnected)
    const lastaddress = localStorage.getItem('lastaddress');
    if(lastaddress != address && address!== undefined){
      localStorage.setItem("lastaddress", address);
      funcDebug('currentAddress: RELOAD')
      window.location.reload();
    } 
  }, [address, isConnecting, isDisconnected]);


  useEffect(() => {
    async function getPrice(){
      const npriceToken = await getTokenPrice(tokensList[0].contract_price);
    }
    getPrice();
  }, []);


  // funcDebug('chain: chain ' + JSON.stringify(chain) )
  
  /*
  useEffect(() => {
    try {
      const oldChain = localStorage.getItem('getNetwork');
      funcDebug('chain oldChain: ' + oldChain)
      localStorage.setItem('getNetwork', chain.id)
      if(Number(chain.id) !== Number(oldChain)){
        //localStorage.clear();
        window.location.reload();
      }
    } catch (e) { 
      console.error('chain: ' + e);   
    }    
  }, [chain]);
*/


  const [darkMode, setDarkMode] = useState(true);
  const { customTheme } = useCustomTheme(darkMode);

  const [showApp, setShowApp] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('isNftDB') === null){
      localStorage.setItem('isNftDB', 'true')
    }    
    const appLoader = setTimeout(() => setShowApp(true), 2000);
    return () => clearTimeout(appLoader);
  }, []);

  const handleDarkThemeSwitch = () => {
    localStorage.setItem("theme", "dark");
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    }
  };

  const handleLightThemeSwitch = () => {
    localStorage.setItem("theme", "light");
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  };

  useEffect(() => {
    //const theme = localStorage.getItem("theme");
    const theme = "dark";

    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  }, [customTheme]);






  return (
    <AuthProvider>
      <ThemeProvider theme={customTheme}>
        <Helmet
          bodyAttributes={
            darkMode
              ? { style: "background-color: #040404" }
              : { style: "background-color: #ffffff" }
          }
        />

        <div
          style={{
            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
            height: "100vh",
          }}
        >
          {showApp ? (
            <Box bgcolor={darkMode ? "#040404" : "#ffffff"}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Layout darkMode={darkMode}>
                        <Auction 
                        darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <Layout darkMode={darkMode}>
                        <Home darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/mybids"
                    element={
                      <Layout darkMode={darkMode}>
                        <MyBidsContainer darkMode={darkMode} />
                      </Layout>
                    }
                  />

                  <Route
                    path="/authentication"
                    element={<AuthInterface darkMode={darkMode} />}
                  >
                    <Route
                      path="sign-up"
                      element={<SignUpPage darkMode={darkMode} />}
                    />
                    <Route
                      path="sign-in"
                      element={<SignInPage darkMode={darkMode} />}
                    />
                    <Route
                      path="verify-email"
                      element={<VerifyEmailPage darkMode={darkMode} />}
                    />
                    <Route
                      path="set-password"
                      element={<SetPasswordPage darkMode={darkMode} />}
                    />
                  </Route>
                  <Route
                    path="/explore"
                    element={
                      <Layout darkMode={darkMode}>
                        <Explore darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/raffle"
                    element={
                      <Layout darkMode={darkMode}>
                        <Auction 
                        darkMode={darkMode} 
                        />
                      </Layout>
                    }
                  />
                  <Route
                    path="/collections"
                    element={
                      <Layout darkMode={darkMode}>
                        <Collections darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/explore/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <ArtCardDetails darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/raffle/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <AuctionCardDetails darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/favourites"
                    element={
                      <Layout darkMode={darkMode}>
                        <Favourites darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/trending-creators/:uid/collection/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/trending-sellers/:uid/collection/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/user/dummy/collection/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <IndividualNft darkMode={darkMode} mode="INUSER" />
                      </Layout>
                    }
                  />
                  <Route
                    path="/collections/collection/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/trending-sellers"
                    element={
                      <Layout darkMode={darkMode}>
                        <TrendingSellers darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/trending-sellers/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <SellersDetails darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/user/dummy"
                    element={
                      <Layout darkMode={darkMode}>
                        <DummyUserProfile darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/create-asset"
                    element={
                      <Layout darkMode={darkMode}>
                        <CreateAssets darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Layout darkMode={darkMode}>
                        <UserProfile darkMode={darkMode} />
                      </Layout>
                    }
                  >
                    <Route
                      path="user-profile"
                      element={<ProfileInterface darkMode={darkMode} />}
                    />
                    <Route
                      path="edit-profile"
                      element={<EditProfile darkMode={darkMode} />}
                    />
                    <Route
                      path="kyc"
                      element={<KYCInterface darkMode={darkMode} />}
                    />
                    <Route
                      path="kyc-pending"
                      element={<KYCPending darkMode={darkMode} />}
                    />
                    <Route
                      path="kyc-approved"
                      element={<KYCApproved darkMode={darkMode} />}
                    />
                    <Route
                      path="language"
                      element={<LanguageInterface darkMode={darkMode} />}
                    />
                    <Route
                      path="change-password"
                      element={<ChangePasswordInterface darkMode={darkMode} />}
                    />
                    <Route
                      path="change-email"
                      element={<ChangeEmailInterface darkMode={darkMode} />}
                    />
                    <Route
                      path="theme"
                      element={
                        <ThemeInterface
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                          handleDarkThemeSwitch={handleDarkThemeSwitch}
                          handleLightThemeSwitch={handleLightThemeSwitch}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="/trending-creators"
                    element={
                      <Layout darkMode={darkMode}>
                        <TrendingCreators darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/trending-creators/:id"
                    element={
                      <Layout darkMode={darkMode}>
                        <CreatorsDetails darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={
                      <Layout darkMode={darkMode}>
                        <PrivacyPolicy darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/terms-and-condition"
                    element={
                      <Layout darkMode={darkMode}>
                        <TermsAndCondition darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/zcoreclub"
                    element={
                      <Layout 
                        zcoreclub={true}
                        pyyy={5}
                        darkMode={darkMode}
                      >
                        <ZCoreClub darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/frequently-asked-questions"
                    element={
                      <Layout darkMode={darkMode}>
                        <FAQ darkMode={darkMode} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/contact-us"
                    element={
                      <Layout darkMode={darkMode}>
                        <ContactUs darkMode={darkMode} />
                      </Layout>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <AnimatedLoader />
            </div>
          )}
        </div>      

        <ToastContainer        
          theme="dark"
        />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
