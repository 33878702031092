import React from "react";

// Material UI components
import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import {
  BsFillStarFill,
  BsLightbulbFill,
  BsFillBookmarkFill,
  BsGearFill,
  BsTwitch,
} from "react-icons/bs";
import { HiCubeTransparent } from "react-icons/hi";
import { MdGavel } from "react-icons/md";
import { CgMenuRight } from "react-icons/cg";
import { FiPackage } from "react-icons/fi";

// Main logo
import MainLogo from "../../assets/mainLogo.png";
import MainLogoLight from "../../assets/mainLogoLight.png";

import { useLocation, useNavigate } from "react-router-dom";

// Side Navigation Shadow
import SideShadow from "../../assets/sideNavigationShadow.svg";

// Theme
import { useTheme } from "@emotion/react";

import { useTranslation } from "react-i18next";
import SideDrawer from "./SideDrawer";

const topBarContent = [
  {
    path: "/home",
    name: "NAV_HOME",
    darkIcon: <BsTwitch />,
  },
  {
    path: "/explore",
    name: "NAV_EXPLORE",
    darkIcon: <HiCubeTransparent />,
  },
  {
    path: "/raffle",
    name: "NAV_AUCTION",
    darkIcon: <MdGavel />,
  },
  {
    path: "/collections",
    name: "Collections",
    darkIcon: <FiPackage />,
  },
];

const SideBar = ({ darkMode }) => {
  // For mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  // Mobile drawer toggler
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>



      {isMobile ? (



        <AppBar
          sx={{
            mt: 2,
            mr: 1,
            backgroundColor: `transparent`,
            display: "flex",
            justifyContent: "right",
            alignItems: "right",    
            alignSelf: "right",
            textAlign: "right",
            zIndex: 0,
            width: '50px',
          }}
          style={{ zIndex: '0' }}
          position="fixed"
          elevation={0}
        >
            <Box>
              <IconButton onClick={handleDrawerToggle}>
                <Typography component="span" color="secondary">
                  <CgMenuRight fontSize={20} />
                </Typography>
              </IconButton>         
          </Box>
        </AppBar>
      ) : null}

{/*

      {!isMobile ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "fixed",
              marginTop: "-3rem",
              marginLeft: "-1rem",
              width: "200px",
              minHeight: "100%",
            }}
          >
            <img
              style={{
                width: "80%",
                height: "50%",
                display: "block",
              }}
              src={SideShadow}
              alt="Bakcdrop"
            />
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: "1000",
              marginTop: "-8rem",
              marginLeft: "-6rem",
            }}
          >
            <div
              style={{
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  ml: 3,
                  width: "60px",
                }}
              >
                {darkMode ? (
                  <img
                    style={{ display: "block" }}
                    src={MainLogo}
                    alt="Minto"
                  />
                ) : (
                  <img
                    style={{ display: "block" }}
                    src={MainLogoLight}
                    alt="Minto"
                  />
                )}
              </Box>
              <Box
                bgcolor={darkMode ? "#171c26" : "#FFF5F9"}
                style={{
                  marginTop: "5rem",
                  borderRadius: "20px",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname ===
                    `/trending-sellers${location.pathname.slice(17)}` ? (
                      <div
                        style={{
                          background:
                            "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}
                    <IconButton
                      onClick={() => navigate(`${"/trending-sellers"}`)}
                    >
                      {location.pathname === "/trending-sellers" ? (
                        <Typography
                          component="span"
                          color="#ffffff"
                          fontSize={20}
                        >
                          <BsFillStarFill />
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          color="secondary"
                          fontSize={20}
                        >
                          <BsFillStarFill />
                        </Typography>
                      )}
                    </IconButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname === "/trending-creators" ? (
                      <div
                        style={{
                          background:
                            "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}
                    <IconButton
                      onClick={() => navigate(`${"/trending-creators"}`)}
                    >
                      {location.pathname === "/trending-creators" ? (
                        <Typography
                          component="span"
                          color="#ffffff"
                          fontSize={20}
                        >
                          <BsLightbulbFill />
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          color="secondary"
                          fontSize={20}
                        >
                          <BsLightbulbFill />
                        </Typography>
                      )}
                    </IconButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname === "/favourites" ? (
                      <div
                        style={{
                          background:
                            "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}
                    <IconButton
                      onClick={() =>
                        navigate(`${"/favourites?type=nft-bookmark"}`)
                      }
                    >
                      {location.pathname === "/favourites" ? (
                        <Typography
                          component="span"
                          color="#ffffff"
                          fontSize={20}
                        >
                          <BsFillBookmarkFill />
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          color="secondary"
                          fontSize={20}
                        >
                          <BsFillBookmarkFill />
                        </Typography>
                      )}
                    </IconButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname ===
                    `/profile${location.pathname?.slice(8)}` ? (
                      <div
                        style={{
                          background:
                            "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}
                    <IconButton
                      onClick={() => navigate(`${"/profile/user-profile"}`)}
                    >
                      {location.pathname ===
                      `/profile${location.pathname?.slice(8)}` ? (
                        <Typography
                          component="span"
                          color="#ffffff"
                          fontSize={20}
                        >
                          <BsGearFill />
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          color="secondary"
                          fontSize={20}
                        >
                          <BsGearFill />
                        </Typography>
                      )}
                    </IconButton>
                  </ListItem>
                </List>
              </Box>
            </div>
          </div>
        </div>
      ) : null}

*/}

      {/* Drawer will render only for mobile devices */}
      
      {isMobile ? (
        <>
          <SideDrawer
            darkMode={darkMode}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </>
      ) : null}
    </>
  );
};

export default SideBar;
