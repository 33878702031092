import React, { useState, useEffect } from "react";
import { AiFillCheckCircle, AiFillWallet } from "react-icons/ai";
import { FaQuestionCircle, FaWallet } from "react-icons/fa";
// Material UI components
import { Link } from "react-router-dom";
import ConnectWalletPopUp from "../ConnectWalletPopUp/ConnectWalletPopUp";
import { TiCancel } from "react-icons/ti";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  Zoom,
} from "@mui/material";

// Icons

import MetaMaskIcon from "../../assets/Icons/darkUIIcons/metaMaskIcon.svg";
import MetaMaskIconLight from "../../assets/Icons/lightUIIcons/metaMaskIcon.svg";
import { GiBugleCall } from "react-icons/gi";
import {
  BsChevronDown,
  BsChevronUp,
  BsQuestionOctagonFill,
  BsFillStarFill,
  BsLightbulbFill,
  BsFillBookmarkFill,
  BsGearFill,
} from "react-icons/bs";
import NavUserAvatar from "../../assets/navUserAvatar.png";
import { RiInstagramLine, RiTwitterFill, RiTelegramFill } from "react-icons/ri";
import { MdLock, MdPrivacyTip } from "react-icons/md";
import { IoPersonSharp, IoLanguage } from "react-icons/io5";
import { IoIosSwitch } from "react-icons/io";
import { AiFillIdcard } from "react-icons/ai";
import { CgLoadbarDoc } from "react-icons/cg";

import LoginIcon from "@mui/icons-material/Login";

import GavelIcon from "@mui/icons-material/Gavel";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

// Modules
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KeyIconDark from "../../assets/Icons/darkUIIcons/keyIconDark.svg";
import KeyIconLight from "../../assets/Icons/lightUIIcons/change-pass-icon.svg";
import DocumentEditIcon from "../../assets/Icons/darkUIIcons/documentEditIconDark.svg";
import DocumentEditIconLight from "../../assets/Icons/lightUIIcons/change-email-icon.svg";

// Logos
//import SideFooterLogoLight from "../../assets/sideFooterLogoLight.svg";
//import SideFooterLogoDark from "../../assets/sideFooterLogoDark.svg";
import SideFooterLogoDark from "../../assets/mainLogo.png";
import SideFooterLogoLight from "../../assets/mainLogoLight.png";
import useAuth from "../../hooks/useAuth";
import { StyledMenu } from "../StyledMenu/StyledMenu";
import { Person } from "@mui/icons-material";
import { BiCoinStack, BiPalette } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import { ImDice } from "react-icons/im";
// Profile sidemenu content

// Drawer width for mobile ui
const drawerWidth = 240;

const SideDrawer = ({ darkMode, mobileOpen, handleDrawerToggle }) => {
  const profileSideMenu = [
    {
      id: 1,
      name: "SETTINGS_USER_PROFILE",
      icon: <IoPersonSharp />,
      path: "/profile/user-profile",
    },
    {
      id: 2,
      name: "SETTINGS_KYC",
      icon: <AiFillIdcard />,
      path: "/profile/kyc",
    },
    {
      id: 3,
      name: "SETTINGS_LANGUAGE",
      icon: <IoLanguage />,
      path: "/profile/language",
    },
    {
      id: 4,
      name: "SETTINGS_THEME",
      icon: <IoIosSwitch />,
      path: "/profile/theme",
    },
    {
      id: 5,
      name: "SETTINGS_CHANGE_PASSWORD",
      icon: "",
      iconMask: darkMode ? KeyIconDark : KeyIconLight,
      path: "/profile/change-password",
    },
    {
      id: 6,
      name: "SETTINGS_CHANGE_EMAIL",
      icon: "",
      iconMask: !darkMode ? DocumentEditIconLight : DocumentEditIcon,
      path: "/profile/change-email",
    },
    {
      id: 7,
      name: "FOOTER_LINK_FAQS",
      icon: <BsQuestionOctagonFill />,
      path: "/frequently-asked-questions",
    },
    {
      id: 8,
      name: "FOOTER_LINK_PRIVACY_POLICY",
      icon: <MdLock />,
      path: "/privacy-policy",
    },
    {
      id: 9,
      name: "FOOTER_LINK_TERMS_CONDITION",
      icon: <CgLoadbarDoc />,
      path: "/terms-and-condition",
    },
    {
      id: 10,
      name: "FOOTER_LINK_CONTACT_US",
      icon: <GiBugleCall />,
      lightIcon: <GiBugleCall />,
      path: "/contact-us",
    },
  ];
  const { user, handleLogOut } = useAuth();

  // Settings toggler
  const [settingsExpanded, setSettingsExpanded] = React.useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const notifySoon = () => toast.info('Coming Soon!', {
    theme: "dark",
  });

  const [isNftDB, setIsNftDB] = useState(localStorage.getItem('isNftDB') === null || localStorage.getItem('isNftDB') === undefined ? true : localStorage.getItem('isNftDB'));
  useEffect(() => {
    setIsNftDB(localStorage.getItem('isNftDB'));
  }, []);

  console.log('isNftDB: ' + isNftDB)
  console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
  

  const goTokens = () => {
    localStorage.setItem('isNftDB', 'false');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('false');
    //console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
    handleReRouting("/raffle?type=live&nft=false")
  }

  const goNft = () => {
    localStorage.setItem('isNftDB', 'true');
    localStorage.setItem('lastUpdateDR', 0);
    setIsNftDB('true');
    //console.log('isNftDB: ' + localStorage.getItem('isNftDB'))
    handleReRouting("/raffle?type=live&nft=true")
  }    


  const goClub = () => {
    handleReRouting("/zcoreclub")
  }

  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleConnectWallet = async () => {
    handleCloseModal();
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
    }
  };
  const handleDisconnectWallet = () => {
    if (walletAddress) {
      window.location.reload();
    }
  };
  const openAvatarMenu = Boolean(avatarAnchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [walletExpanded, setWalletExpanded] = React.useState(false);
  const handleWalletExpand = () => {
    setWalletExpanded(!walletExpanded);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenAvatarMenu = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onDisconnect = () => {
    handleDisconnectWallet();
    localStorage.setItem("isWalletConnected", false);
    localStorage.setItem("walletType", "");
    handleCloseMenu();
  };
  const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Settings expand toggler
  const handleSettingsExpand = () => {
    setSettingsExpanded(!settingsExpanded);
  };

  // Click handler
  const handleReRouting = (path) => {
    handleDrawerToggle();
    navigate(path);    
  };

  const drawer = (
    <Box
      bgcolor={darkMode ? "#0A0407" : "#fff2f8"}
      sx={{ overflowX: "hidden", height: "100vh" }}
    >
      {/* App Toolbar */}
      <Toolbar />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >      

        <ListItem
          key={1}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={goNft}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
                <BiPalette />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("NFTs")}
            </Typography>              
              </>          
          </IconButton>
        </ListItem>        


        <ListItem
          key={99}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={goTokens}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
                <BiCoinStack />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("Tokens")}
            </Typography>
            </>              
          </IconButton>
        </ListItem>


        <ListItem
          key={2}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={goClub}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
                <ImDice />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("ZCoreClub")}
            </Typography>
            </>              
          </IconButton>
        </ListItem>        


{/*

        <ListItem 
          key={3}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
                <MdPrivacyTip />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("Privacy Policy")}
            </Typography>
            </>
          </IconButton>
        </ListItem>     



        <ListItem
        key={4}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
              <AiFillCheckCircle
              />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("Terms & Condition")}
            </Typography>
            </>
          </IconButton>
        </ListItem>     


        <ListItem
        key={5}
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
          >
              <>
              <Typography style={{ marginTop: '4px', }} component="span" color="secondary">
                <FaQuestionCircle />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
              {t("FAQs")}
            </Typography>
            </>
          </IconButton>
        </ListItem>                        


*/}

      </List>

      

      <Box sx={{ px: 2, mt: 4, ml: 2 }}>
        <>

        <Box sx={{ mb: 4 }}>
            <Typography
              color="secondary"
              sx={{ mb: 1, fontWeight: 700 }}
              variant="subtitle1"
              component="h4"
            >
              {t("ABOUT")}
            </Typography>
            <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/privacy-policy" onClick={handleDrawerToggle}
                >
                  {t("FOOTER_LINK_PRIVACY_POLICY")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/terms-and-condition" onClick={handleDrawerToggle}
                >
                  {t("FOOTER_LINK_TERMS_CONDITION")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/frequently-asked-questions" onClick={handleDrawerToggle}
                >
                  {t("FOOTER_LINK_FAQS")}
                </Link>
              </ListItem>


              <ListItem disablePadding>
                  <a 
                   onClick={handleDrawerToggle}
                   style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                   rel="noopener noreferrer" href="https://testnet.bnbchain.org/faucet-smart" target="_blank">
                          {t("Get tBNB")}
                  </a>
              </ListItem>
              {/*
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/contact-us"
                >
                  {t("FOOTER_LINK_CONTACT_US")}
                </Link>
              </ListItem>
            */}
            </List>
          </Box>

          {darkMode ? (
            <img src={SideFooterLogoDark} alt="Side Footer Main Minto Logo" />
          ) : (
            <img src={SideFooterLogoLight} alt="Side Footer Main Minto Logo" />
          )}

          <Typography
            sx={{ color: "#0f5f54", my: 1 }}
            variant="subtitle2"
            component="h6"
          >
            {t("FOOTER_TITLE_MAIN")}
          </Typography>
          <Typography sx={{ color: "gray" }} variant="caption" component="p">
            {t("FOOTER_DETAILS")}
          </Typography>
        </>
        <a rel="noopener" target="_blank" href="https://twitter.com/zcorecrypto">
        <IconButton sx={{ ml: -0.8, mt: 2 }}>
          <Typography component="span" color="secondary">
              <RiTwitterFill />
          </Typography>
        </IconButton>
        </a>
        <a rel="noopener" target="_blank" href="https://t.me/zcoreminers">
        <IconButton sx={{ mt: 2 }}>
          <Typography component="span" color="secondary">
            <RiTelegramFill />
          </Typography>
        </IconButton>
        </a>
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "gray", textAlign: "left", fontSize: "7px", mt: 1 }}
        >
          &copy; 2023 {t("FOOTER_COPYRIGHT")}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {/* The Drawer Component */}
      <Drawer
        anchor="right"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          zIndex: 200000,
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default SideDrawer;
