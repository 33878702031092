import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import styles from './AuctionCardDetails.module.css';
import SingleAuctionCard from './SingleAuctionCard';
import { dataRaffle } from "../../contract/GetRaffles";


const AuctionCardDetails = ({ darkMode }) => {
  const { id } = useParams(); // Read from url
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');

  const [auctionArtWorks, setAuctionArtWorks] = useState([]);
  const [parentCounter, setParentCounter] = useState('1');

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}



  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage('Loading...');    
    /*
    axios.get('/auctionData.json').then((res) => {
      console.log(res.data)
      //setAuctionArtWorks(res.data);
      //setIsLoading(false);
    });
    */
    
    async function fetchData() {
      if(parentCounter != '1'){
        await timeout(2000);
      }      
      const allRaffles = await dataRaffle();
      //console.log(JSON.parse(JSON.stringify(allRaffles)));
      setAuctionArtWorks(allRaffles);
      setIsLoading(false); 
    }
    fetchData();      
    console.log('GetRaffles: ' + parentCounter);
    // console.log('parentCounter: ' + parentCounter)
  }, [id, parentCounter]);


  // Filtering artwork by IDs

  const filteredArtWork = auctionArtWorks.filter(
    (artWork) => artWork.id === id
  );

  console.log('===================================')
  console.log('parentCounter: ' + parentCounter)
  console.log('===================================')

  //console.log('===================================')
  //console.log(filteredArtWork)
  //console.log('===================================')

  return (
    <>
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      <Container className={styles.auctionCardContainer}>
        <Typography variant='h3' color='secondary.main'>
          {filteredArtWork.map((fa) => (
            <SingleAuctionCard 
            setParentCounter={setParentCounter}
            darkMode={darkMode} key={fa.id} fa={fa} />
          ))}
        </Typography>
      </Container>
    </>
  );
};

export default AuctionCardDetails;
